import React, {useState} from 'react';
import ShopCategories from './ShopCategories';
import Search from './Search';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import styled from 'styled-components';
import ShopBrands from './ShopBrands';

const StyledBox = styled(Box)`
  align-items: start;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ShopLayout = () => {
  const [searchState, setSearchState] = useState("");
  const [categoryState, setCategoryState] = useState("");
  const [brandState, setBrandState] = useState("");

  return (
    <StyledBox display="flex" justifyContent="center" gap="20px">
      <Box display="flex" flexDirection="column">
        <Search setSearchState={setSearchState} />
        <ShopBrands setBrandState={setBrandState} />
        <ShopCategories setCategoryState={setCategoryState} categoryState={categoryState} />
      </Box>
      <Outlet context={[searchState, categoryState, brandState]} />
    </StyledBox>
  )
}

export default ShopLayout;
