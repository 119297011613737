import React from 'react';
import VideoFeed from './VideoFeed';
import NewsFeed from './NewsFeed';
import TournamentFeed from './TournamentFeed';
import MatchesFeed from './MatchesFeed';
import Box from '@mui/material/Box';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 40px;
  max-width: 1290px;

  @media only screen and (max-width: 480px) {
    gap: 0;
  }
`;

const HomePage = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <VideoFeed />
      <StyledBox>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <NewsFeed />
          <TournamentFeed />
        </Box>
        <MatchesFeed />
      </StyledBox>
    </Box>
  )
}

export default HomePage;
