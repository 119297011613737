import React, {useState, useEffect} from 'react';
import Pagination from '@mui/material/Pagination';
import {Box, Skeleton, Typography, useTheme} from '@mui/material';
import styled from 'styled-components';
import { tokens } from '../../theme';
import axios from "axios";
import JsonData from "../../Data/data.Url.json";
import useAuth from "../../hooks/useAuth";

const CustomDiv = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 90px;
  padding: 30px 0;

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 768px) {
    
  }
  @media only screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 10px 0;
  }
  @media only screen and (max-width: 320px) {
    
  }
`;

const StyledIframe = styled('iframe')`
  border-radius: 24px;
`;

const StyledTypography = styled(Typography)`
    
`;

const StyledImg = styled.img`
    width: 384px;
    height: 320px;
    object-fit: contain;
    padding: 10px;
    border-radius: 24px;
`;

const StyledBoxLines = styled(Box)`
  width: 100%;
  height: 12px;
  border-radius: 10px;
`;

const StyledTitleBox = styled(Box)`
  padding: 30px 0;

  @media only screen and (max-width: 480px) {
    padding: 10px 0;
  }
`;

const StyledA = styled.a`
  cursor: pointer;
`;

export default function PhotoGallery() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { gameTypeId } = useAuth();

    let [page, setPage] = useState(1);
  
    let [pageCount, setPageCount] = useState(10);
  
    const handleChange = (e, p) => {
      setPage(p);
    };

    const [photoData, setPhotoData] = useState();

    const FilterByGame = () => {
      if(gameTypeId) {
        if(gameTypeId >= 110 ){
          return "&platform=" + gameTypeId;
      }
        return "&gameType=" + gameTypeId;
      } else {
        return "";
      }
    };
  
    useEffect(() => {
        let getImages = async () => {
            let response = await axios.get(`${JsonData.apiMedia}/Get/Images?pageSize=9&pageNumber=${page + FilterByGame()}`);
            setPhotoData(response.data);
            setPageCount(response.headers["page-count"]);
        };
        getImages();
    }, [page, gameTypeId]);

    pageCount = Number(pageCount);

    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box>
          <StyledTitleBox display="flex" justifyContent="center" alignItems="center" p="30px 0">
            <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginRight="20px"></StyledBoxLines>
            <StyledTypography variant="h3" fontWeight="bold">Photos</StyledTypography>
            <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginLeft="20px"></StyledBoxLines>
          </StyledTitleBox>
          <CustomDiv>
            {photoData && photoData.length !== 0 ? (
                photoData.map((items, index) => (
                  <StyledA key={index} href={items.directLink} target="_blank" rel="noopener noreferrer">
                    <StyledImg src={JsonData.imgurl + items.imgUrl.replace("\\\\", "\\")} alt="GalleryPhoto" style={{backgroundColor: colors.greenAccent[800]}} />
                  </StyledA>
              ))
            ) : (
              [...Array(9)].map((e, i) => 
                <Skeleton key={i} variant="rounded" width={384} height={320} />
              )
            )}
          </CustomDiv>
          <Box justifyContent={"center"} alignItems="center" display={"flex"} sx={{padding: "20px 0"}}>
              <Pagination
                size="large"
                shape="rounded"
                variant="outlined"
                count={pageCount}
                page={page}
                onChange={handleChange}
              />
          </Box>
        </Box>
      </Box>
    );
  }
