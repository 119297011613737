import React from "react";
import axios from "axios";
import JsonData from "../../Data/data.Url.json";
import { useState, useEffect } from "react";

const BrandId = () => {
  const [brand, setBrand] = useState();
  const getBrand = async () => {
    const response = await axios.get(`${JsonData.apiurl}/Shop/Brands`);
    setBrand(response.data);
  };

  useEffect(() => {
    getBrand();
  }, []);

  if (brand)
    return brand.map((data, index) => {
      return (
        <option key={index} value={data.id}>
          {data.name}
        </option>
      );
    });
};

export default BrandId;
