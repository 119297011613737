import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import {tokens} from '../theme';
import styled from 'styled-components';

const StyledTitleTypography = styled(Typography)`
  @media only screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const StyledSubtitleTypography = styled(Typography)`
  @media only screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const Header = ({title, subtitle}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

  return (
    <Box mb="30px">
        <StyledTitleTypography variant="h2" color={colors.grey[100]} fontWeight="bold" sx={{mb: "5px"}}>{title}</StyledTitleTypography>
        <StyledSubtitleTypography variant="h5" color={colors.greenAccent[400]}>{subtitle}</StyledSubtitleTypography>
    </Box>
  )
}

export default Header;
