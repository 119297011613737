import React from "react";
import axios from "axios";
import JsonData from "../../Data/data.Url.json";
import { useState, useEffect } from "react";

const CategoriesId = () => {
  const [categories, setCategories] = useState();
  const getCategories = async () => {
    const response = await axios.get(`${JsonData.apiurl}/Shop/Categories`);
    setCategories(response.data);
  };

  useEffect(() => {
    getCategories();
  }, []);

  if (categories)
    return categories.map((data, index) => {
      return (
        <option key={index} value={data.id}>
          {data.name}
        </option>
      );
    });
};

export default CategoriesId;
