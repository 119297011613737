import React, { useState, useEffect } from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { EasyToastify, ToastContainer } from "../../../Extentions/EasyToastify";
import { tokens } from "../../../theme";

const SettingsTeamInvitation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const axiosPrivate = useAxiosPrivate();
  const [teamInvite, setTeamInvite] = useState();

  const fetchTeamInviteData = async () => {
    const response = await axiosPrivate.get("Team/Invite");
    setTeamInvite(response.data);
  };

  const handleTeamAccept = async (id) => {
    if (teamInvite) {
      await axiosPrivate
        .post(`Team/Invite/Accept?id=${id}`, {})
        .then((res) => {
          EasyToastify.ToastSuccess("Invitation Accpeted");
        })
        .catch((error) => {
          EasyToastify.ToastError("Oop Something wrong!");
        });
    }
  };

  const handleTeamDelete = async (id) => {
    if (teamInvite) {
      await axiosPrivate
        .delete(`Team/Invite/Delete?id=${id}`, {})
        .then((res) => {
          EasyToastify.ToastSuccess("Invitation Rejected!");
        })
        .catch((error) => {
          EasyToastify.ToastError("Oop Something wrong!");
        });
    }
  };

  // useEffect(() => {
  //   console.log("ra ginda bijoooo");
  //   const interval = setInterval(() => {
  //     fetchTeamInviteData();
  //   }, [1000]);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // setTimeout(()=>{
  //  }, 10000)

  useEffect(() => {
    fetchTeamInviteData();
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap="12px" padding="16px" borderRadius="16px" style={{backgroundColor: colors.greenAccent[800]}}>
      <Typography variant="h5">Team Invitation</Typography>
      {teamInvite && teamInvite.length !== 0 ? (
        teamInvite.map((items, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="4px 12px"
          >
            <p style={{ fontSize: "16px" }}>{items.content}</p>
            <Box display="flex" gap="8px">
              <Button
                onClick={() => handleTeamAccept(items.id)}
                variant="contained"
                color="success"
              >
                <DoneIcon />
              </Button>
              <Button
                onClick={() => handleTeamDelete(items.id)}
                variant="contained"
                color="error"
              >
                <ClearIcon />
              </Button>
            </Box>
          </Box>
        ))
      ) : (
        <p>There are no invites</p>
      )}
      <ToastContainer />
    </Box>
  );
};

export default SettingsTeamInvitation;
