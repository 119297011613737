import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import styled from "styled-components";
// import SearchIcon from "@mui/icons-material/Search";
// import InputBase from "@mui/material/InputBase";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { EasyToastify, ToastContainer } from "../Extentions/EasyToastify";
import { Avatar, Menu, MenuItem, Divider, useTheme, Box } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import JsonData from "../Data/data.Url.json";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import useLogout from "../hooks/useLogout";
import { ColorModeContext, tokens } from "../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import HeaderTopGames from "./HeaderTopGames";
import HeaderTopSocials from "./HeaderTopSocials";
import LoginIcon from "@mui/icons-material/Login";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const StyledHeaderTop = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  flex-shrink: 1;
  flex-wrap: wrap;

  @media only screen and (max-width: 480px) {
    justify-content: space-between;
  }
`;

const StyledNavMain = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li button {
    margin-left: 0;
  }

  li {
    padding: 5px 7px;

    @media only screen and (max-width: 480px) {
      padding: 2px 3px;
    }
  }

  li a {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }

  .shopping-cart-icon {
    color: white;
  }

  .log-in-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    gap: 6px;
    padding: 8px 12px;
    color: #fff;
  }

  .log-in-btn:hover {
    opacity: 0.95;
  }
`;

const StyledTrustDevice = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;

  label {
    font-size: 15px;
  }

  input.trustDevice {
    width: 15px;
    height: 15px;
    accent-color: #266798;
  }
`;

const StyledLink = styled(Link)`
  color: #fff;
`;

// const StyledSearchBox = styled.div`
//   @media only screen and (max-width: 768px) {
//     display: none;
//   }
// `;

function HeaderTop() {
  const theme2 = createTheme({
    palette: {
      primary: {
        // light: "#757ce8",
        main: "#2196f3",
        // dark: "#002884",
        // contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const postUrl = `${JsonData.apiurl}/Authentification/Login`;

  const { auth, setAuth, persist, setPersist } = useAuth();

  // let userIdParam = "";
  // if(persist !== undefined && persist !== null) {
  //   userIdParam = persist ?
  //   JSON.parse(localStorage.getItem("user")).userId :
  //   JSON.parse(sessionStorage.getItem("user")).userId;
  // };

  const logout = useLogout();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [roles, setRoles] = useState();

  const [email, setEmail] = useState("");
  const [password, setPwd] = useState("");

  const signOut = async () => {
    await logout();
    navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(
        postUrl,
        { email, password },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        // console.log(res.data);
        const accesToken = res?.headers?.token;
        const roles = [res?.data?.roleId];
        const nickName = res.data.nickname;
        const userId = res.data.userId;
        const teamId = res.data.teamId;
        const organisationId = res.data.organisationId;

        setAuth({
          email,
          roles,
          accesToken,
          nickName,
          userId,
          teamId,
          organisationId,
        });
        setRoles(roles);
        setEmail("");
        setPwd("");

        navigate(from, { replace: true });
        if (res.status === 200) {
          EasyToastify.ToastSuccess("Log-in Successfull");
          if (persist) {
            localStorage.setItem(
              "user",
              JSON.stringify({
                email,
                roles,
                nickName,
                userId,
                teamId,
                organisationId,
              })
            );
          }
          if (!persist) {
            sessionStorage.setItem(
              "user",
              JSON.stringify({
                email,
                roles,
                nickName,
                userId,
                teamId,
                organisationId,
              })
            );
          }
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.request.status === 400) {
          EasyToastify.ToastError("Wrong Email or Password");
        } else if (err.request.status === 500) {
          EasyToastify.ToastError("Server Down Or in Maintenance");
        } else {
          EasyToastify.ToastError("Login Failed");
        }
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist, auth]);

  const handleThemeChange = () => {
    if (!localStorage.getItem("themeModeKey")) {
      localStorage.setItem("themeModeKey", "light");
      colorMode.toggleColorMode();
    } else if (localStorage.getItem("themeModeKey") === "light") {
      localStorage.setItem("themeModeKey", "dark");
      colorMode.toggleColorMode();
    } else if (localStorage.getItem("themeModeKey") === "dark") {
      localStorage.setItem("themeModeKey", "light");
      colorMode.toggleColorMode();
    }
  };

  return (
    <>
      <StyledHeaderTop>
        <HeaderTopSocials />
        <HeaderTopGames />
        <StyledNavMain>
          <ul>
            {/* <li>
              <StyledSearchBox backgroundColor="#1F2A40" borderRadius="3px">
                <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                <IconButton type="button" sx={{ p: 1 }}>
                  <SearchIcon />
                </IconButton>
              </StyledSearchBox>
            </li> */}
            <li>
              <IconButton
                onClick={handleThemeChange}
                sx={{
                  borderRadius: "5px",
                  gap: "5px",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {theme.palette.mode === "dark" ? (
                  <DarkModeOutlinedIcon />
                ) : (
                  <LightModeOutlinedIcon />
                )}
              </IconButton>
            </li>
            {/* {auth.nickName ? (
              <li>
                <a href="#">
                  <ShoppingCartIcon className="shopping-cart-icon" />
                </a>
              </li>
            ) : (
              ""
            )} */}
            <li>
              {auth.nickName ? (
                <>
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2, borderRadius: "5px", background: "none" }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup={true}
                      aria-expanded={open ? true : undefined}
                    >
                      <div
                        className="log-in-btn"
                        style={{ backgroundColor: colors.greenAccent[800] }}
                      >
                        <PersonIcon style={{ color: colors.textColor[100] }} />
                        <span
                          style={{
                            fontSize: "14px",
                            color: colors.textColor[100],
                          }}
                        >
                          {auth.nickName.length > 9
                            ? auth.nickName.substr(0, 9) + "..."
                            : auth.nickName}
                        </span>
                      </div>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <StyledLink to={`user/${auth?.userId}`}>
                      <MenuItem>
                        <Avatar />
                        <p style={{ color: colors.textColor[100] }}>
                          {auth?.nickName}
                        </p>
                      </MenuItem>
                    </StyledLink>
                    {!auth?.roles?.includes(1) ? (
                      <Link to="/admin">
                        <MenuItem sx={{ color: colors.textColor[100] }}>
                          <ListItemIcon>
                            <AdminPanelSettingsIcon fontSize="medium" />
                          </ListItemIcon>
                          Admin Dashboard
                        </MenuItem>
                      </Link>
                    ) : (
                      ""
                    )}
                    <Divider />
                    {/* <MenuItem>
                      <ListItemIcon>
                        <PersonAdd fontSize="small" />
                      </ListItemIcon>
                      Add another account
                    </MenuItem> */}
                    {auth.teamId != null ? (
                      <StyledLink to={`team/${auth.teamId}`}>
                        <MenuItem sx={{ color: colors.textColor[100] }}>
                          <ListItemIcon>
                            <GroupsIcon fontSize="medium" />
                          </ListItemIcon>
                          Team Profile
                        </MenuItem>
                      </StyledLink>
                    ) : (
                      ""
                    )}
                    {auth.organisationId != null ? (
                      <StyledLink to={`organization/${auth.organisationId}`}>
                        <MenuItem sx={{ color: colors.textColor[100] }}>
                          <ListItemIcon>
                            <Diversity3Icon fontSize="medium" />
                          </ListItemIcon>
                          Organization Profile
                        </MenuItem>
                      </StyledLink>
                    ) : (
                      ""
                    )}
                    <StyledLink to={`user/${auth?.userId}/settings`}>
                      <MenuItem sx={{ color: colors.textColor[100] }}>
                        <ListItemIcon>
                          <Settings fontSize="medium" />
                        </ListItemIcon>
                        Settings
                      </MenuItem>
                    </StyledLink>
                    <MenuItem
                      onClick={signOut}
                      sx={{ color: colors.textColor[100] }}
                    >
                      <ListItemIcon>
                        <Logout fontSize="medium" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <Tooltip>
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2, borderRadius: "5px", background: "none" }}
                      disableRipple={true}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup={true}
                      aria-expanded={open ? true : undefined}
                    >
                      <div
                        className="log-in-btn"
                        style={{ backgroundColor: colors.greenAccent[800] }}
                      >
                        <LoginIcon style={{ color: colors.textColor[100] }} />
                        <span
                          style={{
                            fontSize: "14px",
                            color: colors.textColor[100],
                          }}
                        >
                          LOG IN
                        </span>
                      </div>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    sx={{
                      backgroundColor: "trasparent",
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <form onSubmit={handleSubmit}>
                      <MenuItem
                        disableRipple={true}
                        style={{
                          backgroundColor: "transparent",
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            cursor: "default",
                          },
                        }}
                      >
                        <TextField
                          id="UserName"
                          label="Your Mail"
                          color="success"
                          variant="outlined"
                          required={true}
                          autoComplete="off"
                          onKeyDown={(e) => {
                            e.stopPropagation();
                          }}
                          type="email"
                          name="email"
                          sx={{ width: "100%" }}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                          color="success"
                          id="Password"
                          label="Password"
                          variant="outlined"
                          required={true}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                          }}
                          type="password"
                          name="password"
                          sx={{ width: "100%" }}
                          onChange={(e) => setPwd(e.target.value)}
                        />
                        <StyledTrustDevice>
                          <input
                            type="checkbox"
                            id="persist"
                            onChange={togglePersist}
                            checked={persist}
                            className="trustDevice"
                          />
                          <label htmlFor="persist">Remember Me</label>
                        </StyledTrustDevice>
                        <Button
                          variant="contained"
                          color="success"
                          sx={{ width: 223 }}
                          type="submit"
                        >
                          Log In
                        </Button>

                        <Link
                          to="/passwordrecovery"
                          className="pass-recovery"
                          style={{
                            color: colors.textColor[100],
                            letterSpacing: "1px",
                            fontSize: "14px",
                            textDecorationLine: "underline",
                          }}
                        >
                          Forgot your password?
                        </Link>
                      </MenuItem>
                    </form>
                    <Divider />
                    <MenuItem
                      disableRipple={true}
                      style={{ backgroundColor: "transparent" }}
                      sx={{
                        "&.MuiButtonBase-root:hover": {
                          cursor: "default",
                        },
                      }}
                    >
                      <Link
                        to="/signup"
                        target="_blank"
                        style={{ width: "100%" }}
                      >
                        <ThemeProvider theme={theme2}>
                          <Button
                            variant="contained"
                            sx={{
                              width: "100%",
                            }}
                            color="primary"
                            colorMode="white"
                          >
                            Sign Up
                          </Button>
                        </ThemeProvider>
                      </Link>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </li>
          </ul>
        </StyledNavMain>
      </StyledHeaderTop>
      <ToastContainer />
    </>
  );
}

export default HeaderTop;
