import React, { useState, useEffect } from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import { axiosPrivate } from "../../api/axios";
import Moment from "moment";
import { tokens } from "../../theme";
import * as yup from "yup";
import { EasyToastify } from "../../Extentions/EasyToastify";

const StyledBox = styled(Box)`
  border-radius: 16px;
  padding: 16px;

  form {
    width: 540px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    @media only screen and (max-width: 480px) {
      padding: 16px;
      width: 100%;
    }
  }

  label {
    color: #b7b7b7;
    font-size: 16px;
  }

  input {
    padding: 8px;
    border-radius: 8px;
    border: none;
    outline: 0;
    width: 100%;
  }

  h2 {
    @media only screen and (max-width: 480px) {
      padding-left: 20px;
    }
  }
`;

const StyledRedBox = styled(Box)`
  color: red;
`;

const validationSchema = yup.object({
  nickName: yup
    .string()
    .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()?]{3,16}$/, "nickName should be 3-16 characters and Latin alphabet")
    .required("Nickname is required!"),
  email: yup
    .string()
    .email()
    .required("Email is required!"),
  firstName: yup
    .string()
    .matches(/^[A-Za-z]{3,50}$/, "firstname Should be only letters and more then 3 characters")
    .required("First name is required!"),
  lastName: yup
    .string()
    .matches(/^[A-Za-z]{3,50}$/, "lastname Should be only letters and more then 3 characters")
    .required("Last name is required!"),
  phoneNumber: yup
    .string()
    .matches(/^[0-9-+]{1,30}$/, "Only Numbers, + and - are allowed")
    .required("Phone number is required!"),
  birthDate: yup
    .date()
    .required("Date is required"),
});

const SettingsAccount = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [userData, setUserData] = useState({
    nickName: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    birthDate: "",
  });

  const getUserDetails = async () => {
    await axiosPrivate.get("/User/Get/UserSettings")
    .then((res) => {
      let changedData = res.data;
      changedData.birthDate = Moment(changedData.birthDate).format("YYYY-MM-DD");
      setUserData(changedData);
    })
    .catch((err) => {
      EasyToastify.ToastError(err.message);
    })
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleSubmit = async (values, actions) => {
    await axiosPrivate
      .put("User/Update/UserSettings", {
        nickName: values.nickName,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        birthDate: values.birthDate,
      })
      .catch((error) => {
        EasyToastify.ToastError(error.message);
      })
      .then((res) => {
        EasyToastify.ToastSuccess("Account details updated");
        actions.resetForm();
        getUserDetails();
      });
  };
  
  return (
    <StyledBox style={{backgroundColor: colors.greenAccent[800]}}>
      <Typography variant="h2">Account</Typography>
      <Formik
        enableReinitialize={true}
        initialValues={userData}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
      <Form>
        <Box display="flex" flexDirection="column">
          {userData && userData.length !== 0 ? (
            <>
              <label>Nickname</label>
              <Field
                type="text"
                name="nickName"
                style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
              />
              <StyledRedBox>
                <ErrorMessage name="nickName" />
              </StyledRedBox>
            </>
          ) : (
            ""
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          {userData && userData.length !== 0 ? (
            <>
              <label>Email Address</label>
              <Field
                type="email"
                name="email"
                style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
              />
              <StyledRedBox>
                <ErrorMessage name="email" />
              </StyledRedBox>
            </>
          ) : (
            ""
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          {userData && userData.length !== 0 ? (
            <>
              <label>First name</label>
              <Field
                type="text"
                name="firstName"
                style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
              />
              <StyledRedBox>
                <ErrorMessage name="firstName" />
              </StyledRedBox>
            </>
          ) : (
            ""
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          {userData && userData.length !== 0 ? (
            <>
              <label>Last name</label>
              <Field
                type="text"
                name="lastName"
                style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
              />
              <StyledRedBox>
                <ErrorMessage name="lastName" />
              </StyledRedBox>
            </>
          ) : (
            ""
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          {userData && userData.length !== 0 ? (
            <>
              <label>Phone Number</label>
              <Field
                type="tel"
                name="phoneNumber"
                style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
              />
              <StyledRedBox>
                <ErrorMessage name="phoneNumber" />
              </StyledRedBox>
            </>
          ) : (
            ""
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          {userData && userData.length !== 0 ? (
            <>
              <label>Date of birth</label>
              <Field
                type="date"
                name="birthDate"
                style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
              />
              <StyledRedBox>
                <ErrorMessage name="birthDate" />
              </StyledRedBox>
            </>
          ) : (
            ""
          )}
        </Box>
        <Button type="submit" variant="contained" sx={{backgroundColor: "#155683"}}>
          Update
        </Button>
      </Form>
      </Formik>
    </StyledBox>
  );
};

export default SettingsAccount;
