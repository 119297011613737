import React from 'react';
import {Box, Typography} from '@mui/material';
import styled from 'styled-components';

const StyledParagraph = styled.p`
  line-height: 1.5rem;
  margin-left: 16px;
`;

const TournamentRules = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" paddingBottom="24px">
      <Box display="flex" flexDirection="column" gap="14px" maxWidth="60vw">
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="h4">1. რეგისტრაცია</Typography>
          <StyledParagraph>
            1.1. მონაწილე ვალდებულია დაეთანხმოს ტურნირის წესებსა და მსაჯის გადაწყვეტილებას; <br />
            1.2. სარეგისტრაციოში მითითებული თითოეული დეტალი უნდა იყოს შევსებული მართებულად; <br />
            1.3. ყველა მონაწილე უნდა იყოს 18 წლის ან ზემოთ 18+; <br />
            1.4. რეგისტრაცია არის ნებადართული მხოლოდ ერთ სახეობაში;
          </StyledParagraph>
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="h4">2. გლობალური წესები</Typography>
          <StyledParagraph>
            2.1. ფედერაციის მიერ დადგენილი წესები ეხება ყველა ტურნირს, ლიგას თუ სხვა სახის ღონისძიებას, რომელიც ორგანიზებული ან თანაორგანიზებულია საქართველოს კომპიუტერული სპორტის ფედერაციის მიერ;<br />
            2.2. ტურნირის წესების დარღვევის შემთხვევაში შეიძლება მიიღოთ გაფრთხილება ან დისკვალიფიკაცია, რომელიც დამოკიდებულია წესის დარღვევის ხარისხზე;<br />
            2.3. წესების დარღვევის გამეორებაზე მიიღებთ დისკვალიფიკაციას (დისკვალიფიკაციის ვადას განსაზღვრავს ადმინისტრაცია);<br />
            2.4. ისეთ მომენტზე, რომელიც წესებში არ არის მითითებული, ბოლო სიტყვა ეკუთვნის ადმინისტრაციას, რომელიც ყველაფრის გათვალისწინებით მიიღებს სამართლიან გადაწყვეტილებას;<br />
            2.5. ტურნირის მსვლელობისას,  მზად უნდა იყოთ ინტერვიუს აღება თუ ფოტო/ვიდეო გადაღებისთვის;<br />
            2.6. დისკვალიფიცირებული გუნდი/მოთამაშე არ მიიღებს თანხას საპრიზოდან;<br />
            2.7. გადაუდებელი სიტუაციის დროს ფედერაციას აქვს უფლება შეცვალოს შეხვედრების განრიგი;<br />
            2.8. ტურნირის დროს თუ გუნდის/მოთამაშის მხრიდან შეამჩნევთ წესების დარღვევას ან მიუღებელ ქმედებას აუცილებელია შეატყობინოთ მსაჯს;<br />
            2.9. აუცილებელია იყოთ საქართველოს მოქალაქე ან ცხოვრობდეთ საქართველოში;<br />
            2.10. რეგისტრაციის გავლის შემდეგ სარეგისტრაციო გადმოგზავნილი ინფორმაციის შეცვლის შემთხვევაში აუცილებლად უნდა შეატყობინოთ ადმინისტრატორს;<br />
            2.11. აკრძალულია სტრიმი ჩემპიონატის დროს;<br />
            2.12. მატჩებს ექნება განსაზღვრული დაწყების დრო;<br />
            2.13. ტურნირის დაწყებამდე ყველა გუნდმა უნდა წარადგინოს გუნდის ლიდერი/კაპიტანი;<br />
            2.14. ლიდერი/კაპიტანი ტურნირის დაწყებიდან ბოლომდე არ იცვლება, ლიდერი/კაპიტანი არის გუნდიდან ერთადერთი წარმომდგენელი ვისაც აქვს უფლება ტურნირის საორგანიზაციო ჯგუფთან უშუალო ურთიერთობის (გუნდის სახელით)<br />
            2.15. თუ მონაწილე აღარ გააგრძელებს ტურნირზე თამაშს, იგი მიიღებს დისკვალიფიკაციას (დისკვალიფიკაციის ვადას განსაზღვრავს ადმინისტრაცია);<br />
            2.16. აუცილებელია ყველა გამოცხადდეს მატჩის განსაზღვრულ დრომდე 10 წუთით ადრე;<br />
            2.17. თუ ადმინისტრაციამ დანიშნა რემატჩი, ამ დროს აუცილებელია ითამაშოთ. წინააღმდეგ შემთხვევაში თუ არ გამოცხადდებით ჩაგეწერებათ წაგება;<br />
            2.18. ტურნირის ფორმატის შესახებ გეცოდინებათ რეგისტრაციის შემდეგ;<br />
            2.19. თუ ტურნირის დროს თამაშები გადაიდო ისეთი მიზეზების გამო, როგორიცაა ტექნიკური თუ სხვა პრობლემა იქნებით ინფორმირებული და საჭიროების შემთხვევაში დაგენიშნებათ სხვა სათამაშო დრო;<br />
            2.20. მხოლოდ შეგიძლიათ პადის, კლავიატურის, მაუსისა და ყურსასმენის შეცვლა. აუცილებლად დაგხვდებათ კომპიუტერისთვის ყველა საჭირო სტანდარტული მოწყობილობა;<br />
            2.21. ტურნირის დროს აუცილებელია მოუსმინოთ და შეასრულოთ მსაჯის მითითებები;<br />
            2.22. მონაწილეებმა აუცილებლად უნდა მოუსმინონ ტურნირის ადმინისტრატორის ინსტრუქციას დაჯილდოების ცერემონიისთვის;<br />
            2.23. მოთამაშე არ უნდა იყოს ალკოჰოლური ან ნარკოტიკული ზემოქმედების ქვეშ;;<br />
            2.24. მოწინააღმდეგეს თანხის შეთავაზება წაგება/მოგების სანაცვლოდ ისჯება დისკვალიფიკაციით (დისკვალიფიკაციის ვადას განსაზღვრავს ადმინისტრაცია);<br />
            2.25. აკრძალულია საერთო ჩატში სპამინგი;<br />
            2.26. არასწორი ინფორმაციის მოწოდების შეთმხვევა ისჯება დისკვალიფიკაციით (დისკვალიფიკაციის ვადას განსაზღვრავს ადმინისტრაცია);<br />
            2.27. ჩემპიონატში არარეგისტრირებული მოთამაშე არ დაიშვება;<br />
            2.28. მოთამაშეებს დათქმულ მატჩამდე მომზადებისთვის ექნებათ 10 წუთი;<br />
            2.29. პრიზების ან ფულადი ჯილდოების გადაცემის მაქსიმალური დროა 45 დღე;<br />
            2.30. პრიზის ან ფულადი ჯილდოს მიღებისას გათვალისწინებული იქნება საქართველოს კანონმდებლობით განსაზღვრული გადასახადები და მიმღები დაიბეგრება შესაბამისად;<br />
            2.31. ლან ტურნირისას აუცილებელია თან გქონდეთ პირადობის დამადასტურებელი ბარათი ან პასპორტი;<br />
            2.32. ლან ტურნირისას აუცილებელია თამაშის დროს ხედვის დისტანცია გქონდეთ დაყენებული default-ზე; <br />
            2.33. ლან ტურნირისას დაუშვებელია დისკორდის, დოტას თამაშის სკრინისა და კომპიუტერული აქსესუარების პროგრამების გარდა სხვა ფანჯრის გახსნა;<br />
          </StyledParagraph>
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="h4">3. მოთამაშეების ეთიკა</Typography>
          <StyledParagraph>
            3.1. იკრძალება: <br />
            · შეურაცხმყოფელი სიტყვები;<br />
            · ფსონები თამაშებზე;<br />
            · ბაგების გამოყენება უპირატესობის მოსაპოვებლად;<br />
            · ჩეთის გამოყენება;<br />
            · უპატივცემულობა ორგანიზატორების და მოთამაშეების მიმართ; <br />
            3.2. რეგისტრაციისას დაუშვებელია მოთამაშეების უცენზურო სახელწოდებები;<br />
            3.3. დაუშვებელია შემდეგი სახის რეკლამირება:<br />
            · რასიზმის<br />
            · სექსიზმის<br />
            · ჰომოფობიის<br />
            · ქსენოფობიის<br />
            · თამბაქოს<br />
            · ნარკოტიკული საშუალებების<br />
            · არალეგალური ნაწარმის<br />
            · პორნოგრაფიის<br />
            · სიძულვილის მქონე ტიპის მაისურების ან სხვა ნივთების<br />
            · რელიგიის<br />
            · დისკრიმინაციის ნებისმიერი სახის მიმართ 
          </StyledParagraph>
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="h4">4. წესების შეცვლა</Typography>
          <StyledParagraph>
            4.1. ფედერაცია იტოვებს უფლებას საჭიროების შემთხვევაში შეცვალოს ტურნირის წესების ნებისმიერი დეტალი;<br />
            4.2. ინფორმაცია ტურნირის წესების შეცვლის შესახებ გამოქვეყნდება წინასწარ;
          </StyledParagraph>
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="h4">5. თამაშის პარამეტრები</Typography>
          <StyledParagraph>
            5.2. მოთამაშე ვალდებულია გადაიღოს Screenshot მოგების დასადასტურებლად და გააგზავნოს ფედერაციის ოფიციალურ Dota 2-ის გვერდზე, მითითებულ ბმულზე: http://m.me/CyberTVDota2 <br />
            5.3. გუნდი ითვლება მოგებულად თუ მისი მოწინააღმდეგის მთავარი ბაზა განადგურდა ან მან საერთო ჩატში დაწერა “gg” (დანებება); <br />
            5.4. პაუზა დასაშვებია მხოლოდ ტექნიკური ხარვეზის, მოთამაშის ჯანმრთელობის ან ბუნებრივი პრობლემის შემთხვევაში; <br />
            5.4.1. პაუზის მოხსნა შესაძლებელია მსაჯის მითითების შემდეგ;
          </StyledParagraph>
        </Box>
      </Box>
    </Box>
  )
}

export default TournamentRules;
