import { useState } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";

const DashboardPage = () => {
  const [isSidebar, setIsSidebar] = useState(true);

  const [searchState, setSearchState] = useState("");

  return (
    <Box display="flex" height="100%">
      <Sidebar isSidebar={isSidebar} />
      <Box width="100%">
        <Topbar setSearchState={setSearchState} setIsSidebar={setIsSidebar} />
        <Outlet context={[searchState]} />
      </Box>
    </Box>
  );
};

export default DashboardPage;
