import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Field, Form, Formik } from "formik";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Button, Box } from "@mui/material";
import useAxiosPrivateForm from "../../hooks/useAxiosPrivateForm";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { EasyToastify, ToastContainer } from "../../Extentions/EasyToastify";
import JsonData from "../../Data/data.Url.json";
import axios from "axios";
const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
`;

const StyledOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(49, 49, 49, 0.8);
`;

const StyledModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2f475d;
  padding: 24px 28px;
  border-radius: 20px;
  width: 700px;

  @media only screen and (max-width: 480px) {
    width: 380px;
  }
`;
function AttachPhotosModal(props) {
  const axiosPrivateForm = useAxiosPrivateForm();
  const fileRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [files, setFiles] = useState();
  const [images, setImages] = useState([]);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    if (files) {
      selectedFilesArray.push(...files);
    }
    setFiles(selectedFilesArray);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages(imagesArray);

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image, index) {
    console.log(index);
    setSelectedImages(selectedImages.filter((e) => e !== image));
    setFiles(files.filter((e) => e !== files[index]));
    // console.log(files.filter((e) => console.log(e.name)));
    URL.revokeObjectURL(image);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    console.log(files);

    formData.append("ProductId", props.productId);
    files.forEach((file) => {
      formData.append("Images", file);
    });

    await axiosPrivateForm
      .post(`/Shop/image`, formData)
      .then((response) => {
        setFiles([]);
        setSelectedImages([]);
        props.setOpen(false);
        EasyToastify.ToastSuccess("Image Added");
      })
      .catch((error) => {
        console.error(error);
        EasyToastify.ToastError("Something Wrong");
      });
  };

  const handleImgDelete = (id) => {
    console.log("Deleted =>", id);
  };

  const getItemImgs = async (id) => {
    const response = await axios
      .get(`${JsonData.apiurl}/shop/image?id=${id}`)
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getItemImgs(props.productId);
  }, [props.open]);

  return (
    <Box>
      {props.open && (
        <StyledModal>
          <StyledOverlay
            onClick={() => {
              props.setOpen(false);
            }}
          ></StyledOverlay>
          <StyledModalContent>
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h2>Attached Images to this product:</h2>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                  columnGap: "20px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                {images &&
                  images.map((img) => {
                    return (
                      <Box width="100%" sx={{}}>
                        <img
                          src={JsonData.imgurl + img.imgUrl}
                          alt="img"
                          style={{ width: "100%", height: "108px" }}
                        />
                        <Button
                          variant="contained"
                          sx={{ width: "100%" }}
                          color="error"
                          onClick={() => handleImgDelete(img.id)}
                        >
                          Delete Existing Image
                        </Button>
                      </Box>
                    );
                  })}
              </Box>
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2>Upload up to 6 images</h2>
              </label>
              <input
                ref={fileRef}
                type="file"
                name="images"
                hidden
                onChange={onSelectFile}
                multiple
                accept="image/png , image/jpeg, image/webp"
              />

              <Button
                variant="contained"
                sx={{
                  color: "black",
                  backgroundColor: "skyblue",
                  margin: "10px 0",
                  width: "100%",
                  fontSize: "15px",
                }}
                onClick={() => {
                  fileRef.current.click();
                }}
              >
                UPLOAD <AddAPhotoIcon />
              </Button>
              {selectedImages.length > 0 &&
                (selectedImages.length > 6 ? (
                  <p>
                    You can't upload more than 6 images! <br />
                    <span style={{ color: "red" }}>
                      please delete <b> {selectedImages.length - 6} </b> of them{" "}
                    </span>
                  </p>
                ) : (
                  <h3
                    className="upload-btn"
                    style={{ color: "skyBlue", marginBottom: "5px" }}
                  >
                    Uploaded {selectedImages.length} image
                    {selectedImages.length === 1 ? "" : "s"}
                  </h3>
                ))}

              <div
                className="images"
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                  columnGap: "20px",
                  height: "100%",
                }}
              >
                {selectedImages &&
                  selectedImages.map((image, index) => {
                    return (
                      <div
                        key={image}
                        className="image"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          height: "100%",
                          gap: "5px",
                        }}
                      >
                        <img
                          src={image}
                          height="200px"
                          width="100%"
                          alt="upload"
                        />
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => deleteHandler(image, index)}
                        >
                          delete image
                        </Button>
                        <p>{index + 1}</p>
                      </div>
                    );
                  })}
              </div>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "white",
                  backgroundColor: "green",
                  width: "100%",
                  marginTop: "10px",
                }}
                disabled={selectedImages.length > 6 ? true : false}
              >
                Submit
              </Button>
            </form>
            <Button
              variant="text"
              sx={{
                color: "red",
                position: "absolute",
                top: "3px",
                right: "5px",
              }}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              <CancelOutlinedIcon />
            </Button>
          </StyledModalContent>
        </StyledModal>
      )}
      {/* <ToastContainer /> */}
    </Box>
  );
}

export default AttachPhotosModal;
