import useAuth from "./useAuth";
import JsonData from "../Data/data.Url.json";
import useAxiosPrivate from "./useAxiosPrivate";
import { EasyToastify } from "../Extentions/EasyToastify";

const useLogout = () => {
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const logout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    try {
      await axiosPrivate
        .post(`${JsonData.apiurl}/Authentification/logOut`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${auth.accesToken}`,
          },
        })
        .then(() => {
          
        });
    } catch (err) {
      console.log(err);
    } finally{
      setAuth({});
      localStorage.clear();
      sessionStorage.clear();
      EasyToastify.ToastError("Logged out Successfully");
    }
  };

  return logout;
};

export default useLogout;
