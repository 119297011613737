import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import BackgroundImg from "../Assets/Images/backgroundpwdrec.png";
import BackgroundImgVertical from "../Assets/Images/pwdrevertical.png";
import CyberTVLogo from "../Assets/Images/cyberlogo.png";
import { useState } from "react";
import axios from "axios";
import JsonUrl from "../Data/data.Url.json";
import { EasyToastify, ToastContainer } from "../Extentions/EasyToastify";
import { Box } from "@mui/system";

const StyledRecPwd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-image: url(${BackgroundImg});

  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media only screen and (max-width: 570px) {
    background-image: url(${BackgroundImgVertical});
  }
`;
const StyledRecPwdForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 25%;
  background-color: red;
  border-top: 3px green solid;
  border-bottom: 3px green solid;
  border-radius: 10px;
  background-color: transparent;
  align-items: center;
  padding: 20px;
  img {
    width: 200px;
    margin-bottom: 15px;
    @media only screen and (max-width: 1080px) {
      width: 180px;
    }
    @media only screen and (max-width: 570px) {
      width: 150px;
    }
  }

  @media only screen and (max-width: 1500px) {
    width: 35%;
  }
  @media only screen and (max-width: 1080px) {
    width: 45%;
  }
  @media only screen and (max-width: 570px) {
    width: 73%;
  }
  div {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 25px;
  }

  input,
  .submit-pwdrec {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    background-color: transparent;
    border-radius: 5px;
    color: #fff;
    border: none;
    border: 0.09rem solid green;
    :focus {
      outline: none;
    }
    ::placeholder {
      color: #d0c9cc;
    }
  }

  label {
    font-size: 20px;
    color: #fff;
  }

  .submit-pwdrec {
    background-color: green;
  }

  .submit-pwdrec:hover {
    opacity: 0.87;
  }
`;

const PasswordRecovery = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const Handlesubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    axios
      .post(
        `${JsonUrl.apiurl}/Authentification/PasswordRecovery?email=${email}`
      )
      .then(() => {
        EasyToastify.ToastSuccess("Request Sent, Check your inbox! 📩");
        setDisabled(false);
        setSuccess(true);
      })
      .catch(() => {
        EasyToastify.ToastError("Wrong Mail ⛔");
        setDisabled(false);
      });
  };

  return (
    <div>
      <StyledRecPwd>
        <StyledRecPwdForm onSubmit={Handlesubmit}>
          <Link to="/">
            <img src={CyberTVLogo} alt=" " />
          </Link>
          {!success ? (
            <div>
              <label>Recover Password</label>
              <input
                type="email"
                name="email"
                placeholder="Enter registered email"
                autoComplete="off"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required={true}
              />
              <input
                type="submit"
                value="Send Mail"
                disabled={disabled}
                className="submit-pwdrec"
                style={{
                  opacity: disabled && "0.2",
                  cursor: disabled ? "default" : "pointer",
                }}
              />
              <Link
                to="/signup"
                style={{
                  color: "#fff",
                  letterSpacing: "1px",
                  // borderRadius: "5px",
                  // padding: "5px",
                  textUnderlinePosition: "under",
                }}
                className="goto-signup"
              >
                <p style={{ textDecoration: "underline" }}>
                  Don't have an account? Sign up now
                </p>
              </Link>
            </div>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px !important",
                justifyContent: "center",
              }}
            >
              <h1 style={{ letterSpacing: "6px" }}>Mail Sent ✅</h1>
              <p style={{ fontSize: "20px" }}>Check your inbox 📧</p>
            </Box>
          )}
        </StyledRecPwdForm>
      </StyledRecPwd>
      <ToastContainer />
    </div>
  );
};

export default PasswordRecovery;
