import React, { useState, useEffect } from "react";
import { Box, Avatar } from "@mui/material";
import styled from "styled-components";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import JsonData from "../../../Data/data.Url.json";
import useAuth from "../../../hooks/useAuth";
import { EasyToastify, ToastContainer } from "../../../Extentions/EasyToastify";

const StyledMemberBox = styled(Box)`
  position: relative;

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 40px;
    color: #042045;
    background-color: #f44336;
    padding: 3px;
    border-radius: 30px;
    display: flex;
  }
`;

const OrgMemberList = () => {
  const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [minimalOrgData, setMinimalOrgData] = useState();

  const getMinimalMembers = async () => {
    const response = await axiosPrivate.get("organisation/Get/MinimalMembers");
    setMinimalOrgData(response.data);
    // console.log(response.data);
  };
  useEffect(() => {
    getMinimalMembers();
  }, []);

  const handleRemoveMember = async (id, nickName) => {
    if (window.confirm(`Do you want to Remove ${nickName} from team?`)) {
      await axiosPrivate
        .put(`organisation/Remove/member?id=${id}`, {
          // steamID: registerData.steamID,
          // riotID: registerData.riotID,
        })
        .then((res) => {
          EasyToastify.ToastSuccess("User Deleted");
          getMinimalMembers();
        })
        .catch((err) => {
          EasyToastify.ToastError("Something went wrong");
          // setRegisterData({
          //     steamID: "",
          //     riotID: "",
          // });
        });
    }
  };

  return (
    <Box padding="8px">
      <p>Organisation Members:</p>
      <Box display="flex" gap="16px" padding="12px 0">
        {minimalOrgData
          ? minimalOrgData.map((items, index) => (
              <StyledMemberBox
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="4px"
              >
                {items.profileImgUrl !== null ? (
                  <Avatar
                    src={
                      JsonData.imgurl +
                      items.profileImgUrl.replace("\\\\", "\\")
                    }
                    sx={{ width: 60, height: 60 }}
                  />
                ) : (
                  <Avatar sx={{ width: 60, height: 60 }} />
                )}
                <p>{items.nickName}</p>
                {items.nickName === auth.auth.nickName ? (
                  ""
                ) : (
                  <span
                    onClick={() => handleRemoveMember(items.id, items.nickName)}
                  >
                    <RemoveCircleOutlineIcon sx={{ color: "#fff" }} />
                  </span>
                )}
              </StyledMemberBox>
            ))
          : ""}
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default OrgMemberList;
