import React, { useState, useEffect } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "./Header";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PhotoModal from "./PhotoModal";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import styled from "styled-components";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { EasyToastify } from "../Extentions/EasyToastify";
import { useNavigate, useLocation } from "react-router-dom";
import JsonData from "../Data/data.Url.json";

const StyledImg = styled.img`
  width: 144px;
  height: 81px;
  object-fit: contain;

  @media only screen and (max-width: 480px) {
    width: 40px;
    height: 24px;
  }
`;

const StyledA = styled.a`
  color: #94e2cd;

  @media only screen and (max-width: 480px) {
    font-size: 8px;
  }
`;

const PhotoList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [newsData, setNewsData] = useState(null);
  const [isDataSet, setIsDataSet] = useState(false);
  const [modal, setModal] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      getPhotos();
    }
  };

  const getPhotos = async () => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(
        "/NewsMaker/GetImageForNewsMakerList",
        {
          signal: controller.signal,
        }
      );
      setNewsData(response.data);
    } catch (err) {
      navigate("/", { state: { from: location }, replace: true });
    }
    return () => {
      controller.abort();
    };
  };

  const deletePhoto = async (id) => {
    const controller = new AbortController();
    try {
      await axiosPrivate
        .delete(`/newsmaker/DeleteImage?id=${id}`, {
          signal: controller.signal,
        })
        .then((res) => {
          if (res.status === 200) {
            EasyToastify.ToastSuccess("Image Deleted");
          }
        });
    } catch (err) {
      if (err.request.status === 400) {
        EasyToastify.ToastError("News does not exists");
      } else if (err.request.status === 500) {
        EasyToastify.ToastError("Server Down Or in Maintenance");
      } else {
        EasyToastify.ToastError("Unhendled exeption");
      }
    }
  };

  useEffect(() => {
    if (!isDataSet) {
      getPhotos();
      setIsDataSet(true);
    }
  }, [newsData]);

  const handleDelete = async (e, id) => {
    if (window.confirm("do you want to delete?")) {
      await deletePhoto(id);
    }
    getPhotos();
  };

  const columns = [
    {
      field: "imgUrl",
      headerName: "Photo Preview",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            <StyledImg src={JsonData.imgurl + params.value.replace("\\\\", "\\")} alt="" />
          </a>
        );
      },
    },
    {
      field: "directLink",
      headerName: "Direct Link",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        return (
          <StyledA
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.value}
          </StyledA>
        );
      },
    },
    { field: "gameId", headerName: "Game ID", flex: 0.3 },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => handleDelete(e, params.row.id)}
            variant="contained"
          >
            <DeleteOutlinedIcon />
          </Button>
        );
      },
    },
  ];

  if (newsData === null) {
    setNewsData([]);
  }
  {
    return (
      <Box m="20px">
        <Header title="Photo LIST" subtitle="List of Photos" />
        <Button
          variant="outlined"
          size="large"
          sx={{ color: "green", backgroundColor: "#2f475d" }}
          onClick={toggleModal}
        >
          Add Photo&nbsp;
          <AddCircleOutlineOutlinedIcon fontSize="large" />
        </Button>
        <PhotoModal
          apiUrl="CreateImage"
          modal={modal}
          toggleModal={toggleModal}
          getPhotos={getPhotos}
        />
        <Box
          m="40px 0 0 0"
          height="69vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={newsData}
            getRowHeight={() => "auto"}
            columns={columns}
          />
        </Box>
      </Box>
    );
  }
};

export default PhotoList;
