import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import styled from "styled-components";
import { tokens } from "../../theme";
import axios from "axios";
import JsonData from "../../Data/data.Url.json";
// import { NavLink } from "react-router-dom";

const StyledTypography = styled(Typography)`
  cursor: pointer;
  border: 1px;
  border-radius: 7px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 8px;

  :hover {
    background-color: #155683;
  }
`;

const ShopCategories = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [categoryData, setCategoryData] = useState();

  const getCategories = async () => {
    const response = await axios.get(`${JsonData.apiurl}/Shop/Categories`);
    setCategoryData(response.data);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      marginBottom="50px"
      padding="20px"
      backgroundColor={colors.greenAccent[800]}
      borderRadius="14px"
    >
      {categoryData
        ? categoryData.map((items, index) => (
          <Button
            key={index}
            onClick={() => props.setCategoryState(props.categoryState === items.id ? "" : items.id)}
            sx={{backgroundColor: props.categoryState === items.id ? "#155683" : ""}}
          >
            <StyledTypography
              variant="h4"
              sx={{ color: colors.textColor[100], width: "100%" }}
            >
              {items.name}
            </StyledTypography>
          </Button>
          // <NavLink
          //   end
          //   to={`/shop/search/${items.name}`}
          //   key={index}
          //   style={({ isActive }) => {
          //     return {
          //       backgroundColor: isActive ? "#155683" : "",
          //     };
          //   }}
          // >
          //   <StyledTypography
          //     variant="h4"
          //     style={{ color: colors.textColor[100] }}
          //   >
          //     {items.name}
          //   </StyledTypography>
          // </NavLink>
        ))
        : ""}
      {/* <NavLink end to={"/shop/cyberpc"} style={({ isActive }) => {
                return {
                    backgroundColor: isActive ? "#155683" : "",
                }
            }}>
                <StyledTypography variant="h4" style={{ color: colors.textColor[100] }}>
                    Cyber PC
                </StyledTypography>
            </NavLink>
            */}
    </Box>
  );
};

export default ShopCategories;
