import React, {useState, useEffect} from 'react';
import Pagination from '@mui/material/Pagination';
import {Box, Skeleton, Typography, useTheme} from '@mui/material';
import styled from 'styled-components';
import { tokens } from '../../theme';
import axios from "axios";
import JsonData from "../../Data/data.Url.json";
import useAuth from "../../hooks/useAuth";

const StyledBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 90px;
  padding: 30px 0;

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 768px) {
    
  }
  @media only screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 10px 0;
  }
  @media only screen and (max-width: 320px) {
    
  }
`;

const StyledIframe = styled.iframe`
  border-radius: 24px;
  width: 384px;
  height: 216px;
  padding: 10px;

  @media only screen and (max-width: 480px) {
    width: 349px;
    height: 196.3px;
  }
`;

const StyledTypography = styled(Typography)`

`;

const StyledBoxLines = styled(Box)`
  width: 100%;
  height: 12px;
  border-radius: 10px;
`;

const StyledTitleBox = styled(Box)`
  padding: 30px 0;

  @media only screen and (max-width: 480px) {
    padding: 10px 0;
  }
`;

export default function VideoGallery() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { gameTypeId } = useAuth();
    
    let [page, setPage] = useState(1);
  
    let [pageCount, setPageCount] = useState(10);
  
    const handleChange = (e, p) => {
      setPage(p);
    };

    const [videoData, setVideoData] = useState();
    const FilterByGame = () => {
      if(gameTypeId) {
        if(gameTypeId >= 110 ){
          return "&platform=" + gameTypeId;
      }
        return "&gameType=" + gameTypeId;
      } else {
        return "";
      }
    };

    useEffect(() => {
        let fetchAllData = async () => {
            let response = await axios.get(`${JsonData.apiMedia}/Get/Videos?pageSize=9&pageNumber=${page + FilterByGame()}`);
            setVideoData(response.data);
            setPageCount(response.headers["page-count"]);
        };
        fetchAllData();
    }, [page, gameTypeId]);

    pageCount = Number(pageCount);

    return (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box>
          <StyledTitleBox display="flex" justifyContent="center" alignItems="center" paddingBottom="10px">
            <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginRight="20px"></StyledBoxLines>
            <StyledTypography variant="h3" fontWeight="bold">Videos</StyledTypography>
            <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginLeft="20px"></StyledBoxLines>
          </StyledTitleBox>
          <StyledBox>
            {videoData && videoData.length !== 0 ? (
                videoData.map((embedList, index) => (
                  <StyledIframe key={index} title="Video" src={"https://www.youtube-nocookie.com/embed/" + embedList.videoUrl} frameBorder="0" allow="fullscreen;" style={{backgroundColor: colors.greenAccent[800]}} />
              ))
            ) : (
              [...Array(9)].map((e, i) => 
                <Skeleton key={i} variant="rounded" width={384} height={216} />
              )
            )}
          </StyledBox>
          <Box justifyContent={"center"} alignItems="center" display={"flex"} sx={{padding: "20px 0"}}>
              <Pagination
                size="large"
                shape="rounded"
                variant="outlined"
                count={pageCount}
                page={page}
                onChange={handleChange}
              />
          </Box>
        </Box>
      </Box>
    );
  }
