import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import BackgroundImg from "../Assets/Images/backgroundpwdrec.png";
import BackgroundImgVertical from "../Assets/Images/pwdrevertical.png";
import CyberTVLogo from "../Assets/Images/cyberlogo.png";
import { useState } from "react";
import axios from "axios";
import JsonUrl from "../Data/data.Url.json";
import { EasyToastify, ToastContainer } from "../Extentions/EasyToastify";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";

const StyledRecPwd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-image: url(${BackgroundImg});

  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media only screen and (max-width: 570px) {
    background-image: url(${BackgroundImgVertical});
  }
  .styled-form {
    display: flex;
    flex-direction: column;
    width: 25%;
    background-color: red;
    border-top: 3px green solid;
    border-bottom: 3px green solid;
    border-radius: 10px;
    background-color: transparent;
    align-items: center;
    padding: 20px;
    img {
      width: 200px;
      margin-bottom: 15px;
      @media only screen and (max-width: 1080px) {
        width: 180px;
      }
      @media only screen and (max-width: 570px) {
        width: 150px;
      }
    }

    @media only screen and (max-width: 1500px) {
      width: 35%;
    }
    @media only screen and (max-width: 1080px) {
      width: 45%;
    }
    @media only screen and (max-width: 570px) {
      width: 73%;
    }
    div {
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      /* align-items: center; */
      height: 100%;
      width: 100%;
      gap: 10px;
    }

    input,
    .submit-pwdrec {
      width: 100%;
      font-size: 16px;
      padding: 10px;
      background-color: transparent;
      border-radius: 5px;
      color: #fff;
      border: none;
      border: 0.09rem solid green;
      :focus {
        outline: none;
      }
      ::placeholder {
        color: #d0c9cc;
      }
    }

    label {
      font-size: 20px;
      color: #fff;
    }

    .submit-pwdrec {
      cursor: pointer;
      background-color: green;
    }

    .submit-pwdrec:hover {
      opacity: 0.87;
    }
  }
`;

const PasswordRecovery = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const redirectToHome = () => {
    setTimeout(() => {
      navigate("/");
    }, 3700);
  };

  const passwordValidation = yup
    .string()
    .required()
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
      "Password shoould be 8-20 characters and must include Letter, Number and special character (@, !, $, etc.)"
    );
  const confirmPasswordValidation = yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords Should match");

  const validationSchema = yup.object({
    confirmpassword: confirmPasswordValidation,
    password: passwordValidation,
  });

  const [initialValues] = useState({
    password: "",
    confirmpassword: "",
  });

  const Handlesubmit = async (values) => {
    setDisabled(true);
    axios
      .put(
        `${JsonUrl.apiurl}/Authentification/PasswordReset`,
        { password: values.password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        EasyToastify.ToastSuccess("Password changed 🎉");
        setDisabled(false);
        setSuccess(true);
        redirectToHome();
      })
      .catch((err) => {
        EasyToastify.ToastError("Something wrong ⛔");
        setDisabled(false);
      });
  };

  return (
    <div>
      <StyledRecPwd style={{}}>
        <Formik
          validationSchema={validationSchema}
          onSubmit={Handlesubmit}
          initialValues={initialValues}
        >
          <Form className="styled-form">
            {!success ? (
              <div>
                <label>New Password</label>
                <Field
                  className="users-field"
                  variant="outlined"
                  name="password"
                  margin="dense"
                  type="password"
                  size="small"
                />
                <ErrorMessage name="password" />
                <label>Repeat Password</label>
                <Field
                  className="users-field"
                  variant="outlined"
                  name="confirmpassword"
                  margin="dense"
                  type="password"
                  size="small"
                />
                <ErrorMessage style={{ color: "red" }} name="confirmpassword" />
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: "green",
                    color: "white",
                    opacity: disabled && "0.2",
                    cursor: disabled ? "default" : "pointer",
                  }}
                  disabled={disabled}
                >
                  Update Password
                </Button>
              </div>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px !important",
                  justifyContent: "center",
                }}
              >
                <h1 style={{ letterSpacing: "2px" }}>PASSWORD CHANGED ✅</h1>
                {/* <Link
                to={"/"}
                style={{
                  textDecoration: "none",
                  color: "white",
                  border: "solid 1px white",
                  padding: "5px 20px",
                  borderRadius: "10px",
                }}
              > */}
                <p style={{ fontSize: "20px" }}>Redirecting To home... </p>
                {/* </Link> */}
              </Box>
            )}
          </Form>
        </Formik>
      </StyledRecPwd>
      <ToastContainer />
    </div>
  );
};

export default PasswordRecovery;
