import React, { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import { Box, Button } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ToastContainer, EasyToastify } from "../Extentions/EasyToastify";
// import { ToastContainer,} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosPrivateForm from "../hooks/useAxiosPrivateForm";
import GameId from "../Components/GameId";
import Moment from "moment";
import JsonData from "../Data/data.Url.json";
import PreviewImage from "./PreviewImage";

const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
  .photo-field {
    height: 30px;
  }
`;

const StyledOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(49, 49, 49, 0.8);
`;

const StyledModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2f475d;
  padding: 24px 28px;
  border-radius: 20px;
  width: 700px;

  @media only screen and (max-width: 480px) {
    width: 380px;
  }
`;

const StyledField = styled(Field)`
  font-family: Montserrat, sans-serif;
`;

const StyledRedBox = styled(Box)`
  color: red;
`;

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const validationSchema = yup.object({
  directLink: yup
    .string()
    .min(4, "Too small!")
    .max(1000, "Too long string")
    .required("Title is required!"),
  date: yup.date().required("Date is required"),
  GameId: yup.number().required("Game Type is required!"),
  file: yup
    .mixed()
    .nullable()
    .required()
    .test(
      "FILE_SIZE",
      "Upload file is too big.",
      (value) => !value || (value && value.size <= 2048 * 2048)
    )
    .test(
      "FILE_FORMAT",
      "Upload file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
    ),
});

export default function PhotoModal(props) {
  const fileRef = useRef(null);
  const axiosPrivateForm = useAxiosPrivateForm();

  // POST METHOD
  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg });
  };
  const handleOnSubmit = async (values, actions) => {
    const formData = new FormData();

    if (values.id !== "") {
      formData.append("id", values.id);
    }
    formData.append("image", values.file);
    formData.append("directLink", values.directLink);
    formData.append("GameId", selectGameId);
    formData.append("date", values.date);

    await axiosPrivateForm
      .post(`/NewsMaker/${props.apiUrl}`, formData)
      .then((response) => {
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, "");
        EasyToastify.ToastSuccess("Photo Added");
        props.toggleModal();
        props.getPhotos();
      })
      .catch((error) => {
        console.log(error);
        actions.setSubmitting(false);
        handleServerResponse(false, error.response.data.error);
        EasyToastify.ToastError("Error");
      });
  };

  const [images] = useState(
    props.images
      ? props.images
      : {
          imgUrl: "",
          directLink: "",
          GameId: 1,
          date: "",
        }
  );

  const [selectGameId, setSelectGameId] = useState();
  const handleSelectGameIdChange = (e) => {
    setSelectGameId(e.target.value);
  };

  return (
    <Box>
      {props.modal && (
        <StyledModal>
          <StyledOverlay onClick={props.toggleModal}></StyledOverlay>
          <StyledModalContent>
            <Formik
              // validateOnMount={true}
              initialValues={images}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Box display="flex" flexDirection="column">
                    <label>Direct Link: </label>
                    <Field
                      className="photo-field"
                      name="directLink"
                      type="text"
                      placeholder={`Enter Direct link...`}
                    />
                    <StyledRedBox>
                      <ErrorMessage name="directLink" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <label>Game:</label>
                    <StyledField
                      className="photo-field"
                      name="GameId"
                      as="select"
                      value={selectGameId}
                      onChange={handleSelectGameIdChange}
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled>
                        Click to Select Game
                      </option>
                      <GameId />
                    </StyledField>
                    <StyledRedBox>
                      <ErrorMessage name="GameId" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <label>Date:</label>
                    <Field className="photo-field" name="date" type="date" />
                    <StyledRedBox>
                      <ErrorMessage name="date" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column" paddingTop="8px">
                    <input
                      ref={fileRef}
                      hidden
                      type="file"
                      name="file"
                      onChange={(event) => {
                        setFieldValue("file", event.target.files[0]);
                      }}
                    />
                    {values.file ? (
                      <PreviewImage
                        file={values.file}
                        width="192px"
                        height="108px"
                      />
                    ) : values.imgUrl != null ? (
                      <img
                        src={
                          JsonData.imgurl + values.imgUrl.replace("\\\\", "\\")
                        }
                        alt=""
                        style={{ width: "192px", height: "108px" }}
                      />
                    ) : (
                      ""
                    )}
                    <Button
                      variant="contained"
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    >
                      Upload Photo
                    </Button>
                    <StyledRedBox>
                      <ErrorMessage name="file" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column" paddingTop="8px">
                    <Button variant="contained" type="submit">
                      Submit
                    </Button>
                  </Box>
                  {serverState && (
                    <p className={!serverState.ok ? "errorMsg" : ""}>
                      {serverState.msg}
                    </p>
                  )}
                </Form>
              )}
            </Formik>
            {/*  */}
            <Button
              variant="text"
              sx={{
                color: "red",
                position: "absolute",
                top: "3px",
                right: "5px",
              }}
              onClick={props.toggleModal}
            >
              <CancelOutlinedIcon />
            </Button>
          </StyledModalContent>
        </StyledModal>
      )}
      <ToastContainer />
    </Box>
  );
}
