import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, Skeleton, Avatar } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import JsonData from "../../Data/data.Url.json";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkIcon from "@mui/icons-material/Link";
import Moment from "moment";
import { string } from "yup";
import { array } from "yup/lib/locale";

const StyledImg = styled.img`
  width: 960px;
  height: 540px;
  object-fit: contain;

  @media only screen and (max-width: 900px) {
    width: 640px;
    height: 360px;
  }
  @media only screen and (max-width: 768px) {
    width: 548.5px;
    height: 308.5px;
  }
  @media only screen and (max-width: 480px) {
    width: 384px;
    height: 216px;
  }
  @media only screen and (max-width: 320px) {
    width: 320px;
    height: 180px;
  }
`;

const StyledBigSkeleton = styled(Skeleton)`
  width: 960px !important;
  height: 540px !important;

  @media only screen and (max-width: 900px) {
    width: 640px !important;
    height: 360px !important;
  }
  @media only screen and (max-width: 768px) {
    width: 548.5px !important;
    height: 308.5px !important;
  }
  @media only screen and (max-width: 480px) {
    width: 384px !important;
    height: 216px !important;
  }
  @media only screen and (max-width: 320px) {
    width: 320px !important;
    height: 180px !important;
  }
`;

const StyledP = styled.p`
  font-size: 16px;
  white-space: pre-wrap;

  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 10px;
  }
  @media only screen and (max-width: 320px) {
    font-size: 8px;
  }
`;

const StyledTypography = styled(Typography)`
  @media only screen and (max-width: 900px) {
    font-size: 32px !important;
  }
  @media only screen and (max-width: 768px) {
    font-size: 28px !important;
  }
  @media only screen and (max-width: 480px) {
    font-size: 22px !important;
  }
  @media only screen and (max-width: 320px) {
    font-size: 18px !important;
  }
`;

const StyledVideoBox = styled(Box)`
  @media only screen and (max-width: 480px) {
    justify-content: center;
    padding-bottom: 12px;
  }

  iframe {
    border-radius: 24px;
    width: 499.2px;
    height: 280.8px;

    @media only screen and (max-width: 480px) {
      width: 345.6px;
      height: 194.4px;
    }
  }
`;

// function Text({ content }) {
//   const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
//   const BOLD_REGEX = /\bboldbold\b/gi;
//   const HIGH_REGEX = /\bhighhigh\b/gi;
//   // const LINK_REGEX = /\blinklink\b/gi;

// 	const words = content.split(' ');

// 	return (
// 		<>
// 			{words.map((word, index) => {
// 				return word.match(URL_REGEX) ? (
// 					<React.Fragment key={index}>
// 						<a href={word} target="_blank" style={{color: "#4271ed"}}>{word}</a>{' '}
// 					</React.Fragment>
// 				) : word.match(BOLD_REGEX) ? (
//           <React.Fragment key={index}>
// 						<b>{word.slice(8)}</b>{' '}
// 					</React.Fragment>
//         ) : word.match(HIGH_REGEX) ? (
//           <React.Fragment key={index}>
// 						<mark style={{backgroundColor: "#4271ed"}}>{word}</mark>{' '}
// 					</React.Fragment>
//         ) : ( 
// 					word + ' '
// 				);
// 			})}
// 		</>
// 	);
// }

function StyleText(props) {

  let content = props.content
  const jsxBuilder = [];
  let word = null;

  let appendWord = () => {
    if (word) {
      jsxBuilder.push(word);
      word = null;
    }
  }

  for (let i = 0; i < content.length - 1; i++) {
    let index = content[i] + content[i + 1]
    switch (index) {
      case "$h":
        i += 2;
        appendWord();
        let hilighted = "";
        while (content[i] + content[i + 1] !== "$j") {
          hilighted += content[i];
          i++;
        }
        i++;
        jsxBuilder.push(<div style={{ backgroundColor: "#3792cb", margin: "15px 12px", borderRadius: "12px", padding: "2px" }}><span>{StyleText({ content: hilighted })}</span></div>);
        break;
      case "$b":
        appendWord();
        i += 2;
        let bold = "";

        while (content[i] + content[i + 1] !== "$n") {
          bold += content[i];
          i++;
        }
        i++;
        jsxBuilder.push(<b>{bold}</b>);
        break;
      case "$l":
        appendWord();
        i += 2;
        let link = "";
        while (content[i] + content[i + 1] !== "$k") {

          link += content[i];
          i++;
        }
        i++;
        const l = link.split("$i");
        jsxBuilder.push(
          <a href={l[1]} target="_blank" style={{ color: "#1c4966" }}>{l[0]}</a>
        );
        break;
      default:
        word = word ? word + content[i] : content[i];
        break;
    }
  }
  jsxBuilder.push(word);
  return (jsxBuilder);
}

const SingleNews = () => {
  const { id } = useParams();

  const [newsData, setNewsData] = useState(null);

  useEffect(() => {
    let fetchData = async () => {
      let response = await axios.get(
        `${JsonData.apiurl}/News/GetNewsById?Id=${id}`
      );
      setNewsData(response.data);
    };
    fetchData();
  }, []);

  const shareUrl = window.location.href;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="24px"
      p="40px 0"
    >
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="24px"
        >
          {newsData ? (
            <StyledImg
              src={JsonData.imgurl + newsData.imgUrl.replace("\\\\", "\\")}
              alt=""
            />
          ) : (
            <StyledBigSkeleton variant="rounded" />
          )}
          <StyledTypography variant="h1">
            {newsData ? newsData.title : <Skeleton width={960} />}
          </StyledTypography>
        </Box>
        <Box display="flex" flexDirection="column" gap="24px" paddingTop="24px" maxWidth="1200px">
          <Box display="flex" flexDirection="column" gap="5px">
            {/* <StyledP>
              {newsData ? newsData.description : <Skeleton />}
            </StyledP> */}
            {newsData ? <StyledP><StyleText content={newsData.description} /></StyledP> : <Skeleton />}
          </Box>
          {newsData && newsData.videoUrl != null ? (
            <StyledVideoBox display="flex">
              <iframe title="Video" src={"https://www.youtube-nocookie.com/embed/" + newsData.videoUrl} frameBorder="0" allow="fullscreen;" />
            </StyledVideoBox>
          ) : ""}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="end"
        >
          <Box display="flex" gap="3px">
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
                  `${JsonData.imgurl + newsData.imgUrl.replace("\\\\", "\\")}`,
                  "_blank"
                );
              }}
            >
              <FacebookIcon fontSize="large" sx={{ color: "#a8a8a8" }} />
            </Link>
            <Link
              to={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}
              target="_blank"
            >
              <TwitterIcon fontSize="large" sx={{ color: "#a8a8a8" }} />
            </Link>
            <Link
              to={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}
              target="_blank"
            >
              <LinkedInIcon fontSize="large" sx={{ color: "#a8a8a8" }} />
            </Link>
            <Link
              to={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}
              target="_blank"
            >
              <EmailIcon fontSize="large" sx={{ color: "#a8a8a8" }} />
            </Link>
            <Link
              onClick={() => {
                navigator.clipboard
                  .writeText(shareUrl)
                  .then(alert("Link copied to clipboard"));
              }}
            >
              <LinkIcon fontSize="large" sx={{ color: "#a8a8a8" }} />
            </Link>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="end" gap="5px">
            <Box display="flex" gap="5px" alignItems="center">
              <p>
                {newsData ? (
                  newsData.user.firstname + ` "` + newsData.user.nickName + `" ` + newsData.user.lastname
                ) : (
                  <Skeleton width={148} />
                )}
              </p>
              {newsData ? (
                newsData.user.profileImgUrl != null ? (
                  <Avatar src={JsonData.imgurl + newsData.user.profileImgUrl.replace("\\\\", "\\")} sx={{ width: 34, height: 34 }} />
                ) : (
                  <Avatar sx={{ width: 34, height: 34 }} />
                )
              ) : (
                <Skeleton variant="circular" width={34} height={34} />
              )}
            </Box>
            <p>
              {newsData != null ? (
                Moment(newsData.date).format("DD-MM-YYYY")
              ) : (
                <Skeleton width={164} />
              )}
            </p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleNews;
