import React, {useState, memo} from "react";
import {ComposableMap, Geographies, Geography, Marker, ZoomableGroup} from "react-simple-maps";
import {Box, InputLabel, MenuItem, FormControl, Select} from '@mui/material';
import MarkersArrayData from '../../Data/markersArray.json';
import styled from "styled-components";

const StyledBoxCont = styled(Box)`
    width: 68vw;

    @media only screen and (max-width: 1200px) {
        height: 78vh;
    }
    @media only screen and (max-width: 900px) {
        /* height: 100vh; */
    }
    @media only screen and (max-width: 768px) {
        width: 98vw;
        height: 80vh;
    }
    @media only screen and (max-width: 480px) {
        height: 76vh;
    }
    @media only screen and (max-width: 320px) {
        
    }
`;

const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const Organizations = ({setTooltipContent}) => {
    const [category, setCategory] = useState(1);

    const handleChange = (event) => {
        setCategory(event.target.value);
    };

  return (
    <StyledBoxCont justifyContent="center" display="flex" flexDirection="column">
        <Box sx={{ margin: "20px 0" }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    label="Category"
                    onChange={handleChange}
                >
                    {MarkersArrayData.map(({id, title}, index) => (
                        <MenuItem value={id} key={index}>{title}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
        <ComposableMap projectionConfig={{ rotate: [-25, 0, 0] }} data-tip="">
            <ZoomableGroup center={[0, 0]} >
                <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                        geographies.map((geo) => (
                            <Geography key={geo.rsmKey} geography={geo} fill="#DDD" stroke="#e0eaff" strokeWidth={.6} style={{default: { fill: "#6992e6", outline: "none" }, hover: { fill: "#0a3da5", outline: "none" }, pressed: { fill: "#002b81", outline: "none" }}} />
                        ))
                    }
                </Geographies>
                {MarkersArrayData.map((items, index) => (
                    <React.Fragment key={index}>
                        {items.markersInfos.map((dataItems, i) => (
                            <Marker 
                                coordinates={dataItems.coordinates}
                                onMouseEnter={() => {
                                    setTooltipContent(`${dataItems.tooltipContentTitle}`);
                                }}
                                onMouseLeave={() => {
                                    setTooltipContent("");
                                }}
                                key={i}
                            >
                                <circle r={4} fill="#F00" stroke="#fff" strokeWidth={1} />
                                <text
                                    textAnchor="middle"
                                    y={dataItems.markerOffset}
                                    style={{fontFamily: "system-ui", fill: "#5D5A6D"}}
                                >
                                </text>
                            </Marker>
                        ))}
                    </React.Fragment>
                ))[category - 1]}
            </ZoomableGroup>
        </ComposableMap>
    </StyledBoxCont>
  );
};

export default memo(Organizations);
