import useAxiosPrivate from "./useAxiosPrivate";
import { EasyToastify } from "../Extentions/EasyToastify";

const useProductList = () => {
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const UpdateProduct = async (Id) => {
    try {
      const response = await axiosPrivate.get(
        `Shop/Products/${Id}`,
        {
          signal: controller.signal,
        }
      );
      controller.abort();
      // console.log(response.data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return () => {
      controller.abort();
    };
  };
  const DeleteProduct = async (Id) => {
    try {
      const response = await axiosPrivate.delete(
        `Shop/Products?id=${Id}`,
        {
          signal: controller.signal,
        }
      );
      if (response.status === 200) {
        EasyToastify.ToastSuccess("Product Deleted");
      }
      controller.abort();
    } catch (err) {
      EasyToastify.ToastError("Something went wrong");
    }
    controller.abort();
  };
  return [UpdateProduct, DeleteProduct];
};

export default useProductList;
