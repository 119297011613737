import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "./Header";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { mockDataTeam } from "../Data/mockData.js";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";

const StyledImg = styled.img`
  width: 144px;
  height: 81px;
  object-fit: contain;

  @media only screen and (max-width: 480px) {
    width: 40px;
    height: 24px;
  }
`;

const Tournaments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [tournamentsData, setTournamentsData] = useState();

  const getTournaments = async () => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get("/tournament/get", {
        signal: controller.signal,
      });
      setTournamentsData(response.data);
    } catch (err) {
      console.error(err);
      navigate("/", { state: { from: location }, replace: true });
    }
    return () => {
      controller.abort();
    };
  };

  useEffect(() => {
    getTournaments();
  }, [getTournaments]);

  const columns = [
    {
      field: "id",
      headerName: "Tournament-ID",
      flex: 0.5,
      // renderCell: (params) => {
      //   return (
      //     <StyledImg
      //       // src={JsonData.imgurl + params.value.replace("\\\\", "/")}
      //       alt="img"
      //     />
      //   );
      // },
    },
    {
      field: "title",
      headerName: "Tournament Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "gameType", headerName: "Game Type", flex: 0.3 },
    { field: "shortDescription", headerName: "Short Description", flex: 1.5 },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            // onClick={async (e) => await onEditClick(e, params.row.id)}
            variant="contained"
          >
            <ModeEditOutlinedIcon />
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            // onClick={(e) => onDeleteClick(e, params.row.id)}
            variant="contained"
          >
            <DeleteOutlinedIcon />
          </Button>
        );
      },
    },
  ];
  if (tournamentsData != null) {
    return (
      <Box m="20px">
        <Header title="Tournament List" />
        <Link to="/admin/tournaments/create">
          <Button
            variant="outlined"
            size="large"
            sx={{ color: "green", backgroundColor: "#2f475d" }}
            // onClick={toggleModal}
          >
            Create Tournament&nbsp;
            <AddCircleOutlineOutlinedIcon fontSize="large" />
          </Button>
        </Link>
        <Box
          m="40px 0 0 0"
          height="69vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          {/* checkboxSelection */}
          <DataGrid
            rows={tournamentsData.map((data) => {
              return {
                id: data.id,
                title: data.name,
                gameType: data.gameId,
                shortDescription: data.about,
              };
            })}
            columns={columns}
          />
        </Box>
      </Box>
    );
  }
};

export default Tournaments;
