import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';
import { useParams } from "react-router-dom";
import axios from 'axios';
import JsonData from "../../Data/data.Url.json";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const StyledSwiper = styled(Swiper)`
  .swiper-slide {
      padding-top: 100%;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }

    .swiper-button-prev {
      left: 0;
      color: #fff;
    }

    .swiper-button-next {
      right: 0;
      color: #fff;
    }
`;

const StyledSwiperThumb = styled(Swiper)`
  .swiper-slide {
    cursor: pointer;
    border: 1px solid #dddbdb;
  }

  .swiper-slide-thumb-active {
    border-color: #0080ff;
  }

  .product-images-slider-thumbs-wrapper {
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
`;

const StyledDiv = styled.div`
  max-width: 480px;
  padding: 16px;

  @media only screen and (max-width: 480px) {
    max-width: 294px;
  }
`;

const ProductPhotoSlider = (props) => {
  const { id } = useParams();

  const [productImageData, setProductImageData] = useState(null);
  const getSingleProductImage = async () => {
    const photoArray = [];
    photoArray.push({imgUrl: props.mainImage});

    const response = await axios.get(
        `${JsonData.apiurl}/Shop/Image?id=${id}`
    );
    response.data.forEach(e => {
      photoArray.push(e);
    });
    setProductImageData(photoArray);
  };

  useEffect(() => {
    getSingleProductImage();
  }, []);

  // console.log(photoArray);

  const [activeThumb, setActiveThumb] = useState();

  if(productImageData) {
    return (
      <div style={{
        // height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <StyledDiv>
          <StyledSwiper
            loop={true}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation, Thumbs]}
            grabCursor={true}
            // thumbs={{ swiper: activeThumb }}
            thumbs={{ swiper: activeThumb && !activeThumb.destroyed ? activeThumb : null }}
            className='product-images-slider'
          >
            {
              productImageData.map((item, index) => (
                <SwiperSlide key={index}>
                  <img src={JsonData.imgurl + item.imgUrl} alt="product images" />
                </SwiperSlide>
              ))
            }
          </StyledSwiper>
          <StyledSwiperThumb
            onSwiper={setActiveThumb}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            modules={[Navigation, Thumbs]}
            className='product-images-slider-thumbs'
          >
            {
              productImageData.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="product-images-slider-thumbs-wrapper">
                    <img src={JsonData.imgurl + item.imgUrl} alt="product images" />
                  </div>
                </SwiperSlide>
              ))
            }
          </StyledSwiperThumb>
        </StyledDiv>
      </div>
    )
  }
}

export default ProductPhotoSlider;
