import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { useState } from "react";
import CyberLogo from "../Assets/Images/cyberlogo.png";
import CyberLogoBlue from "../Assets/Images/cyberBlueTV.png";
import HeaderTop from "./HeaderTop";
import { Link } from "react-router-dom";
import { Menu, MenuItem, Button, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import HeaderTopSocials from "./HeaderTopSocials";

const StyledWrap = styled.div``;

const StyledHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 300px;
  height: 80px;

  @media only screen and (max-width: 1024px) {
    justify-content: center;
    padding: 0 20px;
  }
`;

const StyledHeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 200px;

    @media only screen and (max-width: 480px) {
      width: 150px;
    }
  }
`;

const RightHeader = styled.div`
  display: flex;
  align-items: center;

  .menu {
    /* visibility: hidden; */
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    .menu {
      /* visibility: visible; */
      display: block;
      cursor: pointer;
    }
  }
`;

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  gap: 30px;

  ul {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }

  li {
    position: relative;
  }

  li::after {
    content: "";
    position: absolute;
    background-color: white;
    height: 2px;
    width: 0;
    left: 0;
    bottom: -10px;
    transition: 0.3s;
  }

  li:hover::after {
    width: 100%;
  }

  a {
    color: #fff;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .close {
    /* visibility: hidden; */
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    z-index: 11;
    flex-flow: column nowrap;
    /* background-color: #010c20; */
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100%;
    width: 230px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    ul {
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
    }

    .close {
      /* visibility: visible; */
      display: block;
      z-index: 2;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
`;

const StyledLink = styled(Link)`
  color: #fff;
`;

function Header() {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Media Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledWrap>
      <HeaderTop />
      <StyledHeader backgroundColor={colors.greenAccent[800]}>
        <StyledHeaderBox>
          <StyledLogo>
            <Link to="/">
              <img
                src={theme.palette.mode === "dark" ? CyberLogo : CyberLogoBlue}
                alt="CyberLogo"
              ></img>
            </Link>
          </StyledLogo>
          <RightHeader>
            <StyledNav
              open={open}
              style={{ backgroundColor: colors.greenAccent[800] }}
            >
              <ul>
                <li>
                  <Link to="/" style={{ color: colors.textColor[100] }}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/news" style={{ color: colors.textColor[100] }}>
                    News
                  </Link>
                </li>
                <li>
                  <Link
                    to="/tournaments"
                    style={{ color: colors.textColor[100] }}
                  >
                    Tournaments
                  </Link>
                </li>
                <li>
                  <Button
                    id="basic-button"
                    aria-controls={openMenu ? "basic-menu" : undefined}
                    aria-haspopup={true}
                    aria-expanded={openMenu ? true : undefined}
                    onClick={handleClick}
                    sx={{
                      color: "#FFF",
                      textTransform: "none",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                    style={{ color: colors.textColor[100] }}
                  >
                    Media
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button", sx: {backgroundColor: colors.greenAccent[800]}
                    }}
                  >
                    <MenuItem onClick={handleClose}>
                      <StyledLink
                        to="/media/video"
                        style={{ color: colors.textColor[100] }}
                      >
                        Video Gallery
                      </StyledLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <StyledLink
                        to="/media/photo"
                        style={{ color: colors.textColor[100] }}
                      >
                        Photo Gallery
                      </StyledLink>
                    </MenuItem>
                  </Menu>
                </li>
                <li>
                  <Link
                    to="/organizations"
                    style={{ color: colors.textColor[100] }}
                  >
                    Organizations
                  </Link>
                </li>
                <li>
                  <Link to="/rankings" style={{ color: colors.textColor[100] }}>
                    Rankings
                  </Link>
                </li>
                <li>
                  <Link to="/shop" style={{ color: colors.textColor[100] }}>
                    Shop
                  </Link>
                </li>
              </ul>
              <CloseIcon
                className="close"
                open={open}
                onClick={() => setOpen(!open)}
                style={{ color: colors.textColor[100] }}
              />
            </StyledNav>
            <MenuIcon
              className="menu"
              open={open}
              onClick={() => setOpen(!open)}
              style={{ color: colors.textColor[100] }}
            />
          </RightHeader>
        </StyledHeaderBox>
      </StyledHeader>
    </StyledWrap>
  );
}

export default Header;
