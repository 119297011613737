import React from 'react';
import {Box, Typography} from '@mui/material';
import styled, {keyframes} from 'styled-components';

const bounceAnimation = keyframes`
  to {
    opacity: .8;
    transform: translateY(10px);
  }
`;

const BouncingLoader = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 4px;

  div {
    width: 8px;
    height: 8px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 1;
    animation: ${bounceAnimation} .7s infinite alternate;

    &:nth-child(2) {
      animation-delay: .2s;
    }

    &:nth-child(3)  {
      animation-delay: .4s;
    }
  }
`;

const ComingSoon = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="80vh">
        <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h2">COMING SOON</Typography>
            <BouncingLoader>
              <div></div>
              <div></div>
              <div></div>
            </BouncingLoader>
        </Box>
    </Box>
  )
}

export default ComingSoon;
