import React, { useState } from "react";

const PreviewImage = (props) => {
  const [preview, setPreview] = useState(null);
  const reader = new FileReader();
  reader.readAsDataURL(props.file);
  reader.onload = () => {
    setPreview(reader.result);
  };

  return (
    <div>
      {preview ? (
        <img
          src={preview}
          alt="Preview"
          width={props.width}
          height={props.height}
        />
      ) : (
        "loading..."
      )}
    </div>
  );
};

export default PreviewImage;
