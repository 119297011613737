import { useEffect, useRef, useState } from "react";

// MUI imports
import { Button } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import SaveIcon from "@mui/icons-material/Save";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import GroupsIcon from "@mui/icons-material/Groups";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";

// General imports
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
// Component imports
import PreviewImage from "../../Dashboard/PreviewImage";
import Matches from "./Matches";
import Orginisers from "./Orginisers";
import Sponsors from "./Sponsors";
import Teams from "./Teams";
import useAxiosPrivateForm from "../../hooks/useAxiosPrivateForm";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { EasyToastify } from "../../Extentions/EasyToastify";
import JsonData from "../../Data/data.Url.json";
import GameId from "../GameId";

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 10px;
`;

const StyledHead = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: auto;
`;

const StyledContentTop = styled.div`
  Form {
    height: 100%;
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    input,
    select {
      height: 30px;
      font-size: 13px;
    }

    .group-about-cont {
      display: flex;
      height: 100%;
      width: 100%;
      gap: 20px;
    }
    .aboutTournament,
    .groupsCont {
      width: 100%;
      height: 100%;
      /* gap: 10px; */
    }
    .aboutTournament textarea {
      height: 100%;
    }
    .groupsCont {
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      gap: 20px;
    }
    input,
    select,
    textarea {
      border-radius: 3px;
      border: solid 0.25px black;
    }
    input:focus,
    textarea:focus,
    select:focus {
      outline: none;
    }
  }
  .formGroup2,
  .formGroup1,
  .formGroup4 {
    display: flex;
    gap: 20px;
  }
  .group2,
  .group1,
  .group4 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .group2Manual {
    display: flex;
    flex-direction: column;

    input {
      height: 100%;
      width: 100%;
    }
  }

  .formGroup3 {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    height: 100%;
  }

  .group3Img {
    display: flex;
    height: 135px;
    justify-content: space-between;
  }

  .group3 {
    display: flex;
    flex-direction: column;
  }
  .group3Cont {
    display: flex;
    gap: 20px;
  }

  .addPhotoShortCut {
    border: solid 1px white;
    border-radius: 3px;
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .addPhotoShortCut:hover {
    color: grey;
    border: none;
    box-shadow: 1px 1px 70px 1px #c0cbee inset;
  }

  .imgPreview {
    display: flex;
    flex-direction: column;
  }
  .imgPreview div {
    height: 100%;
  }
  .aboutTournament {
    display: flex;
    flex-direction: column;
  }
`;

const StyledContentUnder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  .underContentHeaders {
    display: flex;
    gap: 10px;
  }

  h2 {
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  h2:hover {
    opacity: 0.8;
  }
`;

const StyledBottom = styled.div`
  display: flex;
  margin-left: auto;
`;

const CreateTournament = () => {
  const { id } = useParams();
  const fileRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivateForm = useAxiosPrivateForm();
  const axiosPrivate = useAxiosPrivate();
  const [currentTab, setCurrentTab] = useState(1);
  const [tournamentType, setTournamentType] = useState();
  const [tournamentGameType, setTournamentGameType] = useState();
  const [teamSizeType, setTeamSizeType] = useState();
  const [currentTournamentData, setCurrentTournamentData] = useState();

  const toggleTab = () => {
    switch (currentTab) {
      case 1:
        return (
          <Sponsors
            tournamentId={currentTournamentData ? currentTournamentData.id : ""}
          />
        );
      case 2:
        return (
          <Orginisers
            tournamentId={currentTournamentData ? currentTournamentData.id : ""}
          />
        );
      case 3:
        return (
          <Teams
            tournamentId={currentTournamentData ? currentTournamentData.id : ""}
          />
        );
      case 4:
        return (
          <Matches
            tournamentId={currentTournamentData ? currentTournamentData.id : ""}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (id) {
      getTournament();
    }
  }, [id]);

  const getTournament = async () => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(`tournament/get/${id}`, {
        signal: controller.signal,
      });
      setCurrentTournamentData(response.data);
    } catch (err) {
      console.error(err);
      navigate("/", { state: { from: location }, replace: true });
    }
    return () => {
      controller.abort();
    };
  };

  const handleSubmit = async (values, actions) => {
    const formData = new FormData();

    formData.append("Name", values.name);
    formData.append("Type", tournamentType);
    formData.append("GameId", tournamentGameType);
    formData.append("teamSize", teamSizeType);
    formData.append("TournamentStartDate", values.tournamentStartDate);
    formData.append("RegistrationStartDate", values.registrationStartDate);
    formData.append("RegistrationEndDate", values.registrationEndDate);
    formData.append("About", values.about);
    formData.append("Rule", values.rule);
    formData.append("EntryFee", values.entryFee);
    formData.append("Img", values.file);
    console.log("done");

    await axiosPrivateForm
      .post("/Tournament/Create", formData)
      .then((response) => {
        actions.setSubmitting(false);
        actions.resetForm();
        // handleServerResponse(true, "");
        EasyToastify.ToastSuccess("Tournament Created");
        navigate(`${response.data}`);
        // props.toggleModal();
      })
      .catch((error) => {
        console.log(error);
        actions.setSubmitting(false);
        EasyToastify.ToastError("Error");
      });
  };

  return (
    <StyledContainer>
      <StyledContentTop className="content content-top">
        <Formik
          initialValues={currentTournamentData ? currentTournamentData : {}}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <StyledHead>
                <Button
                  sx={{ color: "white", backgroundColor: "orange" }}
                  variant="contained"
                >
                  <ModeEditOutlinedIcon variant="contained" />
                </Button>
                <Button
                  sx={{ color: "white", backgroundColor: "red" }}
                  variant="contained"
                >
                  <DeleteOutlinedIcon variant="contained" />
                </Button>
                <Button
                  sx={{
                    color: "white",
                    backgroundColor: "green",
                    display: "flex",
                    gap: "10px",
                  }}
                  variant="contained"
                  type="submit"
                >
                  Save
                  <SaveIcon variant="contained" />
                </Button>
              </StyledHead>
              <div className="group-about-cont">
                <div className="groupsCont">
                  <div className="formGroup1">
                    <div className="group1">
                      <label>Tournament ID</label>
                      <Field
                        type="text"
                        name="id"
                        value={
                          currentTournamentData
                            ? currentTournamentData.id
                            : "Empty"
                        }
                        readonly
                      />
                    </div>
                    <div className="group1">
                      <label>Toruanment Name</label>
                      <Field type="text" name="name" required={true} />
                    </div>
                    <div className="group1">
                      <label>Tournament Type</label>
                      <select
                        name="type"
                        required={true}
                        onChange={(e) => {
                          setTournamentType(e.target.value);
                        }}
                        defaultValue={false ? "data.tournamentType" : 1}
                        as="select"
                      >
                        <option value={1}>Single Elimination</option>
                        <option value={2}>Double Elimination</option>
                      </select>
                    </div>
                    <div className="group1">
                      <label>Tournament Game Type</label>
                      <select
                        name="gameId"
                        required={true}
                        defaultValue={false ? "data.gameType" : 1}
                        as="select"
                        onChange={(e) => {
                          setTournamentGameType(e.target.value);
                        }}
                      >
                        <GameId />
                      </select>
                    </div>
                  </div>
                  <div className="formGroup2">
                    <div className="group2">
                      <label>Starting Date of Tournament</label>
                      <Field
                        type="datetime-local"
                        name="tournamentStartDate"
                        required={true}
                      />
                    </div>
                    <div className="group2">
                      <label>Starting date of Registration </label>
                      <Field
                        type="datetime-local"
                        name="registrationStartDate"
                        required={true}
                      />
                    </div>
                    <div className="group2">
                      <label>Ending date of Registration </label>
                      <Field
                        type="datetime-local"
                        name="registrationEndDate"
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="formGroup3">
                    <div className="group3Cont">
                      <div className="group3">
                        <label>Team Size*</label>
                        <select
                          name="teamSize"
                          required={true}
                          defaultValue={false ? "data.gameType" : 1}
                          as="select"
                          onChange={(e) => {
                            setTeamSizeType(e.target.value);
                          }}
                        >
                          <option value={1}>1vs1</option>
                          <option value={2}>2vs2</option>
                          <option value={3}>3vs3</option>
                          <option value={4}>4vs4</option>
                          <option value={5}>5vs5</option>
                        </select>
                      </div>
                      <div className="group3">
                        <label>Entry Fee (enter 0 if it's FREE) </label>
                        <Field type="number" name="entryFee" required={true} />
                      </div>
                    </div>
                    <div className="group3Img">
                      <input
                        ref={fileRef}
                        hidden
                        type="file"
                        name="imgUrl"
                        onChange={(event) => {
                          setFieldValue("file", event.target.files[0]);
                        }}
                      />
                      <div className="imgPreview">
                        {!values.file && values.imgUrl == null ? (
                          <div
                            className="addPhotoShortCut"
                            onClick={() => {
                              fileRef.current.click();
                            }}
                          >
                            <AddPhotoAlternateIcon fontSize="large" />
                            <p>Add Photo</p>
                          </div>
                        ) : (
                          ""
                        )}

                        {values.file ? (
                          <>
                            <PreviewImage
                              file={values.file}
                              width="240px"
                              height="135px"
                              border="10px"
                            />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                // backgroundColor: "red",
                                justifyContent: "space-between",
                                marginTop: "3px",
                              }}
                            >
                              <p style={{ color: "green" }}>File uploaded</p>
                              <p
                                onClick={() => {
                                  setFieldValue("file", null);
                                }}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",

                                  gap: "3px",
                                }}
                              >
                                Remove
                                <FolderDeleteIcon color="error" />
                              </p>
                            </div>
                          </>
                        ) : values.imgUrl != null ? (
                          <>
                            <img
                              src={
                                JsonData.imgurl +
                                values.imgUrl.replace("\\\\", "\\")
                              }
                              alt="tournament image"
                              style={{ width: "240px", height: "135px" }}
                            />

                            <p
                              onClick={() => {
                                fileRef.current.click();
                              }}
                              style={{
                                cursor: "pointer",
                                display: "flex",

                                gap: "3px",
                              }}
                            >
                              UPLOAD NEW IMAGE
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="aboutTournament">
                  <label>About Tournament</label>
                  <Field
                    type="text"
                    as="textarea"
                    name="about"
                    required={true}
                    style={{
                      resize: "none",
                      fontSize: "17px",
                      padding: "5px",
                    }}
                  />
                  <label>Tournament Rules</label>
                  <Field
                    type="text"
                    as="textarea"
                    name="rule"
                    required={true}
                    style={{
                      resize: "none",
                      fontSize: "17px",
                      padding: "5px",
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </StyledContentTop>
      {currentTournamentData ? (
        <StyledContentUnder className="content content-under">
          <div className="underContentHeaders">
            <h2
              onClick={() => setCurrentTab(1)}
              style={{
                borderTop: "solid 2px green",
                borderBottom: "solid 1px green",
              }}
            >
              <MonetizationOnIcon
                sx={{
                  color: "green",
                }}
              />
              Sponsors
            </h2>
            <h2
              onClick={() => setCurrentTab(2)}
              style={{
                borderTop: "solid 2px orange",
                borderBottom: "solid 1px orange",
              }}
            >
              <AccountBoxIcon sx={{ color: "orange" }} />
              Orginisers
            </h2>
            <h2
              onClick={() => setCurrentTab(3)}
              style={{
                borderTop: "solid 2px cyan",
                borderBottom: "solid 1px cyan",
              }}
            >
              <GroupsIcon sx={{ color: "cyan" }} />
              Teams
            </h2>
            <h2
              onClick={() => setCurrentTab(4)}
              style={{
                borderTop: "solid 2px white",
                borderBottom: "solid 1px white",
              }}
            >
              <FormatListBulletedIcon />
              Matches
            </h2>
          </div>
          <div>{toggleTab()}</div>
        </StyledContentUnder>
      ) : (
        ""
      )}

      <StyledBottom className="footer"></StyledBottom>
    </StyledContainer>
  );
};
export default CreateTournament;
