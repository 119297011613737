import React, {useState} from 'react';
import Organizations from './Organizations';
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';
import { tokens } from '../../theme';
import {useTheme} from "@mui/material";
import Box from '@mui/material/Box';

const OrgsToolTip = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [content, setContent] = useState("");

  return (
    <Box display="flex" justifyContent="center">
        <Organizations setTooltipContent={setContent} />
        <ReactTooltip>{content}</ReactTooltip>
    </Box>
  )
}

export default OrgsToolTip;