import React, { useState, useEffect } from "react";
import { Box, Typography, Skeleton, useTheme } from "@mui/material";
import styled from "styled-components";
import { tokens } from "../../theme";
import { Link } from "react-router-dom";
import axios from "axios";
import JsonData from "../../Data/data.Url.json";
import useAuth from "../../hooks/useAuth";

const StyledBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 20px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledImg = styled.img`
  width: 384px;
  height: 216px;
  object-fit: contain;

  @media only screen and (max-width: 480px) {
    width: 349px;
    height: 196.3px;
  }
`;

const StyledLink = styled(Link)`
  color: #fff;
  border-radius: 16px;
  box-shadow: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  padding: 16px;

  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.20), 0 5px 5px rgba(0,0,0,0.18);
  }
`;

const StyledTypography = styled(Typography)``;

const StyledBoxLines = styled(Box)`
  width: 100%;
  height: 12px;
  border-radius: 10px;
`;

const StyledTitleBox = styled(Box)`
  padding: 30px 0;

  @media only screen and (max-width: 480px) {
    padding: 10px 0;
  }
`;

export default function NewsPage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [newsData, setNewsData] = useState();

  const { gameTypeId } = useAuth();

  const FilterByGame = () => {
    if(gameTypeId) {
      if(gameTypeId >= 110 ){
        return "&platform=" + gameTypeId;
    }
      return "&gameType=" + gameTypeId;
    } else {
      return "";
    }
  };

  useEffect(() => {
    let fetchData = async () => {
      let response = await axios.get(
        `${JsonData.ApiUrls.News}/GetLastNews?AmountOfnews=6&${FilterByGame()}`
      );
      setNewsData(response.data);
    };
    fetchData();
  }, [gameTypeId]);

  return (
    <Box>
      <StyledTitleBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        p="30px 0"
      >
        <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginRight="20px"></StyledBoxLines>
        <StyledTypography
          variant="h3"
          sx={{ fontSize: { xs: 14, sm: 24 } }}
          fontWeight="bold"
        >
          News
        </StyledTypography>
        <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginLeft="20px"></StyledBoxLines>
      </StyledTitleBox>
      <StyledBox>
        {newsData && newsData.length !== 0
          ? newsData.map((infos, index) => (
              <StyledLink
                to={`/news/${infos.id}`}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                style={{backgroundColor: colors.greenAccent[800]}}
              >
                <Box
                  maxWidth={439}
                  display="flex"
                  flexDirection="column"
                  gap="10px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <StyledImg
                    src={JsonData.imgurl + infos.imgUrl.replace("\\\\", "\\")}
                    alt="NewsPhoto"
                  />
                  <Typography variant="h5" color={colors.grey[100]}>{infos.title}</Typography>
                  <p style={{color: colors.greenAccent[100]}}>{infos.shortDescription}</p>
                </Box>
              </StyledLink>
            ))
          : [...Array(6)].map((e, i) => (
              <StyledLink key={i}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="10px"
                  justifyContent="center"
                  alignItems="center"
                  padding="0 40px"
                >
                  <Skeleton variant="rounded" width={384} height={216} />
                  <Typography variant="h5">
                    <Skeleton width={384} />
                  </Typography>
                  <p>
                    <Skeleton width={384} />
                  </p>
                </Box>
              </StyledLink>
            ))}
      </StyledBox>
    </Box>
  );
}
