import React from "react";
import { useState } from "react";
import styled from "styled-components";

const StyledInputCont = styled.div`
  input:invalid[focused="true"] ~ span {
    display: block;
  }
`;

function FormInput(props) {
  const [focused, setFocused] = useState(false);
  const handleBlur = () => {
    setFocused(true);
  };

  const { label, errorMessage, onChange, id, ...inputProps } = props;
  return (
    <StyledInputCont>
      <input
        {...inputProps}
        onChange={onChange}
        onBlur={handleBlur}
        focused={focused.toString()}
      />
      <span>{errorMessage}</span>
    </StyledInputCont>
  );
}

export default FormInput;
