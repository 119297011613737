import HomePage from "./Components/HomePage/HomePage";
import { Route, Routes } from "react-router-dom";
import SignupPage from "./Components/SignupPage";
import OrgsToolTip from "./Components/Organizations/OrgsToolTip";
import VideoGallery from "./Components/MediaPage/VideoGallery";
import PhotoGallery from "./Components/MediaPage/PhotoGallery";
import PageLayout from "./Components/PageLayout";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import PasswordRecovery from "./Components/PasswordRecovery";
import Users from "./Dashboard/Users";
import NewsList from "./Dashboard/NewsList";
import DashboardStats from "./Dashboard/DashboardStats";
import NewsPage from "./Components/NewsPage/NewsPage";
import SingleNews from "./Components/NewsPage/SingleNews";
import PageNotFound from "./Components/PageNotFound";
import Layout from "./Components/Layout";
import RequireAuth from "./Components/RequireAuth";
import PersistLogin from "./Components/PersistLogin";
import PhotoList from "./Dashboard/PhotoList";
import VideoList from "./Dashboard/VideoList";
import DashboardPage from "./Dashboard/DashboardPage";
import UserProfile from "./Components/User/UserProfile";
import TeamProfile from "./Components/User/Team/TeamProfile";
import TournamentsPage from "./Components/TournamentsPage/TournamentsPage";
import TournamentLayout from "./Components/TournamentsPage/TournamentLayout";
import TournamentBracket from "./Components/TournamentsPage/TournamentBracket";
import TournamentOverview from "./Components/TournamentsPage/TournamentOverview";
import TournamentTeams from "./Components/TournamentsPage/TournamentTeams";
import TournamentResults from "./Components/TournamentsPage/TournamentResults";
import TournamentRules from "./Components/TournamentsPage/TournamentRules";
import ComingSoon from "./Components/ComingSoon";
import Tournaments from "./Dashboard/Tournaments";
import CreateTournament from "./Components/CreateTournamentPage/CreateTournamentPage";
import UserSettingsLayout from "./Components/User/SettingsLayout";
import SettingsProfile from "./Components/User/SettingsProfile";
import SettingsAccount from "./Components/User/SettingsAccount";
import SettingsPassword from "./Components/User/SettingsPassword";
// import VerifyAccount from "./Components/User/VerifyAccount";
// import SettingsGameAccounts from "./Components/User/SettingsGameAccounts";
import SettingsTeam from "./Components/User/Team/SettingsTeam";
import SettingsOrg from "./Components/User/Organization/SettingsOrg";
import OrganizationProfile from "./Components/User/Organization/OrganizationProfile";
import ShopLayout from "./Components/ShopPage/ShopLayout";
import SingleProduct from "./Components/ShopPage/SingleProduct";
import ShopFeed from "./Components/ShopPage/ShopFeed";
import ShopList from "./Dashboard/ShopItems/ShopList";
import ShopCategories from "./Dashboard/ShopItems/ShopCategories";
import PasswordReset from "./Components/PasswordReset";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <main className="App">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route element={<PageLayout />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/organizations" element={<OrgsToolTip />} />
                <Route element={<ShopLayout />}>
                  <Route path="/shop" element={<ShopFeed />} />
                  <Route path={"/shop/:id"} element={<SingleProduct />} />
                </Route>
                <Route path="/news" element={<NewsPage />} />
                <Route path={"/news/:id"} element={<SingleNews />} />
                <Route path="/media/video" element={<VideoGallery />} />
                <Route path="/media/photo" element={<PhotoGallery />} />
                <Route path="/shop" element={<ComingSoon />} />
                <Route path="/rankings" element={<ComingSoon />} />
                <Route path="*" element={<PageNotFound />} />
                <Route path={"/user/:id"} element={<UserProfile />} />
                <Route element={<UserSettingsLayout />}>
                  <Route
                    path={"/user/:id/settings"}
                    element={<SettingsAccount />}
                  />
                  <Route
                    path={"/user/:id/settings/profile"}
                    element={<SettingsProfile />}
                  />
                  {/* <Route
                    path={"/user/:id/settings/verifyaccount"}
                    element={<VerifyAccount />}
                  /> */}
                  <Route
                    path={"/user/:id/settings/password"}
                    element={<SettingsPassword />}
                  />
                  {/* <Route
                    path={"/user/:id/settings/gameaccounts"}
                    element={<SettingsGameAccounts />}
                  /> */}
                  <Route
                    path={"/user/:id/settings/team"}
                    element={<SettingsTeam />}
                  />
                  <Route
                    path={"/user/:id/settings/organization"}
                    element={<SettingsOrg />}
                  />
                </Route>
                <Route path={"/team/:id"} element={<TeamProfile />} />
                <Route
                  path={"/organization/:id"}
                  element={<OrganizationProfile />}
                />
                <Route path="/tournaments" element={<ComingSoon />} />
                {/* <Route path="/tournaments" element={<TournamentsPage />} /> */}
                {/* <Route element={<TournamentLayout />}>
                  <Route
                    path="/tournaments/id"
                    element={<TournamentOverview />}
                  />
                  <Route
                    path="/tournaments/id/bracket "
                    element={<TournamentBracket />}
                  />
                  <Route
                    path="/tournaments/id/teams"
                    element={<TournamentTeams />}
                  />
                  <Route
                    path="/tournaments/id/results"
                    element={<TournamentResults />}
                  />
                  <Route
                    path="/tournaments/id/rules"
                    element={<TournamentRules />}
                  />
                </Route> */}
              </Route>
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/passwordrecovery" element={<PasswordRecovery />} />
              <Route path="/passwordreset/:token" element={<PasswordReset />} />
              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth allowedRoles={[2, 3, 4]} />}>
                  <Route element={<DashboardPage />}>
                    <Route path="/admin" element={<DashboardStats />} />
                    <Route element={<RequireAuth allowedRoles={[4]} />}>
                      <Route path="/admin/users" element={<Users />} />
                    </Route>
                    <Route path="/admin/news" element={<NewsList />} />
                    <Route path="/admin/video" element={<VideoList />} />
                    <Route path="/admin/photo" element={<PhotoList />} />
                    <Route path="/admin/shop" element={<ShopList />} />
                    <Route
                      path="/admin/shop/categories"
                      element={<ShopCategories />}
                    />
                    <Route
                      path="/admin/tournaments"
                      element={<Tournaments />}
                    />
                    <Route
                      path="/admin/tournaments/create"
                      element={<CreateTournament />}
                    />
                    <Route
                      path={"/admin/tournaments/create/:id"}
                      element={<CreateTournament />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </main>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
