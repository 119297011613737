import React, {useState, useEffect} from 'react';
import Pagination from '@mui/material/Pagination';
import {Box, Skeleton, Typography, useTheme} from '@mui/material';
import styled from 'styled-components';
import { tokens } from '../../theme';
import {Link} from 'react-router-dom';
import axios from "axios";
import JsonData from "../../Data/data.Url.json";

const StyledBox = styled(Box)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 90px;
    padding: 30px 0;

    @media only screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 768px) {
        
    }
    @media only screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 10px 0;
    }
    @media only screen and (max-width: 320px) {
        
    }
`;

const StyledImg = styled.img`
    width: 384px;
    height: 216px;
    object-fit: contain;
`;

const StyledLink = styled(Link)`
    color: #FFF;
`;

const StyledTypography = styled(Typography)`
    text-align: center;
`;

const StyledBoxLines = styled(Box)`
  width: 100%;
  height: 12px;
  border-radius: 10px;
`;

const StyledTitleBox = styled(Box)`
  padding: 30px 0;

  @media only screen and (max-width: 480px) {
    padding: 10px 0;
  }
`;

const StyledP = styled.p`
    max-width: 350px;
`;

export default function TournamentsPage() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let [page, setPage] = useState(1);
  
    let [pageCount, setPageCount] = useState(10);
  
    const handleChange = (e, p) => {
      setPage(p);
    };

    // News Data
    const [newsData, setNewsData] = useState();
    
    useEffect(() => {
        let fetchAllData = async () => {
            let response = await axios.get(`${JsonData.apiurl}/News/GetNews?pageSize=9&pageNumber=${page}`);
            setNewsData(response.data);
            setPageCount(response.headers["page-count"]);
        };
        fetchAllData();
    }, [page]);

    pageCount = Number(pageCount);

    return (
    <Box>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box>
                <StyledTitleBox display="flex" justifyContent="center" alignItems="center" p="30px 0">
                    <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginRight="20px"></StyledBoxLines>
                    <StyledTypography variant="h3" fontWeight="bold">News</StyledTypography>
                    <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginLeft="20px"></StyledBoxLines>
                </StyledTitleBox>
                <StyledBox>
                    {newsData && newsData.length !== 0 ? (
                        newsData.map((infos, index) => (
                            <StyledLink key={index} to={`${infos.id}`} target="_blank" rel="noopener noreferrer">
                                <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center">
                                    <StyledImg src={JsonData.imgurl + infos.imgUrl.replace("\\\\", "\\")} alt="" />
                                    <Typography variant="h5">{infos.title}</Typography>
                                    <StyledP>{infos.shortDescription}</StyledP>
                                </Box>
                            </StyledLink>
                        ))
                    ) : (
                        [...Array(9)].map((e, i) => 
                            <StyledLink key={i}>
                                <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center" padding="0 40px">
                                    <Skeleton variant="rounded" width={384} height={216} />
                                    <Typography variant="h5"><Skeleton width={384} /></Typography>
                                    <p><Skeleton width={384} /></p>
                                </Box>
                            </StyledLink>
                    ))
                    }
                </StyledBox>
                <Box justifyContent={"center"} alignItems="center" display={"flex"} sx={{padding: "20px 0"}}>
                    <Pagination
                        size="large"
                        shape="rounded"
                        variant="outlined"
                        count={pageCount}
                        page={page}
                        onChange={handleChange}
                    />
                </Box>
            </Box>
        </Box>
    </Box>
    );
  }
