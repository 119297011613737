import React, { useState, useEffect } from "react";
import { Box, Avatar } from "@mui/material";
import styled from "styled-components";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import JsonData from "../../../Data/data.Url.json";
import useAuth from "../../../hooks/useAuth";
import { EasyToastify } from "../../../Extentions/EasyToastify";

const StyledMemberBox = styled(Box)`
  position: relative;

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 40px;
    color: #042045;
    background-color: #f44336;
    padding: 3px;
    border-radius: 30px;
    display: flex;
  }
`;

const TeamList = () => {
  const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [minimalTeamData, setMinimalTeamData] = useState();
  const fetchData = async () => {
    const response = await axiosPrivate.get("Organisation/Get/MinimalTeams");
    setMinimalTeamData(response.data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleRemoveTeam = async (id, nickName) => {
    if (
      window.confirm(`Do you want to Remove ${nickName} from Organisation?`)
    ) {
      await axiosPrivate
        .put(`Organisation/Remove/team?id=${id}`)
        .then((res) => {
          EasyToastify.ToastSuccess("Team removed from organisation ✅");
          fetchData();
        })
        .catch((error) => {
          EasyToastify.ToastError("Oop Something wrong ⛔");
          console.log(error);
        });
    }
  };

  return (
    <Box padding="8px">
      <p>Teams In Organisation:</p>
      <Box display="flex" gap="16px" padding="12px 0">
        {minimalTeamData ? (
          minimalTeamData.map((items, index) => (
            <StyledMemberBox
              key={index}
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="4px"
            >
              {items.imgUrl !== null ? (
                <Avatar
                  src={JsonData.imgurl + items.imgUrl.replace("\\\\", "\\")}
                  sx={{ width: 60, height: 60 }}
                />
              ) : (
                <Avatar sx={{ width: 60, height: 60 }} />
              )}
              <p>{items.name}</p>
              {items.name === auth.auth.nickName ? (
                ""
              ) : (
                <span onClick={() => handleRemoveTeam(items.id, items.name)}>
                  <RemoveCircleOutlineIcon sx={{ color: "#fff" }} />
                </span>
              )}
            </StyledMemberBox>
          ))
        ) : (
          <p>No Team</p>
        )}
      </Box>
    </Box>
  );
};

export default TeamList;
