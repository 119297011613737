import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import { Box, Button } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ToastContainer, EasyToastify } from "../Extentions/EasyToastify";
// import { ToastContainer,} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import GameId from "../Components/GameId";

const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
  .video-field {
    height: 30px;
  }
`;

const StyledOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(49, 49, 49, 0.8);
`;

const StyledModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2f475d;
  padding: 24px 28px;
  border-radius: 20px;
  width: 700px;

  @media only screen and (max-width: 480px) {
    width: 380px;
  }
`;

const StyledField = styled(Field)`
  font-family: Montserrat, sans-serif;
`;

const StyledRedBox = styled(Box)`
  color: red;
`;

const StyledImg = styled.img`
  width: 144px;
  height: 81px;
  object-fit: contain;
`;

const validationSchema = yup.object({
  videoUrl: yup
    .string()
    .min(4, "Too small!")
    .max(30, "Too long string")
    .required("Title is required!"),
  date: yup.date().required("Date is required"),
  gameType: yup.number().required("Game Type is required!"),
});

export default function VideoModal(props) {
  const axiosPrivate = useAxiosPrivate();

  // POST METHOD
  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg });
  };
  const handleOnSubmit = async (values, actions) => {
    try {
      let videoUrl = String(values.videoUrl).split("/");
      videoUrl = videoUrl[videoUrl.length - 1];
      await axiosPrivate
        .post(`/NewsMaker/${props.apiUrl}`, {
          videoUrl: videoUrl,
          GameId: Number(selectGameType),
          date: values.date,
        })
        .then((response) => {
          actions.setSubmitting(false);
          actions.resetForm();
          handleServerResponse(true, "");
          EasyToastify.ToastSuccess("Video Added");
          props.toggleModal();
          setSelectGameType();
          props.getVideos();
        });
    } catch (error) {
      actions.setSubmitting(false);
      handleServerResponse(false, error.response.data.error);
      EasyToastify.ToastError("Error");
    }
  };

  const [videos] = useState(
    props.videos
      ? props.videos
      : {
        videoUrl: "",
        gameType: 1,
        date: "",
      }
  );

  const [selectGameType, setSelectGameType] = useState();
  const handleSelectGameTypeChange = (e) => {
    setSelectGameType(e.target.value);
  };

  return (
    <Box>
      {props.modal && (
        <StyledModal>
          <StyledOverlay onClick={props.toggleModal}></StyledOverlay>
          <StyledModalContent>
            <Formik
              // validateOnMount={true}
              initialValues={videos}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Box display="flex" flexDirection="column">
                    <label>Video URL: </label>
                    <Field
                      className="video-field"
                      name="videoUrl"
                      type="text"
                      placeholder={`Enter Video URL...`}
                    />
                    <StyledRedBox>
                      <ErrorMessage name="videoUrl" />
                    </StyledRedBox>
                  </Box>
                  {values.videoUrl ? (
                    <StyledImg
                      src={
                        "https://img.youtube.com/vi/" +
                        values.videoUrl.split("/").pop() +
                        "/sddefault.jpg"
                      }
                      alt={values.videoUrl}
                    />
                  ) : (
                    ""
                  )}
                  <Box display="flex" flexDirection="column">
                    <label>Game:</label>
                    <StyledField
                      className="video-field"
                      name="gameType"
                      as="select"
                      value={selectGameType}
                      onChange={handleSelectGameTypeChange}
                      defaultValue={"DEFAULT"}
                    >
                      <option value="DEFAULT" disabled>
                        Click to Select Game
                      </option>
                      <GameId />
                    </StyledField>
                    <StyledRedBox>
                      <ErrorMessage name="gameType" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <label>Date:</label>
                    <Field className="video-field" name="date" type="date" />
                    <StyledRedBox>
                      <ErrorMessage name="date" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column" paddingTop="8px">
                    <Button variant="contained" type="submit">
                      Submit
                    </Button>
                  </Box>
                  {serverState && (
                    <p className={!serverState.ok ? "errorMsg" : ""}>
                      {serverState.msg}
                    </p>
                  )}
                </Form>
              )}
            </Formik>
            {/*  */}
            <Button
              variant="text"
              sx={{
                color: "red",
                position: "absolute",
                top: "3px",
                right: "5px",
              }}
              onClick={props.toggleModal}
            >
              <CancelOutlinedIcon />
            </Button>
          </StyledModalContent>
        </StyledModal>
      )}
      <ToastContainer />
    </Box>
  );
}
