import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [persist, setPersist] = useState(
    JSON.parse(localStorage.getItem("persist")) || false
  );
  const [auth, setAuth] = useState(
    persist
      ? JSON.parse(localStorage.getItem("user")) || {}
      : JSON.parse(sessionStorage.getItem("user")) || {}
  );
  const [gameTypeId, setGameTypeId] = useState(localStorage?.getItem("gameTypeId"));
  return (
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist, gameTypeId, setGameTypeId }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
