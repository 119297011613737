import { Box, Typography, useTheme, Link } from "@mui/material";
import styled from "styled-components";
import { tokens } from "../../theme";
import WinterCupCs from '../../Assets/Images/HomeTournament/winterCupCs.jpg';
import WinterCupDota from '../../Assets/Images/HomeTournament/winterCupDota.jpg';
import WinterCupPubgm from '../../Assets/Images/HomeTournament/winterCupPubgm.jpg';
import WinterCupEfoot from '../../Assets/Images/HomeTournament/winterCupEfoot.jpg';
import WinterCupFifa from '../../Assets/Images/HomeTournament/winterCupFifa.jpg';
import WinterCupNba from '../../Assets/Images/HomeTournament/winterCupNba.jpg';

const StyledBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 20px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledImg = styled.img`
  width: 384px;
  height: 216px;
  object-fit: contain;

  @media only screen and (max-width: 480px) {
    width: 349px;
    height: 196.3px;
  }
`;

const StyledLink = styled(Link)`
  color: #fff;
  border-radius: 16px;
  box-shadow: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  padding: 16px;

  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.20), 0 5px 5px rgba(0,0,0,0.18);
  }
`;

const StyledTypography = styled(Typography)``;

const StyledBoxLines = styled(Box)`
  width: 100%;
  height: 12px;
  border-radius: 10px;
`;

const StyledTitleBox = styled(Box)`
  padding: 30px 0;

  @media only screen and (max-width: 480px) {
    padding: 10px 0;
  }
`;

export default function TournamentFeed() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box paddingBottom="36px">
      <StyledTitleBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        p="30px 0"
      >
        <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginRight="20px"></StyledBoxLines>
        <StyledTypography
          variant="h3"
          sx={{ fontSize: { xs: 14, sm: 24 } }}
          fontWeight="bold"
        >
          Tournaments
        </StyledTypography>
        <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginLeft="20px"></StyledBoxLines>
      </StyledTitleBox>
      <StyledBox>
        <StyledLink
          href="https://play.toornament.com/en_US/tournaments/6184800181963661312/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ backgroundColor: colors.greenAccent[800] }}
        >
          <Box
            maxWidth={439}
            display="flex"
            flexDirection="column"
            gap="10px"
            justifyContent="center"
            alignItems="center"
          >
            <StyledImg src={WinterCupCs} alt="TournamentPhoto" />
            <Typography variant="h5" color={colors.grey[100]}>
              შესარჩევი CS:GO-ში | Europebet Winter Cup
            </Typography>
            <p style={{ color: colors.greenAccent[100] }}>
              Europebet Winter Cup - კიბერსპორტის ყველაზე მასშტაბური ტურნირი
              ბრუნდება! 👏 ტურნირის საპრიზო ფონდია 100 000!
            </p>
          </Box>
        </StyledLink>
        <StyledLink
          href="https://play.toornament.com/en_US/tournaments/6159261962233708544/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ backgroundColor: colors.greenAccent[800] }}
        >
          <Box
            maxWidth={439}
            display="flex"
            flexDirection="column"
            gap="10px"
            justifyContent="center"
            alignItems="center"
          >
            <StyledImg src={WinterCupFifa} alt="TournamentPhoto" />
            <Typography variant="h5" color={colors.grey[100]}>
              შესარჩევი FIFA-ში | Europebet Winter Cup
            </Typography>
            <p style={{ color: colors.greenAccent[100] }}>
              Europebet Winter Cup - კიბერსპორტის ყველაზე მასშტაბური ტურნირი
              ბრუნდება! 👏 ტურნირის საპრიზო ფონდია 100 000!
            </p>
          </Box>
        </StyledLink>
        <StyledLink
          href="https://discord.gg/rcRR95AsYd"
          target="_blank"
          rel="noopener noreferrer"
          style={{ backgroundColor: colors.greenAccent[800] }}
        >
          <Box
            maxWidth={439}
            display="flex"
            flexDirection="column"
            gap="10px"
            justifyContent="center"
            alignItems="center"
          >
            <StyledImg src={WinterCupPubgm} alt="TournamentPhoto" />
            <Typography variant="h5" color={colors.grey[100]}>
              შესარჩევი PUBG Mobile-ში | europebet Winter Cup
            </Typography>
            <p style={{ color: colors.greenAccent[100] }}>
              Europebet Winter Cup - კიბერსპორტის ყველაზე მასშტაბური ტურნირი
              ბრუნდება! 👏 ტურნირის საპრიზო ფონდია 100 000!
            </p>
          </Box>
        </StyledLink>
        <StyledLink
          href="https://play.toornament.com/en_US/tournaments/6153495703672987648/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ backgroundColor: colors.greenAccent[800] }}
        >
          <Box
            maxWidth={439}
            display="flex"
            flexDirection="column"
            gap="10px"
            justifyContent="center"
            alignItems="center"
          >
            <StyledImg src={WinterCupDota} alt="TournamentPhoto" />
            <Typography variant="h5" color={colors.grey[100]}>
              შესარჩევი Dota 2-ში | Europebet Winter Cup
            </Typography>
            <p style={{ color: colors.greenAccent[100] }}>
              Europebet Winter Cup - კიბერსპორტის ყველაზე მასშტაბური ტურნირი
              ბრუნდება! 👏 ტურნირის საპრიზო ფონდია 100 000!
            </p>
          </Box>
        </StyledLink>
        <StyledLink
          href="https://play.toornament.com/en_US/tournaments/6159263175949557760/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ backgroundColor: colors.greenAccent[800] }}
        >
          <Box
            maxWidth={439}
            display="flex"
            flexDirection="column"
            gap="10px"
            justifyContent="center"
            alignItems="center"
          >
            <StyledImg src={WinterCupEfoot} alt="TournamentPhoto" />
            <Typography variant="h5" color={colors.grey[100]}>
              შესარჩევი eFootball-ში | Europebet Winter Cup
            </Typography>
            <p style={{ color: colors.greenAccent[100] }}>
              Europebet Winter Cup - კიბერსპორტის ყველაზე მასშტაბური ტურნირი
              ბრუნდება! 👏 ტურნირის საპრიზო ფონდია 100 000!
            </p>
          </Box>
        </StyledLink>
        <StyledLink
          href="https://play.toornament.com/en_US/tournaments/6159260060248801280/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ backgroundColor: colors.greenAccent[800] }}
        >
          <Box
            maxWidth={439}
            display="flex"
            flexDirection="column"
            gap="10px"
            justifyContent="center"
            alignItems="center"
          >
            <StyledImg src={WinterCupNba} alt="TournamentPhoto" />
            <Typography variant="h5" color={colors.grey[100]}>
              შესარჩევი NBA-ში | Europebet Winter Cup
            </Typography>
            <p style={{ color: colors.greenAccent[100] }}>
              Europebet Winter Cup - კიბერსპორტის ყველაზე მასშტაბური ტურნირი
              ბრუნდება! 👏 ტურნირის საპრიზო ფონდია 100 000!
            </p>
          </Box>
        </StyledLink>
      </StyledBox>
      {/* <StyledBox>
        {[...Array(6)].map((e, i) => (
          <StyledLink
            key={i}
            href="https://play.toornament.com/en_US/tournaments/6159260060248801280/stages/6159358626142576640/groups/6159358626981437457/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ backgroundColor: colors.greenAccent[800] }}
          >
            <Box
              maxWidth={439}
              display="flex"
              flexDirection="column"
              gap="10px"
              justifyContent="center"
              alignItems="center"
            >
              <StyledImg src={testTournamentImg} alt="TournamentPhoto" />
              <Typography variant="h5" color={colors.grey[100]}>
                შესარჩევი NBA-ში | europebet Winter Cup
              </Typography>
              <p style={{ color: colors.greenAccent[100] }}>
                europebet Winter Cup - კიბერსპორტის ყველაზე მასშტაბური ტურნირი
                ბრუნდება! 👏 ტურნირის საპრიზო ფონდია 100 000!
              </p>
            </Box>
          </StyledLink>
        ))}
      </StyledBox> */}
    </Box>
  );
};
