import React, { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import { Box, Button } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ToastContainer } from "react-toastify";
import PreviewImage from "../../Dashboard/PreviewImage";
// import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";
import { EasyToastify } from "../../Extentions/EasyToastify";
import { padding } from "@mui/system";

const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
`;

const StyledOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(49, 49, 49, 0.8);
`;

const StyledModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2f475d;
  padding: 24px 28px;
  border-radius: 20px;
  width: 700px;

  @media only screen and (max-width: 480px) {
    width: 380px;
  }
`;

const StyledRedBox = styled(Box)`
  color: red;
`;

const StyledBoxFocused = styled(Box)`
  input:invalid[focused="true"] ~ span {
    display: block;
  }
`;

const StyledField = styled(Field)``;
const validationSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  nickName: yup.string().required(),
  BirthDate: yup.date().required("Date is required"),
  phoneNumber: yup.number().required(),
  userRole: yup.number().required(),
  //   password: yup.string().required(),
  //   confirmPassword: yup.string().required(),
});

const MatchesModal = (props) => {
  const fileRef = useRef(null);

  const [addUserData] = useState(
    props.user
      ? props.user
      : {
          firstname: "",
          lastname: "",
          nickName: "",
          email: "",
          birthDate: "",
          phonenumber: "",
          roleId: "",
          password: "",
          confirmPassword: "",
          verified: "",
        }
  );

  const handleOnSubmit = async (values, actions) => {};

  return (
    <Box>
      {props.modal && (
        <StyledModal>
          <StyledOverlay onClick={props.toggleModal}></StyledOverlay>
          <StyledModalContent>
            {/*  */}
            <Formik
              // validateOnMount={true}
              initialValues={{}}
              onSubmit={handleOnSubmit}
              // validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <Form
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <label>Home Team Score:</label>
                  <input type="number" name="OrginiserName" />
                  <label>Away Team Score:</label>
                  <input type="number" name="OrginiserName" />

                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      ref={fileRef}
                      hidden
                      type="file"
                      name="file"
                      onChange={(event) => {
                        setFieldValue("file", event.target.files[0]);
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {
                        fileRef.current.click();
                      }}
                      sx={{
                        color: "white",
                        backgroundColor: "darkBlue",
                        display: "flex",
                        height: "35px",
                      }}
                    >
                      Upload Photo
                    </Button>
                    {values.file && (
                      <PreviewImage
                        file={values.file}
                        width="240px"
                        height="135px"
                        border="10px"
                      />
                    )}
                  </div> */}
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "green" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
            <Button
              variant="text"
              sx={{
                color: "red",
                position: "absolute",
                top: "3px",
                right: "5px",
              }}
              onClick={props.toggleModal}
            >
              <CancelOutlinedIcon />
            </Button>
          </StyledModalContent>
        </StyledModal>
      )}
      <ToastContainer />
    </Box>
  );
};

export default MatchesModal;
