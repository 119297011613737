// import React, { useState, useEffect, useRef } from 'react';
import { Box, useTheme } from '@mui/material';
import styled from 'styled-components';
import { tokens } from "../../theme";
import SearchIcon from '@mui/icons-material/Search';
// import axios from 'axios';
// import JsonData from '../../Data/data.Url.json';
// import { Link } from 'react-router-dom';

const SearchBarWrap = styled(Box)`
  margin: 25px 0;
  width: 280px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, .26);
  padding: .7rem;
  border-radius: 14px;

  input {
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
    outline: none;
    border: none;
    width: 100%;
    padding: .2rem .5rem;
    
    ::placeholder {
      color: rgba(0, 0, 0, .26);
    }
  }
`;

const Search = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // const inputRef = useRef()

  // const [productList, setProductList] = useState("");
  // const [query, setQuery] = useState("");
  // const [renderProducts, setRenderProducts] = useState(false);

  // useEffect(() => {
  //   if (renderProducts && query !== "") {
  //     const getSearchedProducts = async () => {
  //       const response = await axios.get(`Shop/Products/${query}`);
  //       setProductList(response.data);
  //     };
  //     const timeOutId = setTimeout(() => getSearchedProducts(), 1500);
  //     return () => clearTimeout(timeOutId);
  //   }
  // }, [query]);

  // const handleSearchInput = () => {
  //   setQuery(inputRef.current.value);
  // }

  return (
    <Box>
      <SearchBarWrap
        backgroundColor={colors.greenAccent[800]}
        // onKeyPress={(e) => {
        //   if (e.key === 'Enter') {
        //     setQuery();
        //   }
        // }}
      >
        <input
          type="text"
          // value={query}
          onChange={(event) => {
            props.setSearchState(event.target.value);
            // setQuery(event.target.value);
            // setRenderProducts(true);
          }}
          style={{ borderRadius: "6px" }}
        />
        <SearchIcon fontSize="large" />
        {/* <SearchIcon fontSize="large" onClick={handleSearchInput} /> */}
      </SearchBarWrap>
      {/* {productList
        ? productList.map((items, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            padding="4px 16px"
          >
            <Box display="flex" alignItems="center" gap="12px">
              {items.profileImgUrl ? (
                <img
                  src={
                    JsonData.imgurl +
                    items.profileImgUrl.replace("\\\\", "\\")
                  }
                  alt=""
                />
              ) : (
                <img />
              )}
              <p>{items.nickName}</p>
            </Box>
            <Link
              path="/"
            >
              Click
            </Link>
          </Box>
        ))
        : ""} */}
    </Box>
  )
}

export default Search;
