import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import { Box, Button } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ToastContainer } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import { EasyToastify } from "../Extentions/EasyToastify";
import Moment from "moment";

const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
  .users-field,
  select {
    height: 30px;
  }
`;

const StyledOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(49, 49, 49, 0.8);
`;

const StyledModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2f475d;
  padding: 24px 28px;
  border-radius: 20px;
  width: 700px;

  @media only screen and (max-width: 480px) {
    width: 380px;
  }
`;

const StyledRedBox = styled(Box)`
  color: red;
`;

const StyledBoxFocused = styled(Box)`
  input:invalid[focused="true"] ~ span {
    display: block;
  }
`;

const StyledField = styled(Field)``;

const UsersModal = (props) => {
  const passwordValidation = yup
    .string()
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
      "Password shoould be 8-20 characters and include Letter, Number and special character (@, !, $, etc.)"
    );
  const confirmPasswordValidation = yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords Should match");

  const validationSchema = yup.object({
    firstname: yup
      .string()
      .matches(
        /^[A-Za-z]{3,50}$/,
        "firstname Should be only letters and more then 3 characters"
      )
      .required(),
    lastname: yup
      .string()
      .matches(
        /^[A-Za-z]{3,50}$/,
        "lastname Should be only letters and more then 3 characters"
      )
      .required(),
    nickName: yup
      .string()
      .matches(
        /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()?]{3,16}$/,
        "nickName should be 3-16 characters and Latin alphabet"
      )
      .required(),
    email: yup.string().email().required(),
    phonenumber: yup
      .string()
      .matches(/^[0-9-+]{1,30}$/, "Only Numbers, + and - are allowed")
      .required(),
    // roleId: yup
    //   .number()
    //   .required("Role is required"),
    birthdate: yup.date().required("Date is required"),
    confirmpassword: !props.user
      ? confirmPasswordValidation.required()
      : confirmPasswordValidation,
    password: !props.user ? passwordValidation.required() : passwordValidation,
  });

  const [serverState, setServerState] = useState();
  const [verified, setVerified] = useState(false);

  const [role, setRole] = useState(props.user ? props.user.roleId : 0);
  const [addUserData] = useState(
    props.user
      ? {
          ...props.user,
          birthdate: Moment(props.user.birthdate).format("YYYY-MM-DD"),
        }
      : {
          firstname: "",
          lastname: "",
          nickName: "",
          email: "",
          birthdate: "",
          phonenumber: "",
          roleId: "",
          password: "",
          confirmpassword: "",
          verified: "",
        }
  );

  const handleOnSubmit = async (values, actions) => {
    let user = {
      firstname: values.firstname,
      lastname: values.lastname,
      nickName: values.nickName,
      email: values.email,
      birthdate: values.birthdate,
      phonenumber: values.phonenumber,
      password: values.password ? values.password : "",
      roleId: Number(role),
      verified: verified,
    };

    if (props.user) {
      user.Id = props.user.id;
    }
    // console.log(user);
    await props
      .method(`Admin/${props.apiUrl}`, user)
      .then((res) => {
        EasyToastify.ToastSuccess(props.user ? "User Updated" : "User Created");
        props.toggleModal();
        actions.resetForm();
      })
      .catch((err) => {
        EasyToastify.ToastError(err.message);
      });
  };

  const handleVerified = (e) => {
    setVerified(!verified);
  };

  const handleRoleChange = (e) => {
    setRole(Number(e.target.value));
    // console.log(e.target.value);
  };

  return (
    <Box>
      {props.modal && (
        <StyledModal>
          <StyledOverlay onClick={props.toggleModal}></StyledOverlay>
          <StyledModalContent>
            {/*  */}
            <Formik
              // validateOnMount={true}
              initialValues={addUserData}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
            >
              <Form>
                <Box display="flex" gap="20px">
                  <Box display="flex" flexDirection="column" width="100%">
                    <label>User First-Name: </label>
                    <Field
                      className="users-field"
                      required={true}
                      variant="outlined"
                      name="firstname"
                      margin="dense"
                      type="text"
                      size="small"
                      sx={{
                        border: "black",
                        "& .MuiInputBase-input": {
                          fontSize: "15px",
                          //   height: "10px",
                          //   color: "black",
                          color: "white",
                          borderRadius: "5px",
                          borderColor: "white",
                          borderBlockColor: "white",
                          border: "5px",
                        },
                      }}
                      placeholder={`Enter First Name...`}
                    />

                    <StyledRedBox>
                      <ErrorMessage name="firstname" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column" width="100%">
                    <label>User Last-Name: </label>
                    <Field
                      className="users-field"
                      required={true}
                      variant="outlined"
                      name="lastname"
                      margin="dense"
                      type="text"
                      size="small"
                      sx={{
                        border: "black",
                        "& .MuiInputBase-input": {
                          fontSize: "15px",
                          //   height: "10px",
                          //   color: "black",
                          color: "white",
                          borderRadius: "5px",
                          borderColor: "white",
                          borderBlockColor: "white",
                          border: "5px",
                        },
                      }}
                      placeholder={`Enter Last Name...`}
                    />

                    <StyledRedBox>
                      <ErrorMessage name="lastname" />
                    </StyledRedBox>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column">
                  <label>User nickName: </label>
                  <Field
                    className="users-field"
                    required={true}
                    variant="outlined"
                    name="nickName"
                    margin="dense"
                    type="text"
                    size="small"
                    sx={{
                      border: "black",
                      "& .MuiInputBase-input": {
                        fontSize: "15px",
                        color: "white",
                        borderRadius: "5px",
                        borderColor: "white",
                        borderBlockColor: "white",
                        border: "5px",
                      },
                    }}
                    placeholder={`Enter nickName...`}
                  />

                  <StyledRedBox>
                    <ErrorMessage name="nickName" />
                  </StyledRedBox>
                </Box>
                <Box display="flex" flexDirection="column">
                  <label>User Email: </label>
                  <Field
                    className="users-field"
                    variant="outlined"
                    name="email"
                    margin="dense"
                    type="email"
                    size="small"
                    sx={{
                      border: "black",
                      "& .MuiInputBase-input": {
                        fontSize: "15px",
                        color: "white",
                        borderRadius: "5px",
                        borderColor: "white",
                        borderBlockColor: "white",
                        border: "5px",
                      },
                    }}
                    placeholder={`Enter Email...`}
                  />
                  <StyledRedBox>
                    <ErrorMessage name="email" />
                  </StyledRedBox>
                </Box>
                <Box display="flex" flexDirection="column">
                  <label>User Phone Number: </label>
                  <Field
                    className="users-field"
                    variant="outlined"
                    name="phonenumber"
                    margin="dense"
                    type="tel"
                    size="small"
                    sx={{
                      border: "black",
                      "& .MuiInputBase-input": {
                        fontSize: "15px",
                        color: "white",
                        borderRadius: "5px",
                        borderColor: "white",
                        borderBlockColor: "white",
                        border: "5px",
                      },
                    }}
                    placeholder={`Enter Numbers...`}
                  />
                  <StyledRedBox>
                    <ErrorMessage name="phonenumber" />
                  </StyledRedBox>
                </Box>
                <Box display="flex" flexDirection="column">
                  <label>User Role:</label>
                  <select
                    name="roleId"
                    as="select"
                    required={true}
                    onChange={handleRoleChange}
                    defaultValue={addUserData.roleId ? addUserData.roleId : 1}
                  >
                    {/* <option value="DEFAULT" disabled>Click to Select Role</option> */}
                    <option value={1}>User</option>
                    <option value={2}>News Maker</option>
                    <option value={3}>Editor</option>
                    <option value={4}>Admin</option>
                  </select>
                  <StyledRedBox>
                    <ErrorMessage name="roleId" />
                  </StyledRedBox>
                </Box>
                <Box display="flex" flexDirection="column">
                  <label>Birth Date:</label>
                  <Field
                    className="users-field"
                    size="small"
                    name="birthdate"
                    type="date"
                    margin="dense"
                  />
                  <StyledRedBox>
                    <ErrorMessage name="birthdate" />
                  </StyledRedBox>
                </Box>
                <Box display="flex" gap="20px">
                  <StyledBoxFocused
                    display="flex"
                    flexDirection="column"
                    width="100%"
                  >
                    <label>User Password: </label>
                    <Field
                      className="users-field"
                      variant="outlined"
                      name="password"
                      margin="dense"
                      type="password"
                      size="small"
                      sx={{
                        border: "black",
                        "& .MuiInputBase-input": {
                          fontSize: "15px",
                          color: "white",
                          borderRadius: "5px",
                          borderColor: "white",
                          borderBlockColor: "white",
                          border: "5px",
                        },
                      }}
                      placeholder={`Enter Password...`}
                    />
                    <StyledRedBox>
                      <ErrorMessage name="password" />
                    </StyledRedBox>
                  </StyledBoxFocused>
                  <Box display="flex" flexDirection="column" width="100%">
                    <label>Confirm User Password: </label>
                    <Field
                      className="users-field"
                      variant="outlined"
                      name="confirmpassword"
                      margin="dense"
                      type="password"
                      size="small"
                      sx={{
                        border: "black",
                        "& .MuiInputBase-input": {
                          fontSize: "15px",
                          color: "white",
                          borderRadius: "5px",
                          borderColor: "white",
                          borderBlockColor: "white",
                          border: "5px",
                        },
                      }}
                      placeholder={`Repeat Password...`}
                    />
                    <StyledRedBox>
                      <ErrorMessage name="confirmpassword" />
                    </StyledRedBox>
                  </Box>
                </Box>
                <Box>
                  <Checkbox
                    name="verified"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        color: "#fff",
                        alignItems: "flex-start",
                      },
                    }}
                    value={verified}
                    onChange={handleVerified}
                    disableRipple
                  />
                  Verified user?
                </Box>
                <Box display="flex" flexDirection="column" paddingTop="8px">
                  <Button variant="contained" type="submit">
                    {props.user ? "Save Changes" : "Save User"}
                  </Button>
                </Box>
                {serverState && (
                  <p className={!serverState.ok ? "errorMsg" : ""}>
                    {serverState.msg}
                  </p>
                )}
              </Form>
            </Formik>
            <Button
              variant="text"
              sx={{
                color: "red",
                position: "absolute",
                top: "3px",
                right: "5px",
              }}
              onClick={props.toggleModal}
            >
              <CancelOutlinedIcon />
            </Button>
          </StyledModalContent>
        </StyledModal>
      )}
      <ToastContainer />
    </Box>
  );
};

export default UsersModal;
