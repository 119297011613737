import {toast, Flip,ToastContainer } from "react-toastify";

class EasyToastify{
    static position = "bottom-right";
    static zIndex = 20;
    static autoClose = 2000;
    static hideProgressBar = false;
    static closeOnClick = true;
    static pauseOnHover = true;
    static draggable = true;
    static progress = undefined;
    static transition = Flip;
    static theme = "dark";
    static ToastSuccess(msg){
        toast.success(msg, this);
    }
    static ToastError(msg){
        toast.error(msg, this);
    }
}

export {EasyToastify, ToastContainer};