import React, { useState, useEffect } from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { EasyToastify, ToastContainer } from "../../../Extentions/EasyToastify";
import { tokens } from "../../../theme";

const SettingsOrgInvitation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const axiosPrivate = useAxiosPrivate();
  const [orgInvite, setOrgInvite] = useState();

  const getOrgInvite = async () => {
    const response = await axiosPrivate.get("Organisation/Invite/team");
    setOrgInvite(response.data);
  };

  const handleTeamAccept = async (id) => {
    if (orgInvite) {
      await axiosPrivate
        .post(`Organisation/Invite?id=${id}`, {})
        .then((res) => {
          EasyToastify.ToastSuccess("Team Invitation Accepted");
          getOrgInvite();
        })
        .catch((error) => {
          EasyToastify.ToastError("Oops, Something is wrong");
        });
    }
  };

  const handleTeamDelete = async (id) => {
    if (orgInvite) {
      await axiosPrivate
        .delete(`Organisation/Invite?id=${id}`, {})
        .then((res) => {
          EasyToastify.ToastSuccess("Invitation Rejected");
          getOrgInvite();
        })
        .catch((error) => {
          EasyToastify.ToastError("Oops Something wrong");
        });
    }
  };

  useEffect(() => {
    getOrgInvite();
  }, [setOrgInvite]);

  if (orgInvite) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap="12px"
        padding="16px"
        borderRadius="16px"
        style={{ backgroundColor: colors.greenAccent[800] }}
      >
        <Typography variant="h5">Organisation Invitation</Typography>
        {orgInvite && orgInvite.length !== 0 ? (
          orgInvite.map((items, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="4px 12px"
              gap="8px"
            >
              <p style={{ fontSize: "16px" }}>{items.content}</p>
              <Box display="flex" gap="8px">
                <Button
                  onClick={() => handleTeamAccept(items.id)}
                  variant="contained"
                  color="success"
                >
                  <DoneIcon />
                </Button>
                <Button
                  onClick={() => handleTeamDelete(items.id)}
                  variant="contained"
                  color="error"
                >
                  <ClearIcon />
                </Button>
              </Box>
            </Box>
          ))
        ) : (
          <p>There are no invites</p>
        )}
        <ToastContainer />
      </Box>
    );
  }
};

export default SettingsOrgInvitation;
