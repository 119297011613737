import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Avatar, useTheme, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import styled from "styled-components";
import {
  BsTwitch,
  BsYoutube,
  BsTwitter,
  BsFacebook,
  BsInstagram,
} from "react-icons/bs";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAxiosPrivateForm from "../../hooks/useAxiosPrivateForm";
import JsonData from "../../Data/data.Url.json";
import { tokens } from "../../theme";
import { EasyToastify } from "../../Extentions/EasyToastify";
import { FlagIcon } from "react-flag-kit";
import CountryData from '../../Data/countries.json';

const StyledBox = styled(Box)`
  border-radius: 16px;
  padding: 16px;

  form {
    width: 540px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    @media only screen and (max-width: 480px) {
      padding: 16px;
      width: 100%;
    }
  }

  label {
    color: #b7b7b7;
    font-size: 16px;
  }

  input {
    padding: 8px;
    border-radius: 8px;
    border: none;
    outline: 0;
    width: 100%;
  }

  .menu-flags ul, .menu-flags div, .menu-flags input, .menu-flags-button {
    background-color: #194468;

    /* &:after {
      border-top: solid #fff;
    } */
  }

  .menu-flags-button {
    color: #fff;
  }
`;

const StyledRedBox = styled(Box)`
  color: red;
`;

const StyledProfileEditBox = styled(Box)`
  position: relative;

  input[type="file"] {
    display: none;
  }

  label {
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 90px;
    color: #042045;
    background-color: #fff;
    padding: 7px;
    border-radius: 30px;
    display: flex;
  }
`;

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const validationSchema = yup.object({
  about: yup
    .string()
    .nullable()
    .min(5, "Too small!")
    .max(60, "Too long!"),
  country: yup
    .string()
    .nullable()
    .min(1, "Too small!")
    .max(30, "Too long!"),
  facebookUrl: yup
    .string()
    .nullable()
    .min(10, "Too small!")
    .max(40, "Too long!"),
  instagramUrl: yup
    .string()
    .nullable()
    .min(10, "Too small!")
    .max(40, "Too long!"),
  twitterUrl: yup
    .string()
    .nullable()
    .min(10, "Too small!")
    .max(40, "Too long!"),
  youtubeUrl: yup
    .string()
    .nullable()
    .min(10, "Too small!")
    .max(40, "Too long!"),
  twitchUrl: yup
    .string()
    .nullable()
    .min(10, "Too small!")
    .max(40, "Too long!"),
  file: yup
    .mixed()
    .nullable()
    .test(
      "FILE_SIZE",
      "Upload file is too big.",
      (value) => !value || (value && value.size <= 2048 * 2048)
    )
    .test(
      "FILE_FORMAT",
      "Upload file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
    ),
});

const SettingsProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const axiosPrivate = useAxiosPrivate();
  const axiosPrivateForm = useAxiosPrivateForm();
  // Profile picture upload
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  //
  const [userData, setUserData] = useState({
    profileImgUrl: null,
    about: "",
    country: "",
    facebookUrl: "",
    instagramUrl: "",
    twitterUrl: "",
    youtubeUrl: "",
    twitchUrl: "",
  });

  // const [selectedCountry, setSelectedCountry] = useState("");

  const getUserProfile = async () => {
    await axiosPrivate.get("User/Get/UserProfile")
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => {
        EasyToastify.ToastError(err.message);
      })
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const handleSubmit = async (values, actions) => {
    const formData = new FormData();
    formData.append("profileImage", selectedFile ? selectedFile : null);
    formData.append("about", values.about);
    formData.append("country", selectCountry);
    formData.append("facebookUrl", values.facebookUrl);
    formData.append("instagramUrl", values.instagramUrl);
    formData.append("twitterUrl", values.twitterUrl);
    formData.append("youtubeUrl", values.youtubeUrl);
    formData.append("twitchUrl", values.twitchUrl);

    await axiosPrivateForm
      .put("User/Update/Profile", formData)
      .catch((error) => {
        EasyToastify.ToastError(error.message);
      })
      .then((res) => {
        EasyToastify.ToastSuccess("Profile details updated");
        actions.resetForm();
        getUserProfile();
      });
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const [selectCountry, setSelectCountry] = useState();
  const handleSelectCountry = (e) => {
    setSelectCountry(e.target.value);
  };

  return (
    <StyledBox style={{ backgroundColor: colors.greenAccent[800] }}>
      <Typography variant="h2">Profile</Typography>
      <Formik
        enableReinitialize={true}
        initialValues={userData}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <Box display="flex" flexDirection="column" gap="8px">
            <label>Profile Picture</label>
            <StyledProfileEditBox>
              {userData && userData.length !== 0 ? (
                preview ? (
                  selectedFile && (
                    <Avatar
                      src={preview}
                      alt="P"
                      sx={{ width: 120, height: 120 }}
                    />
                  )
                ) : userData.profileImgUrl ? (
                  <Avatar
                    src={JsonData.imgurl + userData.profileImgUrl.replace("\\\\", "\\")}
                    alt="P"
                    sx={{ width: 120, height: 120 }}
                  />
                ) : (
                  <Avatar alt="P" sx={{ width: 120, height: 120 }} />
                )
              ) : (
                <Avatar alt="P" sx={{ width: 120, height: 120 }} />
              )}
              <label htmlFor="file-upload">
                <AddAPhotoIcon />
              </label>
              <Field
                id="file-upload"
                type="file"
                name="file"
                onChange={onSelectFile}
              />
              <StyledRedBox>
                <ErrorMessage name="file" />
              </StyledRedBox>
            </StyledProfileEditBox>
            <p>Recommend image ratio is 1:1</p>
          </Box>
          <Box display="flex" flexDirection="column">
            <label>Bio &#40;not required&#41;</label>
            {userData && userData.length !== 0 ? (
              <>
                <Field
                  type="text"
                  name="about"
                  placeholder="aq unda iyos biogragia"
                  style={{ color: colors.textColor[100], backgroundColor: colors.greenAccent[600] }}
                />
                <StyledRedBox>
                  <ErrorMessage name="about" />
                </StyledRedBox>
              </>
            ) : (
              ""
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            {userData && userData.length !== 0 ? (
              <>
                {/* <Box display="flex" alignItems="center" gap="8px" paddingBottom="6px">
                  <label>Nationality:</label>
                  {userData.country ? (
                    <FlagIcon code={userData.country} width="6%" height="6%" style={{ borderRadius: "2px" }} />
                  ) : ""}
                </Box> */}
                {/* <Field
                  name="country"
                  as="select"
                  value={selectCountry}
                  onChange={handleSelectCountry}
                  defaultValue={"DEFAULT"}
                >
                  <option value="DEFAULT" disabled>
                    Click to Select Game
                  </option>
                  {CountryData.map((items, index) => (
                    <option key={index} value={items.code}>
                      {items.name}
                    </option>
                  ))}
                </Field> */}
                <FormControl fullWidth sx={{ margin: "12px 0", backgroundColor: colors.greenAccent[600], borderRadius: "14px" }}>
                  <InputLabel id="demo-simple-select-label" sx={{ color: "inherit", "&.Mui-focused": { color: "inherit" } }}>Nationality</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectCountry}
                    // defaultValue={userData.country && userData.country !== "" ? userData.country : selectCountry}
                    label="Nationality"
                    onChange={handleSelectCountry}
                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                    MenuProps={{ style: { maxHeight: 320 }, MenuListProps: { sx: { backgroundColor: colors.greenAccent[600] } } }}
                  >
                    {/* <MenuItem value="">All</MenuItem> */}
                    {CountryData.map((items, index) => (
                      <MenuItem value={items.code} key={index}>{items.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <ReactFlagsSelect
                  selected={selectedCountry}
                  onSelect={code => setSelectedCountry(code)}
                  searchable
                  searchPlaceholder="Search countries"
                  placeholder="Select Country"
                  className="menu-flags"
                  selectButtonClassName="menu-flags-button"
                  style={{}}
                /> */}
                <StyledRedBox>
                  <ErrorMessage name="country" />
                </StyledRedBox>
              </>
            ) : (
              ""
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <label>Socials &#40;not required&#41;</label>
            <Box display="flex" alignItems="center" gap="8px">
              <BsTwitch fontSize={24} />
              {userData && userData.length !== 0 ? (
                <Box width="100%">
                  <Field
                    type="text"
                    name="twitchUrl"
                    placeholder="https://www.twitch.tv/..."
                    style={{ color: colors.textColor[100], backgroundColor: colors.greenAccent[600] }}
                  />
                  <StyledRedBox>
                    <ErrorMessage name="twitchUrl" />
                  </StyledRedBox>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              <BsYoutube fontSize={24} />
              {userData && userData.length !== 0 ? (
                <Box width="100%">
                  <Field
                    type="text"
                    name="youtubeUrl"
                    placeholder="https://www.youtube.com/..."
                    style={{ color: colors.textColor[100], backgroundColor: colors.greenAccent[600] }}
                  />
                  <StyledRedBox>
                    <ErrorMessage name="youtubeUrl" />
                  </StyledRedBox>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              <BsTwitter fontSize={24} />
              {userData && userData.length !== 0 ? (
                <Box width="100%">
                  <Field
                    type="text"
                    name="twitterUrl"
                    placeholder="https://www.twitter.com/..."
                    style={{ color: colors.textColor[100], backgroundColor: colors.greenAccent[600] }}
                  />
                  <StyledRedBox>
                    <ErrorMessage name="twitterUrl" />
                  </StyledRedBox>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              <BsFacebook fontSize={24} />
              {userData && userData.length !== 0 ? (
                <Box width="100%">
                  <Field
                    type="text"
                    name="facebookUrl"
                    placeholder="https://www.facebook.com/..."
                    style={{ color: colors.textColor[100], backgroundColor: colors.greenAccent[600] }}
                  />
                  <StyledRedBox>
                    <ErrorMessage name="facebookUrl" />
                  </StyledRedBox>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              <BsInstagram fontSize={24} />
              {userData && userData.length !== 0 ? (
                <Box width="100%">
                  <Field
                    type="text"
                    name="instagramUrl"
                    placeholder="https://www.instagram.com/..."
                    style={{ color: colors.textColor[100], backgroundColor: colors.greenAccent[600] }}
                  />
                  <StyledRedBox>
                    <ErrorMessage name="instagramUrl" />
                  </StyledRedBox>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Button type="submit" variant="contained" sx={{ backgroundColor: "#155683" }}>
            Update
          </Button>
        </Form>
      </Formik>
    </StyledBox>
  );
};

export default SettingsProfile;
