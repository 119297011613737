import React, {useState} from 'react';
import {Box, Typography, Button, useTheme} from "@mui/material";
import styled from 'styled-components';
import { axiosPrivate } from '../../api/axios';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';
import { tokens } from "../../theme";
import { EasyToastify } from "../../Extentions/EasyToastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";

const StyledBox = styled(Box)`
  border-radius: 16px;
  padding: 16px;

  form {
    width: 540px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    @media only screen and (max-width: 480px) {
      padding: 16px;
      width: 100%;
    }
  }

  label {
    color: #b7b7b7;
    font-size: 16px;
  }

  input {
    padding: 8px;
    border-radius: 8px;
    border: none;
    outline: 0;
    width: 100%;
  }
`;

// const StyledNewPassInput = styled(Box)`
//   position: relative;

//   span {
//     position: absolute;
//     right: 5px;
//     top: 2px;
//     cursor: pointer;
//   }
// `;

const StyledRedBox = styled(Box)`
  color: red;
`;

const validationSchema = yup.object({
  currentPassword: yup
    .string()
    .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/, 'Password shoould be 8-20 characters and include Letter, Number and special character (@, !, $, etc.)')
    .required("Current password is required"),
  newPassword: yup
    .string()
    .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/, 'Password shoould be 8-20 characters and include Letter, Number and special character (@, !, $, etc.)')
    .required("New password is required"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords Should match')
    .required("Confirm password is required"),
});

const SettingsPassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [userData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
});

  const handleSubmit = async (values, actions) => {
    await axiosPrivate
      .put(`User/Update/UserPassword`, {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      })
      .then((res) => {
        EasyToastify.ToastSuccess("Password successfully updated");
        actions.resetForm();
      })
      .catch((error) => {
        EasyToastify.ToastError("Current password is incorrect");
        // EasyToastify.ToastError(error.message);
      });
  };

  // Show/hide password
  const [type, setType]=useState('password');
  const [icon, setIcon]=useState(AiFillEyeInvisible);
  const handleEyeToggle=()=>{    
    if(type==='password'){
      setIcon(AiFillEye);      
      setType('text');
    }
    else{
      setIcon(AiFillEyeInvisible);     
      setType('password');
    }
  }

  return (
    <StyledBox style={{backgroundColor: colors.greenAccent[800]}}>
      <Formik
        initialValues={userData}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <Typography variant="h2">Change password</Typography>
          <Box display="flex" flexDirection="column">
            <label>Current password</label>
            <Field type="password" name="currentPassword" placeholder="Current password" style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}} />
            <StyledRedBox>
              <ErrorMessage name="currentPassword" />
            </StyledRedBox>
          </Box>
          <Box display="flex" flexDirection="column">
            <label>New password</label>
            {/* <StyledNewPassInput> */}
              {/* დასამატებელია Field-ში type={type}  */}
              <Field type="password" name="newPassword" placeholder="New password" style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}} />
              {/* <span onClick={handleEyeToggle}>{type === "text" ? <AiFillEye size={25}/> : <AiFillEyeInvisible size={25}/>}</span> */}
            {/* </StyledNewPassInput> */}
            <StyledRedBox>
              <ErrorMessage name="newPassword" />
            </StyledRedBox>
          </Box>
          <Box display="flex" flexDirection="column">
            <label>Confirm new password</label>
            <Field type="password" name="confirmNewPassword" placeholder="Confirm new password" style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}} />
            <StyledRedBox>
              <ErrorMessage name="confirmNewPassword" />
            </StyledRedBox>
          </Box>
          <Button type="submit" variant="contained" sx={{backgroundColor: "#155683"}}>Update</Button>
        </Form>
      </Formik>
    </StyledBox>
  )
}

export default SettingsPassword;
