import React, { useState, useEffect } from "react";
import { Box, Tooltip } from "@mui/material";
import styled from "styled-components";
import JsonData from "../Data/data.Url.json";
import axios from "axios";
import useAuth from "../hooks/useAuth";

const StyledGames = styled(Box)`
  display: flex;
  align-items: center;

  ul {
    display: flex;

    li {
      padding: 6px 2px;

      @media only screen and (max-width: 480px) {
        padding: 6px 1px;
      }

      button {
        display: flex;
        justify-content: center;
        text-decoration: none;
        border: none;
        background-color: #fff;
        border-radius: 30%;
        padding: 6px;
        cursor: pointer;

        @media only screen and (max-width: 480px) {
          padding: 2px;
        }

        img {
          width: 30px;
          height: 30px;

          @media only screen and (max-width: 480px) {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
`;

const StyledButton = styled.button`
  /* &:hover {
    background-color: #00060f;
    filter: invert(100%) sepia(0%) saturate(5614%) hue-rotate(297deg) brightness(108%) contrast(112%);
  } */

  /* img {
    &:hover {
      filter: invert(100%) sepia(0%) saturate(5614%) hue-rotate(297deg) brightness(108%) contrast(112%);
    }
  } */
`;

const HeaderTopGames = () => {
  const [gameData, setGameData] = useState();

  const { setGameTypeId } = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      let response = await axios.get(`${JsonData.apiurl}/Type/Game/Get`);
      setGameData(response.data);
    };
    fetchData();
  }, []);

  const handleGameTypeIdClick = (id) => {
    if (localStorage.getItem("gameTypeId") == id) {
      setGameTypeId(null);
      localStorage.removeItem("gameTypeId");
    } else {
      setGameTypeId(id);
      localStorage.setItem("gameTypeId", id);
    }
  };

  return (
    <StyledGames>
      <ul>
        {gameData
          ? gameData.map((items, index) => (
              <li key={index}>
                <Tooltip title={items.title}>
                  <StyledButton
                    onClick={() => handleGameTypeIdClick(items.id)}
                    style={{
                      backgroundColor:
                        localStorage.getItem("gameTypeId") == items.id
                          ? "#155683"
                          : localStorage.getItem("gameTypeId")
                          ? "#fff"
                          : "",
                    }}
                  >
                    <img
                      src={JsonData.imgurl + items.imageUrl.replace("\\\\", "\\")}
                      alt={items.title}
                    />
                  </StyledButton>
                </Tooltip>
              </li>
            ))
          : ""}
      </ul>
    </StyledGames>
  );
};

export default HeaderTopGames;
