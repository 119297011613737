import { Box, Button, useTheme } from "@mui/material";
import {tokens} from '../theme';
import Header from './Header';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailIcon from '@mui/icons-material/Email';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TrafficIcon from '@mui/icons-material/Traffic';
import StatBox from './StatBox';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  @media only screen and (max-width: 480px) {
    padding: 8px;
    font-size: 8px;
  }
`;

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Box>
          <StyledButton
            sx={{backgroundColor: colors.blueAccent[700], color: colors.grey[100], fontSize: "14px", fontWeight: "bold", padding: "10px 20px",}}
          >
            <DownloadOutlinedIcon sx={{mr: "10px"}} />
            Download Reports
          </StyledButton>
        </Box>
      </Box>
      {/* GRID & CHARTS */}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="20px">
        {/* ROW 1 */}
        <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox title="12,361" subtitle="Emails Sent" progress="0.75" increase="+14%" icon={
            <EmailIcon sx={{color: colors.greenAccent[600], fontSize: "26px"}} />
          } />
        </Box>
        <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox title="412,361" subtitle="Sales Obtained" progress="0.5" increase="+21%" icon={
            <PointOfSaleIcon sx={{color: colors.greenAccent[600], fontSize: "26px"}} />
          } />
        </Box>
        <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox title="32,361" subtitle="New Client" progress="0.30" increase="+14%" icon={
            <PersonAddIcon sx={{color: colors.greenAccent[600], fontSize: "26px"}} />
          } />
        </Box>
        <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
          <StatBox title="1,312,361" subtitle="Traffic Inbound" progress="0.75" increase="+14%" icon={
            <TrafficIcon sx={{color: colors.greenAccent[600], fontSize: "26px"}} />
          } />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;