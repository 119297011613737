import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Button, Modal } from '@mui/material';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { EasyToastify } from "../../Extentions/EasyToastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";

const StyledModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 640px;
  background-color: #010c20;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 32px;

  @media only screen and (max-width: 480px) {
    padding: 12px;
    width: 320px;
  }
`;

const StyledRedBox = styled(Box)`
  color: red;
`;

const StyledField = styled(Field)`
  border: none;
  width: 100%;
  border-radius: 6px;
  padding: 4px 8px;

  :focus {
    outline: none;
  }
`;

const validationSchema = yup.object({
  videoUrl: yup
    .string()
    .min(4, "Too small!")
    .max(30, "Too long string")
    .required("Title is required!"),
  date: yup.date().required("Date is required"),
  gameType: yup.number().required("Game Type is required!"),
});

const ShopBrandModal = () => {
  const axiosPrivate = useAxiosPrivate();
  const [brandData, setBrandData] = useState(null);
  const [isDataSet, setIsDataSet] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getBrands = async () => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(
        "/Shop/Brands",
        {
          signal: controller.signal,
        }
      );
      setBrandData(response.data);
    } catch (err) {
      navigate("/", { state: { from: location }, replace: true });
    }
    return () => {
      controller.abort();
    };
  };

  const deletebrand = async (id) => {
    const controller = new AbortController();
    try {
      await axiosPrivate
        .delete(`/Shop/Brands?id=${id}`, {
          signal: controller.signal,
        })
        .then((res) => {
          if (res.status === 200) {
            EasyToastify.ToastSuccess("Brand Deleted");
          }
        });
    } catch (err) {
      if (err.request.status === 400) {
        EasyToastify.ToastError("Brand does not exists");
      } else if (err.request.status === 500) {
        EasyToastify.ToastError("Server Down Or in Maintenance");
      } else {
        EasyToastify.ToastError("Unhendled exeption");
      }
    }
  };

  const handleOnSubmit = async (values, actions) => {
    try {
      await axiosPrivate
        .post("/Shop/Brands", { name: brandInput })
        .then((response) => {
          actions.setSubmitting(false);
          actions.resetForm();
          EasyToastify.ToastSuccess("Brand Added");
          handleServerResponse(true, "");
          handleClose();
        });
    } catch (error) {
      actions.setSubmitting(false);
      handleServerResponse(false, error.response.data.error);
      EasyToastify.ToastError("Error");
    }
  };

  useEffect(() => {
    if (!isDataSet) {
      getBrands();
      setIsDataSet(true);
    }
  }, [brandData]);

  const handleDelete = async (e, id) => {
    if (window.confirm("do you want to delete?")) {
      await deletebrand(id);
    }
    getBrands();
  };

  const [brandInput, setbrandInput] = useState("");
  const handleChange = (e) => {
    setbrandInput(e.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Button
        variant="outlined"
        size="large"
        sx={{ color: "green", backgroundColor: "#2f475d" }}
        onClick={handleOpen}
      >
        Add Shop Brand&nbsp;
        <AddCircleOutlineOutlinedIcon fontSize="large" />
      </Button>
      <Box display="flex" flexDirection="column" gap="4px">
        {brandData ?
          brandData.map((items, index) => (
            <Box key={index} display="flex" alignItems="center" gap="4px">
              <p style={{ padding: "6px", backgroundColor: "#0057b7", borderRadius: "4px", width: "100%" }}>{items.name}</p>
              <Button variant="outlined" color="error" onClick={(e) => handleDelete(e, items.id)}>
                <DeleteOutlinedIcon />
              </Button>
            </Box>
          )) : ""}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledModalBox>
          <Formik
            initialValues={brandInput}
            onSubmit={handleOnSubmit}
          // validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form onSubmit={handleOnSubmit} style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
                <label>brand Name:</label>
                <StyledField
                  type="text"
                  name="brand"
                  value={brandInput}
                  onChange={handleChange}
                />
                <StyledRedBox>
                  <ErrorMessage name="brand" />
                </StyledRedBox>
                <Button type="submit" color="success" variant="outlined" sx={{ width: "100%" }}>Add</Button>
                {serverState && (
                  <p className={!serverState.ok ? "errorMsg" : ""}>
                    {serverState.msg}
                  </p>
                )}
              </Form>
            )}
          </Formik>
        </StyledModalBox>
      </Modal>
    </Box>
  )
}

export default ShopBrandModal;
