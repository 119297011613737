import React, { useState, useEffect } from 'react';
import { Box, useTheme, Typography, Skeleton, Pagination } from '@mui/material';
import styled from 'styled-components';
import { tokens } from "../../theme";
import { Link, useOutletContext } from 'react-router-dom';
import axios from "axios";
import JsonData from '../../Data/data.Url.json';
import { GiTwoCoins } from 'react-icons/gi';

const StyledImg = styled.img`
    width: 240px;
    height: 135px;
    object-fit: contain;
`;

const StyledLink = styled(Link)`
    max-width: 320px;
    color: #FFF;
    border-radius: 16px;
    box-shadow: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    padding: 8px;

    span {
        padding: 1px;
        width: 100%;
    }

    &:hover {
        box-shadow: 0 10px 20px rgba(0,0,0,0.20), 0 5px 5px rgba(0,0,0,0.18);
    }
`;

const StyledBox = styled(Box)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 32px 0;
    /* min-width: 904px; */

    @media only screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 768px) {
        
    }
    @media only screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 10px 0;
    }
    @media only screen and (max-width: 320px) {
        
    }
`;

const ShopFeed = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let [page, setPage] = useState(1);
    let [pageCount, setPageCount] = useState(10);
    const [shopData, setShopData] = useState();

    const handleChange = (e, p) => {
        setPage(p);
    };

    const [searchState, categoryState, brandState] = useOutletContext();
    // console.log(brandState);

    let getShopItems = async () => {
        let response = await axios.get(`${JsonData.apiurl}/Shop/Products?&title=${searchState}&brandId=${brandState}&CategoriesId=${categoryState}&pageSize=9&pageNumber=${page}`);
        setShopData(response.data);
        setPageCount(response.headers["page-count"]);
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => getShopItems(), 1000);
        return () => clearTimeout(timeOutId);
    }, [page, searchState, categoryState, brandState]);

    pageCount = Number(pageCount);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box>
                <StyledBox>
                    {shopData && shopData.length !== 0 ? (
                        shopData.map((items, index) => (
                            <StyledLink key={index} to={`${items.id}`} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: colors.greenAccent[800] }}>
                                <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center">
                                    <StyledImg src={JsonData.imgurl + items.imgUrl.replace("\\\\", "\\")} alt="" />
                                    <Typography variant="h5" sx={{ color: colors.textColor[100] }}>{items.title}</Typography>
                                    <span style={{ backgroundColor: colors.greenAccent[600] }}></span>
                                    <Box display="flex" justifyContent="space-between" width="90%">
                                        <p style={{ color: colors.textColor[100] }}>{items.price}&nbsp;₾</p>
                                        <Box display="flex" alignItems="center" gap="4px">
                                            <p style={{ color: colors.textColor[100] }}>{items.cyberPrice}</p>
                                            <GiTwoCoins fontSize={16} />
                                        </Box>
                                    </Box>
                                </Box>
                            </StyledLink>
                        ))
                    ) : (
                        [...Array(9)].map((e, i) =>
                            <StyledLink key={i}>
                                <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center" padding="0 40px">
                                    <Skeleton variant="rounded" width={240} height={135} />
                                    <Typography variant="h5"><Skeleton width={240} /></Typography>
                                    <p><Skeleton width={240} /></p>
                                </Box>
                            </StyledLink>
                        ))
                    }
                </StyledBox>
                <Box justifyContent={"center"} alignItems="center" display={"flex"} sx={{ padding: "20px 0" }}>
                    <Pagination
                        size="large"
                        shape="rounded"
                        variant="outlined"
                        count={pageCount}
                        page={page}
                        onChange={handleChange}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default ShopFeed;
