import React from 'react';
import styled from 'styled-components';
import CyberLogo from "../Assets/Images/cyberlogo.png";
import CyberLogoBlue from "../Assets/Images/cyberBlueTV.png";
import {Link} from 'react-router-dom';
import { tokens } from "../theme";
import { Box, useTheme } from '@mui/material';

const StyledFooter = styled(Box)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90px;

    @media only screen and (max-width: 768px) {
        height: 60px;
    }
    @media only screen and (max-width: 480px) {
        height: 40px;
    }

    div {
        a {
            img {
                width: 150px;
                
                @media only screen and (max-width: 480px) {
                    width: 100px;
                }
            }
        }
        p {
            color: #fff;

            @media only screen and (max-width: 480px) {
                font-size: 10px;
            }
        }
    }
`;

const StyledLink = styled(Link)`
    display: flex;
`;

const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <StyledFooter backgroundColor={colors.greenAccent[800]}>
        <div>
            <p style={{color: colors.textColor[100]}}>&#169; 2023 CyberTV, all rights reserved</p>
        </div>
        <Box>
            <StyledLink to="/"><img src={theme.palette.mode === "dark" ? CyberLogo : CyberLogoBlue} alt="Cyber Logo" /></StyledLink>
        </Box>
    </StyledFooter>
  )
}

export default Footer;
