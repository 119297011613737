import React from 'react';
import styled from 'styled-components';
import {
    FaTwitter,
    FaYoutube,
    FaFacebook,
    FaTwitch,
    FaSteam,
    FaInstagram,
    FaDiscord,
  } from "react-icons/fa";
import { useTheme, Link } from "@mui/material";
import { tokens } from "../theme";

const StyledSocials = styled.div`
  display: flex;
  align-items: center;
  
  ul {
    display: flex;
  }

  li {
    padding: 5px 2px;
  }

  a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    border-radius: 50%;
    padding: 6px;
    font-size: 18px;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const HeaderTopSocials = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <StyledSocials>
        <ul>
            <li>
                <Link
                    href="https://twitter.com/CyberTV_GE"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{backgroundColor: colors.iconColor[100], color: colors.iconColor[400], "&:hover": {backgroundColor: colors.iconColor[400], color: colors.iconColor[100]}}}
                    >
                    <FaTwitter />
                </Link>
            </li>
            <li>
                <Link
                    href="https://www.facebook.com/CyberTV.gg"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{backgroundColor: colors.iconColor[100], color: colors.iconColor[400], "&:hover": {backgroundColor: colors.iconColor[400], color: colors.iconColor[100]}}}
                    >
                    <FaFacebook />
                </Link>
            </li>
            <li>
                <Link
                    href="https://www.youtube.com/cybertv/?sub_confirmation=1"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{backgroundColor: colors.iconColor[100], color: colors.iconColor[400], "&:hover": {backgroundColor: colors.iconColor[400], color: colors.iconColor[100]}}}
                    >
                    <FaYoutube />
                </Link>
            </li>
            <li>
                <Link
                    href="https://www.twitch.tv/cybertv_gg"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{backgroundColor: colors.iconColor[100], color: colors.iconColor[400], "&:hover": {backgroundColor: colors.iconColor[400], color: colors.iconColor[100]}}}
                    >
                    <FaTwitch />
                </Link>
            </li>
            <li>
                <Link
                    href="https://steamcommunity.com/groups/CyberTV_GE"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{backgroundColor: colors.iconColor[100], color: colors.iconColor[400], "&:hover": {backgroundColor: colors.iconColor[400], color: colors.iconColor[100]}}}
                    >
                    <FaSteam />
                </Link>
            </li>
            <li>
                <Link
                    href="https://www.instagram.com/cybertv.ge/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{backgroundColor: colors.iconColor[100], color: colors.iconColor[400], "&:hover": {backgroundColor: colors.iconColor[400], color: colors.iconColor[100]}}}
                    >
                    <FaInstagram />
                </Link>
            </li>
            <li>
                <Link
                    href="https://discord.gg/6WeGtrR4"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{backgroundColor: colors.iconColor[100], color: colors.iconColor[400], "&:hover": {backgroundColor: colors.iconColor[400], color: colors.iconColor[100]}}}
                    >
                    <FaDiscord />
                </Link>
            </li>
        </ul>
    </StyledSocials>
  )
}

export default HeaderTopSocials;
