import React, { useState, useEffect } from "react";
import { Box, Avatar, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  BsTwitch,
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import axios from "axios";
import JsonData from "../../Data/data.Url.json";
import { SiRiotgames, SiSteam } from "react-icons/si";
import useAuth from "../../hooks/useAuth";
import { tokens } from "../../theme";
import { FlagIcon } from "react-flag-kit";

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 48px;
  padding: 32px 0;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    gap: 28px;
    padding: 20px 0;
  }
`;

const StyledBoxCont = styled(Box)`
  @media only screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
  }
`;

const StyledBoxTeamInfos = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 400px;

  border-radius: 16px;
  padding: 16px;
  /* box-shadow: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1); */

  /* &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.20), 0 5px 5px rgba(0,0,0,0.18);
  } */

  @media only screen and (max-width: 480px) {
    width: 320px;
  }
`;

const StyledBoxDatagrid = styled(Box)`
  height: 650px;
  width: 100%;

  @media only screen and (max-width: 480px) {
    width: 98vw;
  }
`;

const StyledBoxDatagridCont = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 800px;
  gap: 12px;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    width: auto;
  }

  h4 {
    padding-left: 12px;
  }
`;

const StyledBoxStats = styled(Box)`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 480px) {
    padding-left: 20px;
    flex-direction: column;
    gap: 3px;
  }
`;

const StyledImg = styled.img`
  width: 32px;
  border-radius: 4px;
`;

const StyledA = styled.a`
  font-size: 36px;
`;

const StyledGameAccountsBox = styled(Box)`
  div {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-size: 24px;

    p {
      font-size: 16px;
    }
  }
`;

const StatisticsBoxCont = styled(Box)`
  border-radius: 16px;
  padding: 16px;
  /* box-shadow: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1); */

  /* &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.20), 0 5px 5px rgba(0,0,0,0.18);
  } */
`;

const columns = [
  { field: "id", headerName: "ID", width: 10 },
  { field: "game", headerName: "Game name", width: 120 },
  {
    field: "fullName",
    headerName: "Tournament name",
    width: 240,
    editable: true,
  },
  {
    field: "lastName",
    headerName: "Opponent name",
    width: 150,
    editable: true,
  },
  {
    field: "firstName",
    headerName: "Won or Lost",
    width: 150,
    editable: true,
  },
  {
    field: "age",
    headerName: "Date / Time",
    type: "number",
    width: 110,
    editable: true,
  },
];

const rows = [
  { id: 1, game: "Dota", lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, game: "Dota", lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, game: "Dota", lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, game: "Dota", lastName: "Stark", firstName: "Arya", age: 16 },
  {
    id: 5,
    game: "Dota",
    lastName: "Targaryen",
    firstName: "Daenerys",
    age: null,
  },
  { id: 6, game: "Dota", lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, game: "Dota", lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, game: "Dota", lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, game: "Dota", lastName: "Roxie", firstName: "Harvey", age: 65 },
  { id: 10, game: "Dota", lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 11, game: "Dota", lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 12, game: "Dota", lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 13, game: "Dota", lastName: "Stark", firstName: "Arya", age: 16 },
  {
    id: 14,
    game: "Dota",
    lastName: "Targaryen",
    firstName: "Daenerys",
    age: null,
  },
  { id: 15, game: "Dota", lastName: "Melisandre", firstName: null, age: 150 },
  { id: 16, game: "Dota", lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 17, game: "Dota", lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 18, game: "Dota", lastName: "Roxie", firstName: "Harvey", age: 65 },
];

const UserProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { id } = useParams();
  const [userData, setUserData] = useState();
  const { auth } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${JsonData.apiurl}/User/Get/User?id=${id}`
      );
      setUserData(response.data);
      console.log(response.data);
    };
    fetchData();
  }, [auth]);

  if (userData)
    return (
      <StyledBox>
        <StyledBoxCont>
          <StyledBoxTeamInfos
            // sx={{ border: 1, borderRadius: "16px", borderColor: "#313b4f" }}
            style={{ backgroundColor: colors.greenAccent[800] }}
          >
            {/* {userData.profileImg !== null ? (
              <img src={userData.profileImg} alt="" />
            ) : (
              <Avatar
                src=""
                alt="Kacis Nickname"
                sx={{ width: 120, height: 120 }}
              />
            )} */}
            {userData && userData.profileImg ? (
              <Avatar
                src={JsonData.imgurl + userData.profileImg.replace("\\\\", "\\")}
                alt=""
                sx={{ width: 120, height: 120 }}
              />
            ) : (
              <Avatar
                src=""
                alt="Kacis Nickname"
                sx={{ width: 120, height: 120 }}
              />
            )}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="5px"
            >
              <Typography variant="h3">{userData?.nickname}</Typography>
              {userData.teamId !== null && userData.teamName !== null && userData.teamImg !== null ? (
                <Link to={`/team/${userData.teamId}`}>
                  <Box display="flex" alignItems="center" gap="8px">
                    <Avatar src={JsonData.imgurl + userData.teamImg.replace("\\\\", "\\")} alt="Gundis Logo" sx={{ width: "36px", height: "36px" }} />
                    <Typography variant="h4" style={{ color: colors.textColor[100] }}>{userData.teamName}</Typography>
                  </Box>
                </Link>
              ) : ""}
            </Box>
            <Box display="flex" flexDirection="column" gap="8px">
              {userData.about !== "" ? (
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography variant="h4" fontWeight="bold">
                    About
                  </Typography>
                  <p>{userData?.about}</p>
                </Box>
              ) : ""}
              {userData.country !== "" ? (
                <Box display="flex" flexDirection="column" gap="6px">
                  <Typography fontWeight="bold">Nationality</Typography>
                  <Box display="flex" alignItems="center" gap="6px">
                    <FlagIcon code={userData?.country} width="12%" height="12%" style={{ borderRadius: "2px" }} />
                    <Typography>{userData?.country}</Typography>
                  </Box>
                </Box>
              ) : ""}
              {userData.facebookUrl !== "" || userData.instagramUrl !== "" || userData.twitterUrl !== "" || userData.youtubeUrl !== "" || userData.twitchUrl !== "" ? (
                <>
                  <Typography fontWeight="bold">Socials</Typography>
                  <Box display="flex" gap="10px">
                    {userData?.facebookUrl ? (
                      <StyledA
                        href={userData?.facebookUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: colors.iconColor[100] }}
                      >
                        <BsFacebook />
                      </StyledA>
                    ) : (
                      ""
                    )}
                    {userData?.instagramUrl ? (
                      <StyledA
                        href={userData?.instagramUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: colors.iconColor[100] }}
                      >
                        <BsInstagram />
                      </StyledA>
                    ) : (
                      ""
                    )}
                    {userData?.twitterUrl ? (
                      <StyledA
                        href={userData?.twitterUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: colors.iconColor[100] }}
                      >
                        <BsTwitter />
                      </StyledA>
                    ) : (
                      ""
                    )}
                    {userData?.youtubeUrl ? (
                      <StyledA
                        href={userData?.youtubeUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: colors.iconColor[100] }}
                      >
                        <BsYoutube />
                      </StyledA>
                    ) : (
                      ""
                    )}
                    {userData?.twitchUrl ? (
                      <StyledA
                        href={userData?.twitchUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: colors.iconColor[100] }}
                      >
                        <BsTwitch />
                      </StyledA>
                    ) : (
                      ""
                    )}
                  </Box>
                </>
              ) : ""}
              {/* {userData.steamID !== null && userData.riotID !== null ? (
                <>
                  <Typography fontWeight="bold">Game Accounts</Typography>
                  <StyledGameAccountsBox
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                  >
                    <div>
                      <SiSteam />
                      <p>{userData.steamID}</p>
                    </div>
                    <div>
                      <SiRiotgames />
                      <p>{userData.riotID}</p>
                    </div>
                  </StyledGameAccountsBox>
                </>
              ) : ""} */}
            </Box>
          </StyledBoxTeamInfos>
        </StyledBoxCont>
        <StatisticsBoxCont sx={{ backgroundColor: colors.greenAccent[800] }}>
          <StyledBoxDatagridCont>
            <Typography variant="h4" fontWeight="bold">
              Stats
            </Typography>
            <StyledBoxStats>
              <Typography variant="h5">Total matches: 50</Typography>
              <Typography variant="h5">Tournaments played: 50</Typography>
              <Typography variant="h5">Win rate: 100%</Typography>
              <Typography variant="h5">Ranking: 1</Typography>
            </StyledBoxStats>
            <Typography variant="h4" fontWeight="bold">
              Recent Matches
            </Typography>
            <StyledBoxDatagrid>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={12}
                rowsPerPageOptions={[12]}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </StyledBoxDatagrid>
          </StyledBoxDatagridCont>
        </StatisticsBoxCont>
      </StyledBox>
    );
};

export default UserProfile;
