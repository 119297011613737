import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from "../../theme";
import ShopCategoriesModal from './ShopCategoriesModal';
import ShopBrandModal from './ShopBrandModal';

const ShopCategories = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box padding="20px">
      <Box paddingBottom="30px">
        <Typography variant="h2" color={colors.grey[100]} textTransform="uppercase" fontWeight={700} fontSize={32}>
          Shop Categories
        </Typography>
        <Typography variant="h5" color={colors.greenAccent[400]} fontWeight={400} fontSize={16} paddingTop="5px">
          Manage Shop Categories
        </Typography>
      </Box>
      <Box display="flex" gap="32px">
        <ShopCategoriesModal />
        <ShopBrandModal />
      </Box>
    </Box>
  )
}

export default ShopCategories;
