import React, { useState, useEffect } from 'react';
import { InputLabel, MenuItem, FormControl, Select, useTheme } from '@mui/material';
import axios from 'axios';
import JsonData from '../../Data/data.Url.json';
import { tokens } from "../../theme";

const ShopBrands = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [brand, setBrand] = useState("");
    const [brandsData, setBrandsData] = useState();

    const getBrands = async () => {
        const response = await axios.get(`${JsonData.apiurl}/Shop/Brands`);
        setBrandsData(response.data);
    };

    useEffect(() => {
        getBrands();
    }, [brand]);

    const handleChange = (event) => {
        setBrand(event.target.value);
        props.setBrandState(event.target.value);
    };

    if (brandsData) {
        return (
            <FormControl fullWidth sx={{ marginBottom: "20px", backgroundColor: colors.greenAccent[800], borderRadius: "14px" }}>
                <InputLabel id="demo-simple-select-label" sx={{ color: "inherit", "&.Mui-focused": { color: "inherit" } }}>Brand</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={brand}
                    label="Brand"
                    onChange={handleChange}
                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                    MenuProps={{ style: {maxHeight: 320}, MenuListProps: {sx: {backgroundColor: colors.greenAccent[800]}}}}
                >
                    <MenuItem value="">All</MenuItem>
                    {brandsData.map((items, index) => (
                        <MenuItem value={items.id} key={index}>{items.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
}

export default ShopBrands;
