import useAxiosPrivate from "./useAxiosPrivate";
import { EasyToastify } from "../Extentions/EasyToastify";

const useNewsList = () => {
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const UpdateNews = async (Id) => {
    try {
      const response = await axiosPrivate.get(
        `NewsMaker/GetNewsForNewsMakerById?Newsid=${Id}`,
        {
          signal: controller.signal,
        }
      );
      controller.abort();
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return () => {
      controller.abort();
    };
  };
  const DeleteNews = async (id) => {
    try {
      const response = await axiosPrivate.delete(
        `NewsMaker/Delete/News?id=${id}`,
        {
          signal: controller.signal,
        }
      );
      if (response.status === 200) {
        EasyToastify.ToastSuccess("News Deleted");
      }
      controller.abort();
    } catch (err) {
      EasyToastify.ToastError("Something went wrong");
    }
    controller.abort();
  };
  return [UpdateNews, DeleteNews];
};

export default useNewsList;
