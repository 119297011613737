import React from 'react';
import {Avatar, Box, Typography} from '@mui/material';
import styled from 'styled-components';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

const StyledParagraph = styled.p`
  padding-bottom: 8px;
`;

const StyledBox = styled(Box)`
  :hover {
    background-color: #2c3547;
  }
`;

const TournamentResults = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" paddingBottom="24px">
      <Box display="flex" flexDirection="column" gap="24px" maxWidth="60vw">
        <Box display="flex" flexDirection="column">
          <Box display="flex" gap="118px" paddingLeft="16px">
            <StyledParagraph>#</StyledParagraph>
            <StyledParagraph>TEAM</StyledParagraph>
          </Box>
          <StyledBox display="flex" alignItems="center" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Box display="flex" alignItems="center" gap="10px">
              <EmojiEventsIcon sx={{color: "#f7d000"}}  />
              <p>1st</p>
            </Box>
            <Box display="flex" alignItems="center" gap="10px" paddingLeft="88px">
              <Avatar>
                <GroupsIcon fontSize="large" />
              </Avatar>
              <Typography variant="h6">Protos</Typography>
            </Box>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Box display="flex" alignItems="center" gap="10px">
              <EmojiEventsIcon sx={{color: "#bababa"}}  />
              <p>2nd</p>
            </Box>
            <Box display="flex" alignItems="center" gap="10px" paddingLeft="78px">
              <Avatar>
                <GroupsIcon fontSize="large" />
              </Avatar>
              <Typography variant="h6">Protos</Typography>
            </Box>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Box display="flex" alignItems="center" gap="10px">
              <EmojiEventsIcon sx={{color: "#c77b30"}}  />
              <p>3rd - 4th</p>
            </Box>
            <Box display="flex" flexDirection="column" gap="10px">
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="48px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="48px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
            </Box>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Box display="flex" alignItems="center" gap="10px">
              <MilitaryTechIcon sx={{color: "#918676"}}  />
              <p>5th - 8th</p>
            </Box>
            <Box display="flex" flexDirection="column" gap="10px">
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="48px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="48px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="48px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="48px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
            </Box>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Box display="flex" alignItems="center" gap="10px">
              <MilitaryTechIcon sx={{color: "#918676"}}  />
              <p>9th - 16th</p>
            </Box>
            <Box display="flex" flexDirection="column" gap="10px">
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="44px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="44px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="44px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="44px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="44px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="44px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="44px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="44px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
            </Box>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Box display="flex" alignItems="center" gap="10px">
              <MilitaryTechIcon sx={{color: "#918676"}}  />
              <p>17th - 32nd</p>
            </Box>
            <Box display="flex" flexDirection="column" gap="10px">
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px" paddingLeft="34px">
                <Avatar>
                  <GroupsIcon fontSize="large" />
                </Avatar>
                <Typography variant="h6">Protos</Typography>
              </Box>
            </Box>
          </StyledBox>
        </Box>
      </Box>
    </Box>
  )
}

export default TournamentResults;
