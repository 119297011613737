import React, { useEffect, useState } from "react";

// MUI Imports
import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

// Component Imports
import Header from "./Header";
import UsersModal from "./UsersModal";
import { EasyToastify } from "../Extentions/EasyToastify";
// Global State Imports
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const [usersData, setUsersData] = useState();
  const [modal, setModal] = useState(false);
  const [singleUser, setSingleUser] = useState();
  const [editModal, setEditModal] = useState(false);

  const getUsers = async () => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get("Admin/Get/Users", {
        signal: controller.signal,
      });
      setUsersData(response.data);
    } catch (err) {
      console.error(err);
      navigate("/", { state: { from: location }, replace: true });
    }
    return () => {
      controller.abort();
    };
  };

  const updateUser = async (id) => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(`/Admin/Get/User?id=${id}`, {
        signal: controller.signal,
      });
      return response.data;
    } catch (err) {
      console.error(err);
      navigate("/", { state: { from: location }, replace: true });
    }
    return () => {
      controller.abort();
    };
  };

  const deleteUser = async (id) => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.delete(
        `/Admin/delete/user?id=${id}`,
        {
          signal: controller.signal,
        }
      );
      if (response.status === 200) {
        EasyToastify.ToastSuccess("User Removed");
      }
      controller.abort();
    } catch (err) {
      EasyToastify.ToastError("Something went wrong");
    }
    return () => {
      controller.abort();
    };
  };

  useEffect(() => {
    getUsers();
  }, []);

  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      getUsers();
    }
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
    if (editModal) {
      getUsers();
      setSingleUser(undefined);
    }
  };
  const handleEdit = async (e, id) => {
    setSingleUser(await updateUser(id));
    toggleEditModal();
  };

  const handleDelete = async (e, id) => {
    if (window.confirm("do you want to delete?")) {
      await deleteUser(id);
    }
    getUsers();
  };

  useEffect(() => {}, [singleUser]);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "nickname",
      headerName: "Nickname",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "phone", headerName: "Phone Number", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "access",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            // m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              access === "admin"
                ? colors.greenAccent[500]
                : colors.greenAccent[600]
            }
            borderRadius="4px"
          >
            {access === "Admin" && <AdminPanelSettingsOutlinedIcon />}

            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {access}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,

      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={async (e) => await handleEdit(e, params.row.id)}
          >
            <ModeEditOutlinedIcon />
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={async (e) => await handleDelete(e, params.row.id)}
            variant="contained"
          >
            <DeleteOutlinedIcon />
          </Button>
        );
      },
    },
  ];

  if (usersData != null) {
    return (
      <Box m="20px">
        <Header title="TEAM" subtitle="Managing Members" />
        <Button
          variant="outlined"
          size="large"
          sx={{ color: "green", backgroundColor: "#2f475d" }}
          onClick={toggleModal}
        >
          Add User&nbsp;
          <AddCircleOutlineOutlinedIcon fontSize="large" />
        </Button>
        <UsersModal
          apiUrl="create/user"
          modal={modal}
          toggleModal={toggleModal}
          method={axiosPrivate.post}
        />

        {singleUser ? (
          <UsersModal
            method={axiosPrivate.put}
            apiUrl="Update/user"
            modal={editModal}
            toggleModal={toggleEditModal}
            user={singleUser}
          />
        ) : (
          ""
        )}
        <Box
          m="40px 0 0 0"
          height="69vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
          }}
        >
          <DataGrid
            rows={usersData.map((data) => {
              return {
                id: data.id,
                firstname: data.firstname,
                lastname: data.lastname,
                nickname: data.nickName,
                age: data.age,
                phone: data.phonenumber,
                email: data.email,
                access: data.role,
              };
            })}
            columns={columns}
          />
        </Box>
      </Box>
    );
  }
};

export default Team;
