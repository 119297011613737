import React from "react";
import axios from "axios";
import JsonData from "../Data/data.Url.json";
import { useState, useEffect } from "react";

const GameId = () => {
  const [games, setGames] = useState();
  const getGames = async () => {
    const response = await axios.get(`${JsonData.apiurl}/type/game/get/selectors`);
    setGames(response.data);
  };

  useEffect(() => {
    getGames();
  }, []);
  
  if (games)
    return games.map((data, index) => {
      return (
        <option key={index} value={data.id}>
          {data.title}
        </option>
      );
    });
};

export default GameId;
