import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from "../../theme";
import styled from 'styled-components';
import testMatchesData from '../../Data/testMatches.json';

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 0;

  span {
    padding: 1px;
    width: 84%;
    margin: 0 auto;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const MatchesFeed = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <StyledBox width="290px" backgroundColor={colors.greenAccent[800]} borderRadius="14px">
        <Typography variant="h4" padding="8px 0" fontWeight="bold">Matches</Typography>
        <span style={{ backgroundColor: colors.greenAccent[600] }}></span>
        {testMatchesData.map((items, index) => (
          <React.Fragment key={index}>
            <Box display="flex" justifyContent="space-evenly" alignItems="center" width="100%">
              <p>{items.firstTeam}</p>
              <p style={{ fontSize: "18px" }}>Vs</p>
              {/* Vs-s backgroundad qondes tamashis logo */}
              <p>{items.secondTeam}</p>
            </Box>
            <span style={{ backgroundColor: colors.greenAccent[600] }}></span>
          </React.Fragment>
        ))}
      </StyledBox>
    </Box>
  )
}

export default MatchesFeed;
