import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CyberTVLogo from "../Assets/Images/cyberlogo.png";
import styled from "styled-components";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormInput from "./FormInput";
import axios from "axios";
import JsonData from "../Data/data.Url.json";
import BackgroundImg from "../Assets/Images/background.png";

const CustomAdminLogin = styled.div`
  background-color: #042045;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;

  img {
    width: 200px;
    margin-bottom: 15px;
  }
`;

const CustomAdminForm = styled.form`
  width: 30vw;
  display: flex;
  flex-direction: column;
  padding: 18px 30px;
  background-color: #11609a;
  color: #fff;
  border: 1.5px solid #a70831;
  border-radius: 10px;
  @media only screen and (max-width: 1500px) {
    width: 35%;
  }
  @media only screen and (max-width: 1080px) {
    width: 75%;
  }
  @media only screen and (max-width: 1230px) {
    width: 50%;
  }
  @media only screen and (max-width: 750px) {
    width: 80%;
  }
  @media only screen and (max-width: 570px) {
    width: 95%;
  }

  .first-last-names,
  .pass-confirmPass {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    gap: 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2px 0;

    label {
      padding: 5px 0;
    }

    input {
      background-color: #042045;
      color: #fff;
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 7px;
      ::-webkit-calendar-picker-indicator {
        filter: invert(1);
        font-size: 15px;
      }

      ::-webkit-calendar-picker-indicator:hover {
        cursor: pointer;
        opacity: 0.6;
      }

      :focus {
        outline: none;
      }

      ::placeholder {
        color: #d0c9cc;
      }
    }

    span {
      display: none;
      font-size: 14px;
      padding: 2px;
      color: #ffcccb;
    }

    select {
      width: 100%;
      padding: 10px;
      border: none;
      color: #fff;
      border-radius: 7px;

      :focus {
        outline: none;
      }
    }

    .submit:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const StyledLabel = styled.label`
  padding: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  input {
    cursor: pointer;
  }

  input.larger {
    width: 18px;
    height: 18px;
    accent-color: #9b59b6;
    /* accent-color: #34495e; */
  }

  span {
    cursor: pointer;
  }
`;

const AdminLogin = () => {
  const navigate = useNavigate();

  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    nickName: "",
    email: "",
    BirthDate: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const [disabled, setDisabled] = useState(false);

  const postUrl = `${JsonData.apiurl}/authentification/register`;
  const redirectToHome = () => {
    setTimeout(() => {
      navigate("/");
    }, 3700);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    await axios
      .post(postUrl, {
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        nickName: registerData.nickName,
        email: registerData.email,
        BirthDate: registerData.BirthDate,
        phoneNumber: registerData.phoneNumber,
        password: registerData.password,
      })
      .catch((error) => {
        toast.error(error.response.headers["registration-response"], {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setDisabled(false);
      })
      .then((res) => {
        toast.success(res.headers["registration-response"], {
          position: "bottom-center",
          zIndex: 20,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          transition: Flip,
          theme: "dark",
        });
        setDisabled(false);
        redirectToHome();
        setRegisterData({
          firstName: "",
          lastName: "",
          nickName: "",
          email: "",
          BirthDate: "",
          phoneNumber: "",
          password: "",
          confirmPassword: "",
        });
      });
  };

  const handleChange = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };

  const nameInput = [
    {
      id: 1,
      type: "text",
      name: "firstName",
      placeholder: "Your Name",
      label: "First Name",
      errorMessage: "Firstname Should be only letters",
      pattern: "^[A-Za-z]{1,50}$",
      required: true,
      autoComplete: "off",
    },
    {
      id: 2,
      type: "text",
      name: "lastName",
      placeholder: "Your Last Name",
      label: "Last Name",
      required: true,
      pattern: "^[A-Za-z]{1,50}$",
      errorMessage: "Lastname Should be only letters",
      autoComplete: "off",
    },
  ];

  const inputs = [
    {
      id: 3,
      type: "text",
      name: "nickName",
      placeholder: "Your Nickname",
      label: "Nickname",
      errorMessage: "Nickname should be 3-16 characters and Latin alphabet",
      pattern: "^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()?]{3,16}$",
      required: true,
      autoComplete: "off",
    },
    {
      id: 4,
      type: "email",
      name: "email",
      placeholder: "Your Email",
      label: "E-mail",
      errorMessage: "Enter  valid email address",
      autoComplete: "off",
    },
    {
      id: 5,
      type: "date",
      name: "BirthDate",
      label: "Birth date",
    },
    // {
    //   id: 6,
    //   type: "text",
    //   name: "city",
    //   placeholder: "Your City or Region",
    //   label: "Country",
    //   pattern: "^[A-Za-z]{1,50}$",
    //   errorMessage: "Enter only letters",
    //   autoComplete: "off",
    // },
    {
      id: 7,
      type: "tel",
      name: "phoneNumber",
      placeholder: "Your Phone",
      pattern: "^[0-9-+]{1,30}$",
      label: "Phone number",
      errorMessage: "Enter only Numbers",
    },
  ];

  const passInput = [
    {
      id: 8,
      type: "password",
      name: "password",
      placeholder: "Your Password",
      label: "Password",
      required: true,
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      errorMessage:
        "Password shoould be 8-20 characters and include  Letter,  Number and special character (@, !, $, etc.)",
    },
    {
      id: 9,
      type: "password",
      name: "confirmPassword",
      placeholder: "Repeat Passowrd",
      label: "Confirm Password",
      required: true,
      pattern: registerData.password,
      errorMessage: "Passwords don't match",
    },
  ];

  return (
    <>
      <CustomAdminLogin
        style={{
          backgroundImage: `url(${BackgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <Link to="/">
          <img src={CyberTVLogo} alt=" " />
        </Link>
        <CustomAdminForm onSubmit={handleSubmit}>
          <div className="first-last-names">
            {nameInput.map((input) => (
              <div>
                <label>{input.label} :</label>
                <FormInput
                  key={input.id}
                  {...input}
                  value={registerData[input.name]}
                  onChange={handleChange}
                  className="main-form"
                />
              </div>
            ))}
          </div>
          {inputs.map((input) => (
            <div>
              <label>{input.label} :</label>
              <FormInput
                key={input.id}
                {...input}
                value={registerData[input.name]}
                onChange={handleChange}
                className="main-form"
              />
            </div>
          ))}
          <div className="pass-confirmPass">
            {passInput.map((input) => (
              <div>
                <label>{input.label} :</label>
                <FormInput
                  key={input.id}
                  {...input}
                  value={registerData[input.name]}
                  onChange={handleChange}
                  className="main-form"
                />
              </div>
            ))}
          </div>
          <StyledLabel>
            <input type="checkbox" required={true} className="larger" />
            <span>Agree to the CyberTV terms of use.</span>
          </StyledLabel>
          <div>
            <input
              type="submit"
              value="Sign Up"
              className="submit"
              disabled={disabled}
              // style={{ backgroundColor: "#e0163c" }}
            />
          </div>
        </CustomAdminForm>
        <ToastContainer />
      </CustomAdminLogin>
    </>
  );
};

export default AdminLogin;
