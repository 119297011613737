import React from 'react';
import { Link, NavLink } from "react-router-dom";
import {Box, Typography} from '@mui/material';
import styled from 'styled-components';
import DotaCoverImg from '../../Assets/Images/radiant_dire.jpg';
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkIcon from "@mui/icons-material/Link";

const StyledCoverImg = styled.img`
    max-width: 100%;
`;

const StyledShadowBox = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 3px 9px 40px 0 #000;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(16, 19, 25, 0), #000);
`;

const StyledTypography = styled(Typography)`
  color: #fff;
`;

const NavUnlisted = styled.ul`
  display: flex;
  gap: 36px;
  li {
    display: flex;
    align-items: center;
    
    a {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 16px 0;
    }
  }
`;

const StyledAnchor = styled.a`
    padding: 4px 8px;
    background-color: #454545;
    color: #f5f5f5;
    border-radius: 3px;
`;

const StyledLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SingleTournament = () => {
  const shareUrl = window.location.href;

  return (
    <Box>
        <Box display="flex" position="relative">
            <StyledCoverImg src={DotaCoverImg} alt="DotaCoverImg" />
            <StyledShadowBox display="flex" justifyContent="center">
                <Box display="flex" justifyContent="center" alignItems="end">
                    <Box display="flex" flexDirection="column" gap="16px" paddingBottom="24px">
                        <Typography variant='h1'>შესარჩევი NBA-ში | europebet Winter Cup Cup Cup</Typography>
                        <Box display="flex" flexDirection="column" gap="8px">
                            <p>Organized by Cyber Group ☑</p>
                            <p>In about 8 hours • Today, 20:00 GMT+4</p>
                        </Box>
                    </Box>
                    <Box display="flex" gap="4px" justifyContent="center" alignItems="center" height="52px">
                        <StyledAnchor href="#">
                            <Typography>Feedback</Typography>
                        </StyledAnchor>
                        <StyledLink
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(
                            `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
                            //   `${JsonData.imgurl + newsData.imgUrl.replace("\\\\", "\\")}`,
                            "_blank"
                            );
                        }}
                        >
                        <FacebookIcon fontSize="large" sx={{ color: "#a8a8a8" }} />
                        </StyledLink>
                        <StyledLink
                        to={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}
                        target="_blank"
                        >
                        <TwitterIcon fontSize="large" sx={{ color: "#a8a8a8" }} />
                        </StyledLink>
                        <StyledLink
                        to={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}
                        target="_blank"
                        >
                        <LinkedInIcon fontSize="large" sx={{ color: "#a8a8a8" }} />
                        </StyledLink>
                        <StyledLink
                        to={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`}
                        target="_blank"
                        >
                        <EmailIcon fontSize="large" sx={{ color: "#a8a8a8" }} />
                        </StyledLink>
                        <StyledLink
                        onClick={() => {
                            navigator.clipboard
                            .writeText(shareUrl)
                            .then(alert("Link copied to clipboard"));
                        }}
                        >
                        <LinkIcon fontSize="large" sx={{ color: "#a8a8a8" }} />
                        </StyledLink>
                    </Box>
                </Box>
            </StyledShadowBox>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" gap="48px" p="24px 0">
            <NavUnlisted>
                <li>
                    <NavLink end style={({ isActive }) => {
                        return {
                        borderBottom: isActive ? "2px solid #fff": "",
                        }
                    }} to="/tournaments/id">
                        <StyledTypography variant="h4">Overview</StyledTypography>
                    </NavLink >
                </li>
                <li>
                    <NavLink end style={({ isActive }) => {
                        return {
                        borderBottom: isActive ? "2px solid #fff": "",
                        }
                    }} to="/tournaments/id/bracket">
                        <StyledTypography variant="h4">Bracket</StyledTypography>
                    </NavLink>
                </li>
                <li>
                    <NavLink end style={({ isActive }) => {
                        return {
                        borderBottom: isActive ? "2px solid #fff": "",
                        }
                    }} to="/tournaments/id/teams">
                        <StyledTypography variant="h4">Teams</StyledTypography>
                    </NavLink>
                </li>
                <li>
                    <NavLink end style={({ isActive }) => {
                        return {
                        borderBottom: isActive ? "2px solid #fff": "",
                        }
                    }} to="/tournaments/id/results">
                        <StyledTypography variant="h4">Results</StyledTypography>
                    </NavLink>
                </li>
                <li>
                    <NavLink end style={({ isActive }) => {
                        return {
                        borderBottom: isActive ? "2px solid #fff": "",
                        }
                    }} to="/tournaments/id/rules">
                        <StyledTypography variant="h4">Rules</StyledTypography>
                    </NavLink>
                </li>
            </NavUnlisted>
        </Box>
    </Box>
  )
}

export default SingleTournament;
