import React, { useState, useEffect } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../Header";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CustomModal from "./ShopModal";
import useAuth from "../../hooks/useAuth";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import JsonData from "../../Data/data.Url.json";
import styled from "styled-components";
import useProductList from "../../hooks/useProductList";
import useAxiosPrivateForm from "../../hooks/useAxiosPrivateForm";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AttachPhotosModal from "./AttachPhotosModal";

const StyledImg = styled.img`
  width: 144px;
  height: 81px;
  object-fit: contain;

  @media only screen and (max-width: 480px) {
    width: 40px;
    height: 24px;
  }
`;

const ShopList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const axiosPrivate = useAxiosPrivate();
  const axiosPrivateForm = useAxiosPrivateForm();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const updateProduct = useProductList()[0];
  const deleteProduct = useProductList()[1];
  const [attachModal, setaAttachModal] = useState(false);
  const [productId, setProductId] = useState();
  // Data
  const [newsData, setNewsData] = useState(null);
  // Modal
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      getProducts();
    }
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
    if (editModal) {
      getProducts();
      setsingleProduct(undefined);
    }
  };
  // End of Modal

  const [singleProduct, setsingleProduct] = useState();
  const [isDataSet, setIsDataSet] = useState(false);

  const [searchState] = useOutletContext();

  const getProducts = async () => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(
        `/Shop/Admin/Products?title=${searchState}`,
        {
          signal: controller.signal,
        }
      );
      setNewsData(response.data);
      // console.log(response.data);
    } catch (err) {
      navigate("/", { state: { from: location }, replace: true });
    }
    return () => {
      controller.abort();
    };
  };

  useEffect(() => {
    // if (!isDataSet) {
    const timeOutId = setTimeout(() => getProducts(), 1500);
    // setIsDataSet(true);
    // console.log("gamarjoba");
    return () => clearTimeout(timeOutId);
    // }
  }, [singleProduct, auth, searchState]);
  // }, [newsData, singleProduct, auth, searchState]);

  const onEditClick = async (e, id) => {
    setsingleProduct(await updateProduct(id));
    toggleEditModal();
  };

  const onDeleteClick = async (e, id) => {
    if (window.confirm("do you want to delete?")) {
      await deleteProduct(id);
    }
    getProducts();
  };

  const columns = [
    {
      field: "imgUrl",
      headerName: "Photo",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <StyledImg
            src={JsonData.imgurl + params.value}
            // src={JsonData.imgurl + params.value.replace("\\\\", "/")}
            alt=""
          />
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "code", headerName: "Code", flex: 1.2 },
    { field: "categoryId", headerName: "Categories Id", flex: 1.2 },
    { field: "brandId", headerName: "Brand Id", flex: 1.2 },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={async (e) => await onEditClick(e, params.row.id)}
            variant="contained"
          >
            <ModeEditOutlinedIcon />
          </Button>
        );
      },
    },
    {
      field: "attachPhotos",
      headerName: "Attach Photos",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              setaAttachModal(true);
              setProductId(params.row.id);
            }}
            variant="contained"
          >
            <AddPhotoAlternateIcon />
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => onDeleteClick(e, params.row.id)}
            variant="contained"
          >
            <DeleteOutlinedIcon />
          </Button>
        );
      },
    },
  ];

  if (newsData != null) {
    return (
      <Box m="20px">
        <Header title="SHOP ITEMS LIST" subtitle="List of Shop Items" />
        <Button
          variant="outlined"
          size="large"
          sx={{ color: "green", backgroundColor: "#2f475d" }}
          onClick={toggleModal}
        >
          Add Shop Item&nbsp;
          <AddCircleOutlineOutlinedIcon fontSize="large" />
        </Button>
        <CustomModal
          apiUrl="Products"
          modal={modal}
          toggleModal={toggleModal}
          method={axiosPrivateForm.post}
        />
        {/* {console.log(productId)} */}
        <AttachPhotosModal
          open={attachModal}
          setOpen={setaAttachModal}
          productId={productId}
        />
        {singleProduct ? (
          <CustomModal
            method={axiosPrivateForm.put}
            apiUrl="Products"
            modal={editModal}
            toggleModal={toggleEditModal}
            product={singleProduct}
          />
        ) : (
          ""
        )}
        <Box
          m="40px 0 0 0"
          height="69vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          {/* checkboxSelection */}
          <DataGrid
            getRowId={(row) => row.id}
            rows={newsData}
            getRowHeight={() => "auto"}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Box>
    );
  }
};

export default ShopList;
