import React, { useState, useEffect } from "react";
import {
  Box,
  Avatar,
  IconButton,
  Modal,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails, useTheme
} from "@mui/material";
import styled from "styled-components";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import JsonData from "../../../Data/data.Url.json";
import OrgMemberList from "./OrgMemberList";
import { EasyToastify, ToastContainer } from "../../../Extentions/EasyToastify";
import { tokens } from "../../../theme";

const StyledAccordion = styled(Accordion)`
  border-radius: 16px;
  padding: 16px;
`;

const StyledMemberBox = styled(Box)`
  position: relative;

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 40px;
    color: #042045;
    background-color: #f44336;
    padding: 3px;
    border-radius: 30px;
    display: flex;
  }
`;

const StyledModalInputBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 640px;
  background-color: #010c20;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 32px;

  @media only screen and (max-width: 480px) {
    padding: 12px;
    width: 320px;
  }

  input {
    padding: 4px 10px;
    border: none;
    border-radius: 4px;

    :focus {
      outline: none;
    }
  }
`;

const SettingsOrgMembers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const axiosPrivate = useAxiosPrivate();

  const [memberList, setMemberList] = useState("");
  const [query, setQuery] = useState("");
  const [renderMembers, setRenderMembers] = useState(false);

  useEffect(() => {
    if (renderMembers && query !== "") {
      const fetchData = async () => {
        const response = await axiosPrivate.get(`User/Get/User/${query}`);
        setMemberList(response.data);
      };
      const timeOutId = setTimeout(() => fetchData(), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [query]);

  const handleTeamInvite = async (id) => {
    if (memberList) {
      await axiosPrivate
        .post(`Organisation/Invite/member?id=${id}`, {})
        .catch((error) => {
          EasyToastify.ToastError("Something went wrong");
        })
        .then((res) => {
          EasyToastify.ToastSuccess("Invitation Sent");
        });
    }
  };

  return (
    <StyledAccordion
      defaultExpanded={true}
      disableGutters={true}
      sx={{
        backgroundColor: "transparent",
        "&:before": { display: "none" },
      }}
      style={{backgroundColor: colors.greenAccent[800]}}
      elevation={0}
      square={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>Remove or add Members:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <OrgMemberList />
        <Box padding="8px">
          <p>Add Members:</p>
          <Box display="flex" gap="16px" paddingTop="12px">
            <IconButton
              onClick={handleOpen}
              sx={{ border: 1, borderColor: "#313b4f" }}
            >
              <PersonAddIcon sx={{ width: 45, height: 45 }} />
            </IconButton>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <StyledModalInputBox>
                <input
                  type="text"
                  value={query}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    setRenderMembers(true);
                  }}
                />
                {memberList
                  ? memberList.map((items, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        padding="4px 16px"
                      >
                        <Box display="flex" alignItems="center" gap="12px">
                          {items.profileImgUrl ? (
                            <Avatar
                              src={
                                JsonData.imgurl +
                                items.profileImgUrl.replace("\\\\", "\\")
                              }
                              alt=""
                            />
                          ) : (
                            <Avatar />
                          )}
                          <p>{items.nickName}</p>
                        </Box>
                        <Button
                          onClick={() => handleTeamInvite(items.id)}
                          variant="contained"
                          color="secondary"
                        >
                          Invite
                        </Button>
                      </Box>
                    ))
                  : ""}
              </StyledModalInputBox>
            </Modal>
          </Box>
          <ToastContainer />
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default SettingsOrgMembers;
