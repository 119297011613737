import React, { useState, useEffect } from "react";
import { Box, Skeleton, useTheme } from "@mui/material";
import styled from "styled-components";
import { tokens } from "../../theme";
import axios from "axios";
import JsonData from "../../Data/data.Url.json";
import useAuth from "../../hooks/useAuth";

const StyledBigIframe = styled.iframe`
  padding: 12px;
  border-radius: 24px;
  width: 960px;
  height: 540px;

  @media only screen and (max-width: 900px) {
    width: 640px;
    height: 360px;
  }
  @media only screen and (max-width: 768px) {
    width: 548.5px;
    height: 308.5px;
  }
  @media only screen and (max-width: 480px) {
    width: 384px;
    height: 216px;
  }
  @media only screen and (max-width: 320px) {
    width: 320px;
    height: 180px;
  }
`;

const StyledBigSkeleton = styled(Skeleton)`
  width: 960px !important;
  height: 540px !important;

  @media only screen and (max-width: 900px) {
    width: 640px !important;
    height: 360px !important;
  }
  @media only screen and (max-width: 768px) {
    width: 548.5px !important;
    height: 308.5px !important;
  }
  @media only screen and (max-width: 480px) {
    width: 384px !important;
    height: 216px !important;
  }
  @media only screen and (max-width: 320px) {
    width: 320px !important;
    height: 180px !important;
  }
`;

const StyledThreeIframe = styled.iframe`
  padding: 10px;
  border-radius: 24px;
  width: 290px;
  height: 163px;

  @media only screen and (max-width: 900px) {
    width: 193.3px;
    height: 108.6px;
  }
  @media only screen and (max-width: 768px) {
    width: 166.9px;
    height: 93.9px;
  }
  @media only screen and (max-width: 480px) {
    width: 112.9px;
    height: 63.5px;
  }
`;

const StyledThreeSkeleton = styled(Skeleton)`
  width: 290px !important;
  height: 163px !important;

  @media only screen and (max-width: 900px) {
    width: 193.3px !important;
    height: 108.6px !important;
  }
  @media only screen and (max-width: 768px) {
    width: 166.9px !important;
    height: 93.9px !important;
  }
  @media only screen and (max-width: 480px) {
    width: 112.9px !important;
    height: 63.5px !important;
  }
`;

const StyledBoxCont = styled(Box)`
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledBoxThreeVideo = styled(Box)`
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

const VideoFeed = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [fourVideoData, setFourVideoData] = useState();

  const { gameTypeId } = useAuth();

  const FilterByGame = () => {
    if(gameTypeId) {
      if(gameTypeId >= 110 ){
        return "&platform=" + gameTypeId;
    }
      return "&gameType=" + gameTypeId;
    } else {
      return "";
    }
  };

  useEffect(() => {
    let fetchData = async () => {
      let response = await axios.get(
        `${JsonData.apiMedia}/Get/LastVideos?videoEmount=4&${FilterByGame()}`
      );
      setFourVideoData(response.data);
    };
    fetchData();
  }, [gameTypeId]);

  return (
    <Box p="40px 0 20px 0">
      <StyledBoxCont
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="40px"
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          {fourVideoData && fourVideoData.length !== 0 ? (
            <StyledBigIframe
              title="Video"
              src={
                "https://www.youtube-nocookie.com/embed/" +
                fourVideoData[0].videoUrl +
                "?autoplay=1&mute=1"
              }
              frameBorder="0"
              allow="fullscreen;"
              style={{backgroundColor: colors.greenAccent[800]}}
            />
          ) : (
            <StyledBigSkeleton variant="rounded" />
          )}
        </Box>
        <StyledBoxThreeVideo
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="25px"
        >
          {fourVideoData && fourVideoData.length !== 0
            ? fourVideoData
                .slice(1, 4)
                .map((infos, index) => (
                  <StyledThreeIframe
                    key={index}
                    title="Video"
                    src={
                      "https://www.youtube-nocookie.com/embed/" + infos.videoUrl
                    }
                    frameBorder="0"
                    allow="fullscreen;"
                    style={{backgroundColor: colors.greenAccent[800]}}
                  />
                ))
            : [...Array(3)].map((e, i) => (
                <StyledThreeSkeleton key={i} variant="rounded" />
              ))}
        </StyledBoxThreeVideo>
      </StyledBoxCont>
    </Box>
  );
};

export default VideoFeed;
