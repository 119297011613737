import React, {useState, useEffect} from 'react';
import Pagination from '@mui/material/Pagination';
import {Box, Skeleton, Typography, useTheme} from '@mui/material';
import styled from 'styled-components';
import { tokens } from '../../theme';
import {Link} from 'react-router-dom';
import axios from "axios";
import JsonData from "../../Data/data.Url.json";
import useAuth from "../../hooks/useAuth";

const StyledThreeBoxCont = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 900px) {
        min-width: 0;
    }
`;

const StyledThreeBox = styled(Box)`
    align-items: start;

    @media only screen and (max-width: 768px) {
        align-items: center;
    }
`;

const StyledImg = styled.img`
    width: 384px;
    height: 216px;
    object-fit: contain;
`;

const StyledThreeLink = styled(Link)`
    color: #FFF;
    min-width: 1332px;
    border-radius: 16px;
    box-shadow: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    padding: 12px;

    @media only screen and (max-width: 768px) {
        min-width: auto;
    }

    &:hover {
        box-shadow: 0 10px 20px rgba(0,0,0,0.20), 0 5px 5px rgba(0,0,0,0.18);
    }
`;

const StyledLink = styled(Link)`
    color: #FFF;
    border-radius: 16px;
    box-shadow: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.10), 0 1px 2px rgba(0,0,0,0.20);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    padding: 16px;

    &:hover {
        box-shadow: 0 10px 20px rgba(0,0,0,0.20), 0 5px 5px rgba(0,0,0,0.18);
    }
`;

const StyledTypography = styled(Typography)`
    text-align: center;
`;

const StyledBoxLines = styled(Box)`
  width: 100%;
  height: 12px;
  border-radius: 10px;
`;

const StyledTitleBox = styled(Box)`
  padding: 30px 0;

  @media only screen and (max-width: 480px) {
    padding: 10px 0;
  }
`;

const StyledP = styled.p`
    max-width: 350px;
`;

const StyledThreeText = styled.p`
    max-width: 928px;
`;

const StyledThreeBoxDetails = styled(Box)`
    flex-direction: row;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const StyledThreeBoxText = styled(Box)`
    @media only screen and (max-width: 768px) {
        justify-content: center;
        align-items: center;
    }
`;

const StyledBox = styled(Box)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 42px;
    padding: 30px 0;

    @media only screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 768px) {
        
    }
    @media only screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 10px 0;
    }
    @media only screen and (max-width: 320px) {
        
    }
`;

export default function NewsPage() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { gameTypeId } = useAuth();

    let [page, setPage] = useState(1);
    let [pageCount, setPageCount] = useState(10);
    const [threeNewsData, setThreeNewsData] = useState();
    const [newsData, setNewsData] = useState();
    
    const handleChange = (e, p) => {
      setPage(p);
    };

    const FilterByGame = () => {
        if(gameTypeId) {
            if(gameTypeId >= 110 ){
                return "&platform=" + gameTypeId;
            }
          return "&gameType=" + gameTypeId;
        } else {
          return "";
        }
    };
    
    useEffect(() => {
        let fetchData = async () => {
            let response = await axios.get(`${JsonData.apiurl}/News/GetLastNews?${FilterByGame()}`);
            setThreeNewsData(response.data);
        };
        if(page === 1) {
            fetchData();
        };
        let fetchAllData = async () => {
            let response = await axios.get(`${JsonData.apiurl}/News/GetNews?pageSize=9&pageNumber=${page + FilterByGame()}`);
            setNewsData(response.data);
            setPageCount(response.headers["page-count"]);
        };
        fetchAllData();
    }, [page, gameTypeId]);

    pageCount = Number(pageCount);

    return (
    <Box>
        {page === 1 ?
            <Box display="flex" justifyContent="center" alignItems="center">
                <StyledThreeBoxCont>
                    <StyledTitleBox display="flex" justifyContent="center" alignItems="center" p="30px 0">
                        <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginRight="20px"></StyledBoxLines>
                        <StyledTypography variant="h3" fontWeight="bold">Top&nbsp;News</StyledTypography>
                        <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginLeft="20px"></StyledBoxLines>
                    </StyledTitleBox>
                    <StyledThreeBox display="flex" flexDirection="column" justifyContent="center" gap="20px">
                        {threeNewsData && threeNewsData.length !== 0 ? (
                            threeNewsData.map((infos, index) => (
                                <StyledThreeLink key={index} to={`${infos.id}`} target="_blank" rel="noopener noreferrer" style={{backgroundColor: colors.greenAccent[800]}}>
                                    <StyledThreeBoxDetails display="flex" gap="20px" justifyContent="start" alignItems="center" p="20px 0">
                                        <StyledImg src={JsonData.imgurl + infos.imgUrl.replace("\\\\", "\\")} alt="" />
                                        <StyledThreeBoxText display="flex" flexDirection="column" gap="15px">
                                            <Typography variant="h5" sx={{color: colors.textColor[100]}}>{infos.title}</Typography>
                                            <StyledThreeText style={{color: colors.textColor[100]}}>{infos.shortDescription}</StyledThreeText>
                                        </StyledThreeBoxText>
                                    </StyledThreeBoxDetails>
                                </StyledThreeLink>
                            ))
                        ) : (
                            [...Array(3)].map((e, i) => 
                            <StyledLink key={i}>
                                <Box display="flex" flexDirection="row" gap="20px" justifyContent="center" alignItems="center" padding="20px 0">
                                    <Skeleton variant="rounded" width={384} height={216} />
                                    <Box display="flex" flexDirection="column" gap="15px">
                                        <Typography variant="h5"><Skeleton width={768} height={40} /></Typography>
                                        <p><Skeleton width={768} height={124} /></p>
                                    </Box>
                                </Box>
                            </StyledLink>
                        )
                        )}
                    </StyledThreeBox>
                </StyledThreeBoxCont>
            </Box>
        : ""
        }
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box>
                <StyledTitleBox display="flex" justifyContent="center" alignItems="center" p="30px 0">
                    <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginRight="20px"></StyledBoxLines>
                    <StyledTypography variant="h3" fontWeight="bold">News</StyledTypography>
                    <StyledBoxLines backgroundColor={colors.greenAccent[800]} marginLeft="20px"></StyledBoxLines>
                </StyledTitleBox>
                <StyledBox>
                    {newsData && newsData.length !== 0 ? (
                        newsData.map((infos, index) => (
                            <StyledLink key={index} to={`${infos.id}`} target="_blank" rel="noopener noreferrer" style={{backgroundColor: colors.greenAccent[800]}}>
                                <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center">
                                    <StyledImg src={JsonData.imgurl + infos.imgUrl.replace("\\\\", "\\")} alt="" />
                                    <Typography variant="h5" sx={{color: colors.textColor[100]}}>{infos.title}</Typography>
                                    <StyledP style={{color: colors.textColor[100]}}>{infos.shortDescription}</StyledP>
                                </Box>
                            </StyledLink>
                        ))
                    ) : (
                        [...Array(9)].map((e, i) => 
                            <StyledLink key={i}>
                                <Box display="flex" flexDirection="column" gap="10px" justifyContent="center" alignItems="center" padding="0 40px">
                                    <Skeleton variant="rounded" width={384} height={216} />
                                    <Typography variant="h5"><Skeleton width={384} /></Typography>
                                    <p><Skeleton width={384} /></p>
                                </Box>
                            </StyledLink>
                    ))
                    }
                </StyledBox>
                <Box justifyContent={"center"} alignItems="center" display={"flex"} sx={{padding: "20px 0"}}>
                    <Pagination
                        size="large"
                        shape="rounded"
                        variant="outlined"
                        count={pageCount}
                        page={page}
                        onChange={handleChange}
                    />
                </Box>
            </Box>
        </Box>
    </Box>
    );
  }
