import React, { useContext } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { ColorModeContext, tokens } from "../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import HomeIcon from "@mui/icons-material/Home";
import styled from 'styled-components';

const StyledContBox = styled(Box)`
  @media only screen and (max-width: 480px) {
    padding: 8px;
  }
`;

const StyledInputBase = styled(InputBase)`
  @media only screen and (max-width: 480px) {
    margin-left: 6px;
  }
`;

const Topbar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const logout = useLogout();

  const HandleSignout = async () => {
    await logout();
    navigate("/");
  };

  const handleThemeChange = () => {
    if(!localStorage.getItem("themeModeKey")) {
      localStorage.setItem("themeModeKey", "light");
      colorMode.toggleColorMode();
    } else if(localStorage.getItem("themeModeKey") === "light") {
      localStorage.setItem("themeModeKey", "dark");
      colorMode.toggleColorMode();
    } else if(localStorage.getItem("themeModeKey") === "dark") {
      localStorage.setItem("themeModeKey", "light");
      colorMode.toggleColorMode();
    }
  }

  return (
    <StyledContBox display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <StyledInputBase 
          sx={{ ml: 2, flex: 1 }} 
          placeholder="Search"
          type="text"
          onChange={(event) => {
            props.setSearchState(event.target.value);
          }}
        />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>
      {/* ICONS */}
      <Box display="flex" gap="15px">
        <IconButton
          onClick={handleThemeChange}
          sx={{
            borderRadius: "5px",
            gap: "5px",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {/* <IconButton
          sx={{
            borderRadius: "5px",
            gap: "5px",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NotificationsOutlinedIcon />
        </IconButton> */}
        {/* <IconButton
          sx={{
            borderRadius: "5px",
            gap: "5px",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            navigate("user/id/settings");
          }}
        >
          <SettingsOutlinedIcon />
        </IconButton> */}
        <IconButton
          onClick={() => {
            navigate("/");
          }}
          sx={{
            borderRadius: "5px",
            gap: "5px",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <HomeIcon />
        </IconButton>
        <Button variant="outlined" color="error" onClick={HandleSignout}>
          Log Out
        </Button>
      </Box>
    </StyledContBox>
  );
};

export default Topbar;
