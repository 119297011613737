import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { mockDataTeam } from "../../Data/mockData.js";
import { Link } from "react-router-dom";
import SponsorsModal from "./SponsorsModal";

const StyledImg = styled.img`
  width: 144px;
  height: 81px;
  object-fit: contain;

  @media only screen and (max-width: 480px) {
    width: 40px;
    height: 24px;
  }
`;
const Sponsors = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usersData, setUsersData] = useState();
  const [modal, setModal] = useState(false);
  const [singleUser, setSingleUser] = useState();
  const [editModal, setEditModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      // getUsers();
    }
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
    if (editModal) {
      // getUsers();
      setSingleUser(undefined);
    }
  };
  const handleEdit = async (e, id) => {
    setSingleUser();
    toggleEditModal();
  };
  const handleDelete = async (e, id) => {
    if (window.confirm("do you want to delete?")) {
      // await deleteUser(id);
    }
    // getUsers();
  };

  const columns = [
    {
      field: "tournamentId",
      headerName: "Tournament-ID",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <StyledImg
            // src={JsonData.imgurl + params.value.replace("\\\\", "/")}
            alt="img"
          />
        );
      },
    },
    {
      field: "title",
      headerName: "Tournament Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "gameType", headerName: "Game Type", flex: 0.3 },
    { field: "shortDescription", headerName: "Short Description", flex: 1.5 },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={async (e) => await handleEdit(e, params.row.id)}
            variant="contained"
          >
            <ModeEditOutlinedIcon />
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => handleDelete(e, params.row.id)}
            variant="contained"
          >
            <DeleteOutlinedIcon />
          </Button>
        );
      },
    },
  ];
  return (
    <Box sx={{}}>
      <div
        style={{
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          // justifyContent: "space-between",
          gap: "20%",
        }}
      >
        <div>
          <Button
            sx={{
              backgroundColor: "green",
              margin: "2px",
              height: "34px",
              color: "white",
            }}
            onClick={toggleModal}
          >
            <AddCircleOutlineOutlinedIcon />
            <h2>Add Sponsor</h2>
          </Button>
          <Button
            sx={{
              backgroundColor: "green",
              margin: "2px",
              height: "34px",
              color: "white",
            }}
            onClick={toggleModal}
          >
            <AddCircleOutlineOutlinedIcon />
            <h2>Create Sponsor</h2>
          </Button>
        </div>
        <h1>List Of Sponsors</h1>
      </div>
      <SponsorsModal
        apiUrl="create/user"
        modal={modal}
        toggleModal={toggleModal}
        // method={axiosPrivate.post}
      />

      {true ? (
        <SponsorsModal
          // method={axiosPrivate.put}
          apiUrl="Update/user"
          modal={editModal}
          toggleModal={toggleEditModal}
          user={singleUser}
        />
      ) : (
        ""
      )}
      <Box
        // m="40px 0 0 0"
        height="47vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {/* checkboxSelection */}
        <DataGrid
          //   getRowId={(row) => row.id}

          headerHeight={35}
          rows={mockDataTeam}
          getRowHeight={() => "auto"}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Sponsors;
