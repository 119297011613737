import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Avatar,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails, useTheme
} from "@mui/material";
import styled from "styled-components";
import useAuth from "../../../hooks/useAuth";
import {
  BsTwitch,
  BsYoutube,
  BsTwitter,
  BsFacebook,
  BsInstagram,
} from "react-icons/bs";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import useAxiosPrivateForm from "../../../hooks/useAxiosPrivateForm";
import JsonData from "../../../Data/data.Url.json";
import UpdateIcon from "@mui/icons-material/Update";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EasyToastify, ToastContainer } from "../../../Extentions/EasyToastify";
import { tokens } from "../../../theme";

const StyledAccordion = styled(Accordion)`
  border-radius: 16px;
  padding: 16px;

  form {
    width: 540px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 12px;
  }

  label {
    color: #b7b7b7;
    font-size: 16px;
  }

  input {
    padding: 8px;
    border-radius: 8px;
    border: none;
    outline: 0;
    width: 100%;
  }
`;

const StyledProfileEditBox = styled(Box)`
  position: relative;

  input[type="file"] {
    display: none;
  }

  label {
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 90px;
    color: #042045;
    background-color: #fff;
    padding: 7px;
    border-radius: 30px;
    display: flex;
  }
`;

const StyledGameTypeBox = styled(Box)`
  select {
    border: none;
    font-family: "Montserrat";

    :focus {
      outline: none;
    }
  }
`;

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const validationSchema = yup.object({
  title: yup
    .string()
    .min(5, "Too small!")
    .max(60, "Too long string")
    .required("Title is required!"),
  gameType: yup.number().required("Game Type is required!"),
  date: yup.date().required("Date is required"),
  shortDescription: yup
    .string()
    .min(5, "Too small!")
    .max(200, "Too long string")
    .required("Short Description is required"),
  description: yup
    .string()
    .min(20, "Too small!")
    .max(4000, "Too long string")
    .required("Description is required"),
  file: yup
    .mixed()
    .nullable()
    .required()
    .test(
      "FILE_SIZE",
      "Upload file is too big.",
      (value) => !value || (value && value.size <= 2048 * 2048)
    )
    .test(
      "FILE_FORMAT",
      "Upload file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
    ),
});

const SettingsTeamUpdate = ({ teamData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useAuth();
  const axiosPrivateForm = useAxiosPrivateForm();
  // Profile picture upload
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  //

  const [registerData, setRegisterData] = useState({
    imgUrl: null,
    about: "",
    country: "",
    name: "",
    gameId: "",
    facebookUrl: "",
    instagramUrl: "",
    twitterUrl: "",
    youtubeUrl: "",
    twitchUrl: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("imgUrl", selectedFile ? selectedFile : null);
    formData.append("about", registerData.about);
    formData.append("country", registerData.country);
    formData.append("name", registerData.name);
    formData.append("gameId", registerData.gameId);
    formData.append("facebookUrl", registerData.facebookUrl);
    formData.append("instagramUrl", registerData.instagramUrl);
    formData.append("twitterUrl", registerData.twitterUrl);
    formData.append("youtubeUrl", registerData.youtubeUrl);
    formData.append("twitchUrl", registerData.twitchUrl);
    // console.log(registerData);
    await axiosPrivateForm
      .put("Team/Update", formData)
      .then((res) => {
        // console.log(registerData);
        setRegisterData({
          imgUrl: null,
          about: "",
          country: "",
          name: "",
          gameId: "",
          facebookUrl: "",
          instagramUrl: "",
          twitterUrl: "",
          youtubeUrl: "",
          twitchUrl: "",
        });
        EasyToastify.ToastSuccess("Updated Successfully");
      })
      .catch((error) => {
        EasyToastify.ToastError("Oop Something wrong!");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  return (
    <StyledAccordion
      defaultExpanded={false}
      disableGutters={true}
      sx={{
        backgroundColor: "transparent",
        "&:before": { display: "none" },
      }}
      style={{backgroundColor: colors.greenAccent[800]}}
      elevation={0}
      square={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Update Team Details:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap="8px">
            <label>Profile Picture</label>
            <StyledProfileEditBox>
              {teamData.length !== 0 ? (
                preview ? (
                  selectedFile && (
                    <Avatar
                      src={preview}
                      alt={auth.auth.name}
                      sx={{ width: 120, height: 120 }}
                    />
                  )
                ) : teamData.imgUrl ? (
                  <Avatar
                    src={
                      JsonData.imgurl + teamData.imgUrl.replace("\\\\", "\\")
                    }
                    alt={auth.auth.name}
                    sx={{ width: 120, height: 120 }}
                  />
                ) : (
                  <Avatar
                    alt={auth.auth.name}
                    sx={{ width: 120, height: 120 }}
                  />
                )
              ) : (
                <Avatar alt={auth.auth.name} sx={{ width: 120, height: 120 }} />
              )}
              <label htmlFor="file-upload">
                <AddAPhotoIcon />
              </label>
              <input
                id="file-upload"
                type="file"
                accept="image/png, image/jpeg"
                onChange={onSelectFile}
                style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
              />
            </StyledProfileEditBox>
            <p>Recommend image ratio is 1:1</p>
          </Box>
          <StyledGameTypeBox display="flex" flexDirection="column">
            <label>Game Type:</label>
            {teamData.length !== 0 ? (
              <select
                name="gameId"
                defaultValue={teamData.gameId}
                onChange={handleChange}
                style={{
                  width: "100%",
                  borderRadius: "6px",
                  padding: "4px 6px",
                  color: colors.textColor[100],
                  backgroundColor: colors.greenAccent[600]
                }}
              >
                <option value={1}>Dota 2</option>
                <option value={2}>CS:GO</option>
                <option value={3}>PUBG</option>
                <option value={4}>League of Legends</option>
              </select>
            ) : (
              ""
            )}
          </StyledGameTypeBox>
          <Box display="flex" flexDirection="column">
            <label>Team Name</label>
            {teamData.length !== 0 ? (
              <input
                type="text"
                onChange={handleChange}
                name="name"
                defaultValue={teamData.name}
                style={{
                  width: "100%",
                  borderRadius: "6px",
                  padding: "4px 8px",
                  color: colors.textColor[100],
                  backgroundColor: colors.greenAccent[600]
                }}
              />
            ) : (
              ""
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            <label>Nationality</label>
            {teamData.length !== 0 ? (
              <input
                type="text"
                onChange={handleChange}
                name="country"
                defaultValue={teamData.country}
                style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
              />
            ) : (
              ""
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            <label>Bio &#40;not required&#41;</label>
            {teamData.length !== 0 ? (
              <input
                type="text"
                onChange={handleChange}
                name="about"
                defaultValue={teamData.about}
                placeholder="aq unda iyos biogragia"
                style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
              />
            ) : (
              ""
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <label>Socials &#40;not required&#41;</label>
            <Box display="flex" alignItems="center" gap="8px">
              <BsTwitch fontSize={24} />
              {teamData.length !== 0 ? (
                <input
                  type="text"
                  onChange={handleChange}
                  name="twitchUrl"
                  defaultValue={teamData.twitchUrl}
                  placeholder="https://www.twitch.tv/..."
                  style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
                />
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              <BsYoutube fontSize={24} />
              {teamData.length !== 0 ? (
                <input
                  type="text"
                  onChange={handleChange}
                  name="youtubeUrl"
                  defaultValue={teamData.youtubeUrl}
                  placeholder="https://www.youtube.com/..."
                  style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
                />
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              <BsTwitter fontSize={24} />
              {teamData.length !== 0 ? (
                <input
                  type="text"
                  onChange={handleChange}
                  name="twitterUrl"
                  defaultValue={teamData.twitterUrl}
                  placeholder="https://www.twitter.com/..."
                  style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
                />
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              <BsFacebook fontSize={24} />
              {teamData.length !== 0 ? (
                <input
                  type="text"
                  onChange={handleChange}
                  name="facebookUrl"
                  defaultValue={teamData.facebookUrl}
                  placeholder="https://www.facebook.com/..."
                  style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
                />
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              <BsInstagram fontSize={24} />
              {teamData.length !== 0 ? (
                <input
                  type="text"
                  onChange={handleChange}
                  name="instagramUrl"
                  defaultValue={teamData.instagramUrl}
                  placeholder="https://www.instagram.com/..."
                  style={{color: colors.textColor[100], backgroundColor: colors.greenAccent[600]}}
                />
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Button type="submit" variant="contained" sx={{backgroundColor: "#155683"}}>
            <span style={{ paddingRight: "6px" }}>Update</span>
            <UpdateIcon />
          </Button>
        </form>
        <ToastContainer />
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default SettingsTeamUpdate;
