import React from 'react';
import {Avatar, Box, Typography} from '@mui/material';
import styled from 'styled-components';
import GroupsIcon from '@mui/icons-material/Groups';

const StyledBox = styled(Box)`
  :hover {
    background-color: #2c3547;
  }
`;

const TournamentTeams = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" paddingBottom="24px">
      <Box display="flex" flexDirection="column" gap="24px" maxWidth="60vw">
        <Box display="flex" flexDirection="column">
          <Typography variant="h4" sx={{paddingBottom: "16px"}}>Confirmed</Typography>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="h4" sx={{paddingBottom: "16px"}}>Not Confirmed</Typography>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
          <StyledBox display="flex" alignItems="center" gap="10px" padding="8px" borderTop="1px solid #313b4f" width="1200px">
            <Avatar>
              <GroupsIcon fontSize="large" />
            </Avatar>
            <Typography variant="h6">Protos</Typography>
          </StyledBox>
        </Box>
      </Box>
    </Box>
  )
}

export default TournamentTeams;
