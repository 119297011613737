import axios from "axios";
import useAuth from "./useAuth";
import JsonData from "../Data/data.Url.json";

function useRefreshToken() {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios
      .get(`${JsonData.apiurl}/Authentification/RefreshToken`, {
        withCredentials: true,
      })
      .catch((err) => {
        if (response.status != 401) {
          console.error(err);
          localStorage.clear();
          sessionStorage.clear();
        }
      });

    setAuth((prev) => {
      return {
        ...prev,
        userId: response.data.userId,
        teamId: response.data.teamId,
        organisationId: response.data.organisationId,
        roles: [response.data.roleId],
        accesToken: response.headers.token,
      };
    });
    return response.headers.token;
  };
  return refresh;
}

export default useRefreshToken;
