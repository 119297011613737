import React from 'react';
import { Typography, Box } from '@mui/material';

const PageNotFound = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="75vh">
      <Typography variant="h2">Page Not Found</Typography>
    </Box>
  )
};

export default PageNotFound;