import axios from "axios";
import JsonData from "../Data/data.Url.json";
export const axiosPrivate = axios.create({
  baseURL: JsonData.apiurl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPrivateForm = axios.create({
  baseURL: JsonData.apiurl,
  withCredentials: true,
});
