import React, { useState, useEffect } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "./Header";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CustomModal from "./NewsModal";
import useAuth from "../hooks/useAuth";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import JsonData from "../Data/data.Url.json";
import styled from "styled-components";
import useNewsList from "../hooks/useNewList";
import useAxiosPrivateForm from "../hooks/useAxiosPrivateForm";

const StyledImg = styled.img`
  width: 144px;
  height: 81px;
  object-fit: contain;

  @media only screen and (max-width: 480px) {
    width: 40px;
    height: 24px;
  }
`;

const NewsList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const axiosPrivateForm = useAxiosPrivateForm();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const updateNews = useNewsList()[0];
  const deleteNews = useNewsList()[1];
  // Data
  const [newsData, setNewsData] = useState(null);

  // Modal
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      getNews();
    }
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
    if (editModal) {
      getNews();
      setSingleNews(undefined);
    }
  };
  // End of Modal

  const [singleNews, setSingleNews] = useState();
  // console.log(singleNews)

  const [isDataSet, setIsDataSet] = useState(false);

  const getNews = async () => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(
        "/NewsMaker/GetNewsForNewsMaker",
        {
          signal: controller.signal,
        }
      );
      setNewsData(response.data);
    } catch (err) {
      navigate("/", { state: { from: location }, replace: true });
    }
    return () => {
      controller.abort();
    };
  };

  useEffect(() => {
    if (!isDataSet) {
      getNews();
      setIsDataSet(true);
    }
  }, [newsData, singleNews, auth]);

  const onEditClick = async (e, id) => {
    setSingleNews(await updateNews(id));
    toggleEditModal();
  };

  const onDeleteClick = async (e, id) => {
    if (window.confirm("do you want to delete?")) {
      await deleteNews(id);
    }
    getNews();
  };

  const columns = [
    {
      field: "imgUrl",
      headerName: "Photo",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <StyledImg
            src={JsonData.imgurl + params.value.replace("\\\\", "/")}
            alt=""
          />
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "gameId", headerName: "Game Type", flex: 0.3 },
    { field: "shortDescription", headerName: "Short Description", flex: 1.5 },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={async (e) => await onEditClick(e, params.row.id)}
            variant="contained"
          >
            <ModeEditOutlinedIcon />
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => onDeleteClick(e, params.row.id)}
            variant="contained"
          >
            <DeleteOutlinedIcon />
          </Button>
        );
      },
    },
  ];

  if (newsData != null) {
    return (
      <Box m="20px">
        <Header title="NEWS LIST" subtitle="List of News" />
        <Button
          variant="outlined"
          size="large"
          sx={{ color: "green", backgroundColor: "#2f475d" }}
          onClick={toggleModal}
        >
          Add News&nbsp;
          <AddCircleOutlineOutlinedIcon fontSize="large" />
        </Button>
        <CustomModal
          apiUrl="Create/News"
          modal={modal}
          toggleModal={toggleModal}
          method={axiosPrivateForm.post}
        />
        {singleNews ? (
          <CustomModal
            method={axiosPrivateForm.put}
            apiUrl="Update/News"
            modal={editModal}
            toggleModal={toggleEditModal}
            news={singleNews}
          />
        ) : (
          ""
        )}
        <Box
          m="40px 0 0 0"
          height="69vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          {/* checkboxSelection */}
          <DataGrid
            getRowId={(row) => row.id}
            rows={newsData}
            getRowHeight={() => "auto"}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Box>
    );
  }
};

export default NewsList;
