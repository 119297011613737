import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar, Skeleton, useTheme } from "@mui/material";
import styled from "styled-components";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import { RiGameLine } from "react-icons/ri";
import { ToastContainer, toast, Flip } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import JsonData from "../../Data/data.Url.json";
import { Link, NavLink } from "react-router-dom";
import GroupsIcon from '@mui/icons-material/Groups';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { axiosPrivate } from "../../api/axios";
import { tokens } from "../../theme";

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 48px;
  padding: 32px 0;
`;

const StyledBoxCont = styled(Box)`
  @media only screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledBoxWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 300px;
  border-radius: 16px;
  padding: 16px;
`;

const StyledTypography = styled(Typography)`
  cursor: pointer;
  border: 1px;
  border-radius: 7px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 8px;

  :hover {
    background-color: #155683;
  }
`;

const StyledArrowLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const StyledLink = styled(Link)`
  
`;

const StyledTitleTypography = styled(Typography)`
  color: #b7b7b7;
`;

const StyledSettingsBox = styled(Box)`
  a {
    border: 1px;
    border-radius: 7px;
  }
`;

const SettingsHeader = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useAuth();
  const [userData, setUserData] = useState();

  useEffect(() => {
      const fetchData = async () => {
        const response = await axiosPrivate.get("User/Get/UserProfileImage");
        setUserData(response.data);
      }
      fetchData();
  }, []);

  return (
    <StyledBox>
      <StyledBoxCont>
        <StyledBoxWrap style={{backgroundColor: colors.greenAccent[800]}}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="8px"
          >
            <Link to={`/user/${auth?.auth.userId}`}>
              {userData ? (
                userData.profileImg != null ? (
                  <Avatar
                    src={JsonData.imgurl + userData.profileImg.replace("\\\\", "\\")}
                    alt="User Nickname"
                    sx={{ width: 120, height: 120 }}
                  />
                ) : (
                  <Avatar sx={{ width: 120, height: 120 }} />
                )
              ) : (
                <Skeleton variant="circular" width={120} height={120} />
              )}
              {/* <Avatar
                src={
                  JsonData.imgurl + `wwwroot/Resources/Users/${auth.auth.userId}/ProfileImage.jpg`
                }
                alt="User Nickname"
                sx={{ width: 120, height: 120 }}
              /> */}
            </Link>
            <StyledLink to={`/user/${auth?.auth.userId}`} style={{color: colors.textColor[100]}}>
              <Typography variant="h3">{auth?.auth.nickName}</Typography>
            </StyledLink>
            <StyledArrowLink
              to={`/user/${auth?.auth.userId}`}
              style={{color: colors.textColor[100]}}
            >
              <KeyboardBackspaceIcon />
              Back to Profile
            </StyledArrowLink>
          </Box>
          <StyledSettingsBox display="flex" flexDirection="column" gap="12px" p="18px 0 0 0">
            <StyledTitleTypography>User Settings</StyledTitleTypography>
            <NavLink end to={`/user/${auth?.auth.userId}/settings`} style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#155683": "",
                }
              }}>
              <StyledTypography variant="h4" style={{color: colors.textColor[100]}}>
                <PermIdentityOutlinedIcon />
                Account
              </StyledTypography>
            </NavLink>
            <NavLink end to={`/user/${auth?.auth.userId}/settings/profile`} style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#155683": "",
                }
              }}>
              <StyledTypography variant="h4" style={{color: colors.textColor[100]}}>
                <DriveFileRenameOutlineOutlinedIcon />
                Profile
              </StyledTypography>
            </NavLink>
            {/* <NavLink end to={`/user/${auth?.auth.userId}/settings/verifyaccount`} style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#155683": "",
                }
              }}>
              <StyledTypography variant="h4" style={{color: colors.textColor[100]}}>
                <VerifiedIcon />
                Verify Account
              </StyledTypography>
            </NavLink> */}
            <StyledTitleTypography>Login</StyledTitleTypography>
            <NavLink end to={`/user/${auth?.auth.userId}/settings/password`} style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#155683": "",
                }
              }}>
              <StyledTypography variant="h4" style={{color: colors.textColor[100]}}>
                <HttpsOutlinedIcon />
                Password
              </StyledTypography>
            </NavLink>
            {/* <StyledTitleTypography>Games</StyledTitleTypography>
            <NavLink end to={`/user/${auth?.auth.userId}/settings/gameaccounts`} style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#155683": "",
                }
              }}>
              <StyledTypography variant="h4" style={{color: colors.textColor[100]}}>
                <RiGameLine />
                Game Accounts
              </StyledTypography>
            </NavLink> */}
            <StyledTitleTypography>Organization</StyledTitleTypography>
            <NavLink end to={`/user/${auth?.auth.userId}/settings/team`} style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#155683": "",
                }
              }}>
              <StyledTypography variant="h4" style={{color: colors.textColor[100]}}>
                <GroupsIcon />
                Team
              </StyledTypography>
            </NavLink>
            <NavLink end to={`/user/${auth?.auth.userId}/settings/organization`} style={({ isActive }) => {
                return {
                  backgroundColor: isActive ? "#155683": "",
                }
              }}>
              <StyledTypography variant="h4" style={{color: colors.textColor[100]}}>
                <Diversity3Icon />
                Organization
              </StyledTypography>
            </NavLink>
          </StyledSettingsBox>
        </StyledBoxWrap>
      </StyledBoxCont>
      {/* <ToastContainer /> */}
    </StyledBox>
  );
};

export default SettingsHeader;
