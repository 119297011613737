import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, Button, Dialog, Slide } from '@mui/material';
import styled from 'styled-components';
import { tokens } from "../../theme";
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ProductPhotoSlider from './ProductPhotoSlider';
import { useParams } from "react-router-dom";
import axios from 'axios';
import JsonData from "../../Data/data.Url.json";
import { GiTwoCoins } from 'react-icons/gi';

const StyledDiv = styled.div`
    display: flex;
    gap: 32px;
    flex-direction: row;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const StyledP = styled.p`
    font-size: 16px;
    font-weight: bold;

    @media only screen and (max-width: 1024px) {
        font-size: 12px;
        text-align: center;
    }
`;

const StyledIframe = styled.iframe`
    width: 600px;
    height: 250px;

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SingleProduct = () => {
    const { id } = useParams();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };

    const [productData, setProductData] = useState(null);

    useEffect(() => {
        const getSingleProductData = async () => {
            const response = await axios.get(
                `${JsonData.apiurl}/Shop/Products/${id}`
            );
            setProductData(response.data);
            console.log(response.data);
        };
        getSingleProductData();
    }, []);

    if (productData) {
        return (
            <Box display="flex" flexDirection="column" gap="24px" padding="40px">
                <Typography>{"/ " + productData.category + " / " + productData.title}</Typography>
                <StyledDiv>
                    <Box display="flex" position="relative">
                        <Box display="flex" flexDirection="column" alignItems="center" gap="8px" position="absolute" zIndex={2}>
                            {productData.bestSeller === false ? "" : <Typography backgroundColor={colors.greenAccent[800]} sx={{padding: "2px 8px", borderRadius: "8px"}}>BESTSELLER</Typography>}
                            {productData.new === false ? "" : <Typography backgroundColor={colors.greenAccent[800]} sx={{padding: "2px 8px", borderRadius: "8px"}}>NEW</Typography>}
                            {productData.inStock === false ? "" : <Typography backgroundColor={colors.greenAccent[800]} sx={{padding: "2px 8px", borderRadius: "8px"}}>IN STOCK</Typography>}
                            {productData.used === false ? "" : <Typography backgroundColor={colors.greenAccent[800]} sx={{padding: "2px 8px", borderRadius: "8px"}}>USED</Typography>}
                        </Box>
                        {/* თუ multiplePhotoData და videoUrl = null-ს მაშინ გამომიტანოს მხოლოდ ერთი ფოტო, თუ არადა ფოტო სლაიდერი */}
                        <Box>
                            <ProductPhotoSlider mainImage={productData.imgUrl} />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="12px">
                        <Box display="flex" flexDirection="column" gap="8px" className="secondChild">
                            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{productData.title}</Typography>
                            <Typography>{productData.brand}</Typography>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{productData.price}&nbsp;₾</Typography>
                                <Box display="flex" alignItems="center" gap="4px">
                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{productData.cyberPrice}</Typography>
                                    <GiTwoCoins fontSize={20} />
                                </Box>
                            </Box>
                        </Box>
                        <Typography sx={{ maxWidth: "328px" }}>
                            {productData.description}
                        </Typography>
                        <Button onClick={handleClickOpen} color="success" sx={{ backgroundColor: "#155683" }}>Buy</Button>
                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            PaperProps={{
                                style: {
                                    backgroundColor: '#155683',
                                    borderRadius: "16px",
                                },
                            }}
                        >
                            <Box display="flex" flexDirection="column" position="relative" padding="32px 42px" gap="16px">
                                <Button onClick={handleClose} sx={{ position: "absolute", right: "4px", top: "4px" }}><CloseIcon /></Button>
                                <Box display="flex" justifyContent="space-evenly">
                                    <a href="tel:+995-599-19-66-33" style={{ display: "flex", gap: "4px", alignItems: "center", color: "#fff" }}>
                                        <PhoneIcon />
                                        <StyledP>+995 599 19 66 33</StyledP>
                                    </a>
                                    <a href="https://goo.gl/maps/c65BUpCKSgoPyQLJ7" target="_blank" rel="noopener noreferrer" style={{ display: "flex", gap: "4px", alignItems: "center", color: "#fff" }}>
                                        <LocationOnIcon />
                                        <StyledP>Tbilisi, 45 Vazha Pshavela Ave</StyledP>
                                    </a>
                                </Box>
                                <Box display="flex">
                                    <StyledIframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2977.7460331336133!2d44.742688870433476!3d41.72599879753892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404473f9d0231389%3A0x645c79698c2727ee!2sCyber%20Arena!5e0!3m2!1sen!2sge!4v1674507097468!5m2!1sen!2sge" style={{ border: 0, borderRadius: "16px" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="cyber-map">
                                    </StyledIframe>
                                </Box>
                            </Box>
                        </Dialog>
                    </Box>
                </StyledDiv>
            </Box>
        )
    }
}

export default SingleProduct;
