import React from 'react';
import { Outlet } from 'react-router-dom';
import SettingsHeader from './SettingsHeader';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0;
  gap: 48px;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }
`;

const SettingsLayout = () => {
  return (
    <StyledDiv>
        <SettingsHeader />
        <Outlet />
    </StyledDiv>
  )
}

export default SettingsLayout;
