import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';
import {tokens} from "../theme";
import ProgressCircle from './ProgressCircle';
import styled from 'styled-components';

const StyledBox = styled(Box)`
    @media only screen and (max-width: 480px) {
        flex-direction: column;
    }

    h4, h5 {
        @media only screen and (max-width: 480px) {
            font-size: 12px;
        }
    }
`;

const StatBox = ({title, subtitle, icon, progress, increase}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px">
        <StyledBox display="flex" justifyContent="space-between">
            <Box>
                {icon}
                <Typography variant='h4' fontWeight="bold" sx={{color: colors.grey[100]}}>
                    {title}
                </Typography>
            </Box>
            <Box>
                <ProgressCircle progress={progress} />
            </Box>
        </StyledBox>
        <StyledBox display="flex" justifyContent="space-between">
            <Typography variant='h5' sx={{color: colors.greenAccent[100]}}>
                {subtitle}
            </Typography>
            <Typography variant='h5' fontStyle="italic" sx={{color: colors.greenAccent[600]}}>
                {increase}
            </Typography>
        </StyledBox>
    </Box>
  );
};

export default StatBox;