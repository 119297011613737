import React from 'react';
import {Box, Typography} from '@mui/material';
import styled from 'styled-components';
import {SiCounterstrike} from 'react-icons/si';
import {TiGroup} from 'react-icons/ti';
import {TbCell, TbWallet} from 'react-icons/tb';
import {FaRegCalendarCheck, FaCalendarDay} from 'react-icons/fa';

const StyledGridBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

const StyledGridBoxItems = styled(Box)`
  padding: 15px;
  border: 1px solid #313b4f;
  border-radius: 10px;

  :hover {
    background-color: #2c3547;
  }
`;

const StyledTitleTypography = styled(Typography)`
  font-weight: bold;
`;

const StyledParagraph = styled.p`
  font-size: 11px;
  color: #97949c;
`;

const StyledGridBoxPrizes = styled(Box)`
  border: 1px solid #313b4f;
  border-radius: 10px;

  div {
    :hover {
      background-color: #2c3547;
    }
  }
`;

const TournamentOverview = () => {
  return (
    <Box display="flex" justifyContent="center" gap="32px" paddingBottom="32px">
      <Box display="flex" flexDirection="column" maxWidth="880px" gap="20px">
        <Box display="flex" flexDirection="column" gap="18px">
          <StyledTitleTypography variant="h3">Details</StyledTitleTypography>
          <StyledGridBox>
            <StyledGridBoxItems display="flex" alignItems="center" gap="12px">
              <SiCounterstrike fontSize={28} />
              <Box>
                <StyledParagraph>Game</StyledParagraph>
                <p>Dota 2</p>
              </Box>
            </StyledGridBoxItems>
            <StyledGridBoxItems display="flex" alignItems="center" gap="12px">
              <FaCalendarDay fontSize={28} />
              <Box>
                <StyledParagraph>Start Time:</StyledParagraph>
                <p>Thu 30 Jan 2022, 18:00 GMT+4</p>
              </Box>
            </StyledGridBoxItems>
            <StyledGridBoxItems display="flex" alignItems="center" gap="12px">
              <FaRegCalendarCheck fontSize={28} />
              <Box>
                <StyledParagraph>Check in Time:</StyledParagraph>
                <p>Thu 30 Jan 2022, 17:30 GMT+4</p>
              </Box>
            </StyledGridBoxItems>
            <StyledGridBoxItems display="flex" alignItems="center" gap="12px">
              <TiGroup fontSize={28} />
              <Box>
                <StyledParagraph>Team size</StyledParagraph>
                <p>5vs5 + 2 substitutes</p>
              </Box>
            </StyledGridBoxItems>
            <StyledGridBoxItems display="flex" alignItems="center" gap="12px">
              <TbWallet fontSize={28} />
              <Box>
                <StyledParagraph>Entry fee</StyledParagraph>
                <p>Free to enter</p>
              </Box>
            </StyledGridBoxItems>
            <StyledGridBoxItems display="flex" alignItems="center" gap="12px">
              <TbCell fontSize={28} />
              <Box>
                <StyledParagraph>Format</StyledParagraph>
                <p>Single Elimination</p>
                <p>Double Elimination</p>
              </Box>
            </StyledGridBoxItems>
          </StyledGridBox>
        </Box>
        <Box display="flex" flexDirection="column" gap="18px">
          <StyledTitleTypography variant="h3">Information</StyledTitleTypography>
          <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            Why do we use it?
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            Where does it come from?
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
          </p>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="48px">
        <Box display="flex" flexDirection="column" gap="18px">
          <StyledTitleTypography variant="h3">Tournament Prizes</StyledTitleTypography>
          <StyledGridBoxPrizes display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" padding="10px 18px" sx={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
              <Typography variant="h5">Prize Pool: </Typography>
              <Typography variant="h5">20000₾</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" padding="10px 18px" borderTop="1px solid #313b4f">
              <Typography variant="h5">1st Place: </Typography>
              <Typography variant="h5">10000₾</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" padding="10px 18px" borderTop="1px solid #313b4f">
              <Typography variant="h5">2nd Place: </Typography>
              <Typography variant="h5">6000₾</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" padding="10px 18px" sx={{borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}} borderTop="1px solid #313b4f">
              <Typography variant="h5">3th Place: </Typography>
              <Typography variant="h5">4000₾</Typography>
            </Box>
          </StyledGridBoxPrizes>
        </Box>
        {/* <Box display="flex" flexDirection="column" gap="18px">
          <StyledTitleTypography variant="h3">Placement</StyledTitleTypography>
        </Box> */}
        <Box display="flex" flexDirection="column" gap="18px">
          <StyledTitleTypography variant="h3">Sponsors</StyledTitleTypography>
          <StyledGridBox>
            <StyledGridBoxItems display="flex" flexDirection="column" justifyContent="center" width="160px" height="100px">
              <img src="https://europop.ge/themes/custom/omedia/images/logo-europop.svg" alt="europop" />
            </StyledGridBoxItems>
            <StyledGridBoxItems display="flex" flexDirection="column" justifyContent="center" width="160px" height="100px">
              <img src="https://europop.ge/themes/custom/omedia/images/logo-europop.svg" alt="europop" />
            </StyledGridBoxItems>
            <StyledGridBoxItems display="flex" flexDirection="column" justifyContent="center" width="160px" height="100px">
              <img src="https://europop.ge/themes/custom/omedia/images/logo-europop.svg" alt="europop" />
            </StyledGridBoxItems>
            <StyledGridBoxItems display="flex" flexDirection="column" justifyContent="center" width="160px" height="100px">
              <img src="https://europop.ge/themes/custom/omedia/images/logo-europop.svg" alt="europop" />
            </StyledGridBoxItems>
            <StyledGridBoxItems display="flex" flexDirection="column" justifyContent="center" width="160px" height="100px">
              <img src="https://europop.ge/themes/custom/omedia/images/logo-europop.svg" alt="europop" />
            </StyledGridBoxItems>
          </StyledGridBox>
        </Box>
      </Box>
    </Box>
  )
}

export default TournamentOverview;
