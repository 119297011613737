import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import PreviewImage from "../PreviewImage";
import styled from "styled-components";
import { Box, Button, Checkbox } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EasyToastify } from "../../Extentions/EasyToastify";
import JsonData from "../../Data/data.Url.json";
import CategoriesId from "./CategoriesId";
import BrandId from "./BrandId";

const StyledModal = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
`;

const StyledOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(49, 49, 49, 0.8);
`;

const StyledModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2f475d;
  padding: 24px 28px;
  border-radius: 20px;
  width: 700px;

  @media only screen and (max-width: 480px) {
    width: 380px;
  }
`;

const StyledRedBox = styled(Box)`
  color: red;
`;

const StyledImg = styled.img`
  width: 144px;
  height: 81px;
  object-fit: contain;

  @media only screen and (max-width: 480px) {
    width: 40px;
    height: 24px;
  }
`;

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const validationSchema = yup.object({
  title: yup
    .string()
    .min(5, "Too small!")
    .max(60, "Too long string")
    .required("Title is required!"),
  // gameId: yup.number().required("Game Type is required!"),
  date: yup.date().required("Date is required"),
  shortDescription: yup
    .string()
    .min(5, "Too small!")
    .max(300, "Too long string")
    .required("Short Description is required"),
  description: yup
    .string()
    .min(20, "Too small!")
    .max(4000, "Too long string")
    .required("Description is required"),
  // videoUrl: yup
  //   .string()
  //   .nullable()
  //   .min(0)
  //   .max(40, "Too long string"),
  file: yup
    .mixed()
    .nullable()
    .required()
    .test(
      "FILE_SIZE",
      "Upload file is too big.",
      (value) => !value || (value && value.size <= 2048 * 2048)
    )
    .test(
      "FILE_FORMAT",
      "Upload file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
    ),
});

export default function ShopModal(props) {
  const fileRef = useRef(null);

  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg });
  };
  const handleOnSubmit = async (values, actions) => {
    let videoUrl = values.videoUrl.split("/").pop();
    const formData = new FormData();

    if (values.id !== "") {
      formData.append("id", values.id);
    }
    formData.append("title", values.title);
    formData.append("code", values.code);
    formData.append("categoryId", selectCategoriesId);
    formData.append("brandId", selectBrandId);
    formData.append("bestSeller", bestSellerCheck);
    formData.append("inStock", inStockCheck);
    formData.append("used", usedCheck);
    formData.append("new", newCheck);
    formData.append("price", Number(values.price));
    formData.append("cyberPrice", Number(values.cyberPrice));
    formData.append("description", values.description);
    formData.append("videoUrl", videoUrl);
    formData.append("imgUrl", values.file);

    await props
      .method(`/Shop/${props.apiUrl}`, formData)
      .then((response) => {
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, "");
        EasyToastify.ToastSuccess("Product Added");
        props.toggleModal();
      })
      .catch((error) => {
        console.error(error);
        actions.setSubmitting(false);
        handleServerResponse(false, error.response.data.error);
        EasyToastify.ToastError("Error");
      });
  };

  const [product] = useState(
    props.product
      ? props.product
      : {
          id: "",
          title: "",
          code: "",
          categoriesId: 1,
          brandId: 1,
          bestSeller: false,
          inStock: true,
          used: false,
          new: true,
          price: "",
          cyberPrice: "",
          description: "",
          videoUrl: "",
          file: null,
        }
  );

  // Id Types
  const [selectCategoriesId, setSelectCategoriesId] = useState(
    props.product ? props.product.categoriesId : 1
  );
  const handleCategoriesChange = (e) => {
    setSelectCategoriesId(e.target.value);
  };

  const [selectBrandId, setSelectBrandId] = useState(
    props.product ? props.product.brandId : 1
  );
  const handleBrandChange = (e) => {
    setSelectBrandId(e.target.value);
  };

  // Bools
  const [bestSellerCheck, setBestSellerCheck] = useState(false);
  const handleBestSeller = (e) => {
    setBestSellerCheck(!bestSellerCheck);
  };

  const [newCheck, setNewCheck] = useState(true);
  const handleNew = (e) => {
    setNewCheck(!newCheck);
  };

  const [usedCheck, setUsedCheck] = useState(false);
  const handleUsed = (e) => {
    setUsedCheck(!usedCheck);
  };

  const [inStockCheck, setInStockCheck] = useState(true);
  const handleInStock = (e) => {
    setInStockCheck(!inStockCheck);
  };

  return (
    <Box>
      {props.modal && (
        <StyledModal>
          <StyledOverlay onClick={props.toggleModal}></StyledOverlay>
          <StyledModalContent>
            <Formik
              // validateOnMount={true}
              enableReinitialize={true}
              initialValues={product}
              onSubmit={handleOnSubmit}
              // validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Box display="flex" flexDirection="column">
                    <label>Title: </label>
                    <Field
                      name="title"
                      type="text"
                      placeholder={`Enter Title...`}
                    />
                    <StyledRedBox>
                      <ErrorMessage name="title" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <label>Product Code: </label>
                    <Field
                      name="code"
                      type="text"
                      placeholder={`Enter Product Code...`}
                    />
                    <StyledRedBox>
                      <ErrorMessage name="code" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <label>Category Type:</label>
                    <select
                      name="categoriesId"
                      as="select"
                      required={true}
                      onChange={handleCategoriesChange}
                      defaultValue={
                        props.product ? props.product.categoriesId : 1
                      }
                    >
                      <CategoriesId />
                    </select>
                    <StyledRedBox>
                      <ErrorMessage name="categoriesId" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <label>Brand:</label>
                    <select
                      name="brand"
                      as="select"
                      required={true}
                      onChange={handleBrandChange}
                      defaultValue={props.product ? props.product.brandId : 1}
                    >
                      <BrandId />
                    </select>
                    <StyledRedBox>
                      <ErrorMessage name="brand" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" gap="12px">
                    <Box display="flex" flexDirection="column" width="100%">
                      <label>Price: </label>
                      <Field
                        name="price"
                        type="text"
                        placeholder={`Enter Price...`}
                      />
                      <StyledRedBox>
                        <ErrorMessage name="price" />
                      </StyledRedBox>
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%">
                      <label>Cyber Coin: </label>
                      <Field
                        name="cyberPrice"
                        type="text"
                        placeholder={`Enter Cyber Coin...`}
                      />
                      <StyledRedBox>
                        <ErrorMessage name="cyberPrice" />
                      </StyledRedBox>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <label>Description:</label>
                    <Field
                      name="description"
                      as="textarea"
                      cols={40}
                      rows={12}
                      placeholder={`Enter Description...`}
                    />
                    <StyledRedBox>
                      <ErrorMessage name="description" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" gap="4px">
                    <Box border={1} borderRadius={1} paddingRight="6px">
                      <Checkbox
                        name="bestSeller"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 16,
                            color: "#fff",
                            alignItems: "flex-start",
                          },
                        }}
                        value={bestSellerCheck}
                        onChange={handleBestSeller}
                        disableRipple
                      />
                      bestseller?
                    </Box>
                    <Box border={1} borderRadius={1} paddingRight="6px">
                      <Checkbox
                        name="new"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 16,
                            color: "#fff",
                            alignItems: "flex-start",
                          },
                        }}
                        value={newCheck}
                        onChange={handleNew}
                        disableRipple
                      />
                      new?
                    </Box>
                    <Box border={1} borderRadius={1} paddingRight="6px">
                      <Checkbox
                        name="used"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 16,
                            color: "#fff",
                            alignItems: "flex-start",
                          },
                        }}
                        value={usedCheck}
                        onChange={handleUsed}
                        disableRipple
                      />
                      used?
                    </Box>
                    <Box border={1} borderRadius={1} paddingRight="6px">
                      <Checkbox
                        name="inStock"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 16,
                            color: "#fff",
                            alignItems: "flex-start",
                          },
                        }}
                        value={inStockCheck}
                        onChange={handleInStock}
                        disableRipple
                      />
                      in stock?
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <label>Video Iframe url: </label>
                    <Field
                      name="videoUrl"
                      type="text"
                      placeholder={`Enter iframe url...`}
                    />
                    <StyledRedBox>
                      <ErrorMessage name="videoUrl" />
                    </StyledRedBox>
                  </Box>
                  {values.videoUrl ? (
                    <StyledImg
                      src={
                        "https://img.youtube.com/vi/" +
                        values.videoUrl.split("/").pop() +
                        "/sddefault.jpg"
                      }
                      alt={values.videoUrl}
                    />
                  ) : (
                    ""
                  )}
                  <Box display="flex" flexDirection="column" paddingTop="8px">
                    <input
                      ref={fileRef}
                      hidden
                      type="file"
                      name="file"
                      onChange={(event) => {
                        setFieldValue("file", event.target.files[0]);
                      }}
                    />

                    {values.file ? (
                      <PreviewImage
                        file={values.file}
                        width="192px"
                        height="108px"
                      />
                    ) : values.imgUrl != null ? (
                      <img
                        src={
                          JsonData.imgurl + values.imgUrl.replace("\\\\", "\\")
                        }
                        alt=""
                        style={{ width: "192px", height: "108px" }}
                      />
                    ) : (
                      ""
                    )}
                    <Button
                      variant="contained"
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    >
                      Upload Main Photo
                    </Button>
                    <StyledRedBox>
                      <ErrorMessage name="file" />
                    </StyledRedBox>
                  </Box>
                  <Box display="flex" flexDirection="column" padding="8px 0">
                    <Button variant="contained" type="submit">
                      Submit
                    </Button>
                  </Box>
                  {serverState && (
                    <p className={!serverState.ok ? "errorMsg" : ""}>
                      {serverState.msg}
                    </p>
                  )}
                </Form>
              )}
            </Formik>

            <Button
              variant="text"
              sx={{
                color: "red",
                position: "absolute",
                top: "3px",
                right: "5px",
              }}
              onClick={props.toggleModal}
            >
              <CancelOutlinedIcon />
            </Button>
          </StyledModalContent>
        </StyledModal>
      )}
      <ToastContainer />
    </Box>
  );
}
