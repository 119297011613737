import React, { useRef, useState, useEffect } from "react";
import { Box, Typography, Button, Avatar, Tooltip, Modal } from "@mui/material";
import styled from "styled-components";
import useAuth from "../../../hooks/useAuth";
import { EasyToastify, ToastContainer } from "../../../Extentions/EasyToastify";
import {
  BsTwitch,
  BsYoutube,
  BsTwitter,
  BsFacebook,
  BsInstagram,
} from "react-icons/bs";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAxiosPrivateForm from "../../../hooks/useAxiosPrivateForm";
import JsonData from "../../../Data/data.Url.json";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ReplyIcon from "@mui/icons-material/Reply";
import SettingsTeamUpdate from "./SettingsTeamUpdate";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsTeamMembers from "./SettingsTeamMembers";
import SettingsTeamInvitation from "./SettingsTeamInvitation";
import SettingsOrgInvitation from "./SettingsOrgInvitation";

import LinearProgress from "@mui/material/LinearProgress";

const StyledBox = styled(Box)`
  @media only screen and (max-width: 480px) {
    padding: 16px;
  }

  form {
    width: 540px;
    display: flex;
    flex-direction: column;
    gap: 14px;

    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  label {
    color: #b7b7b7;
    font-size: 16px;
  }

  input {
    padding: 8px;
    border-radius: 8px;
    border: none;
    outline: 0;
    color: #fff;
    background-color: #0b0f1a;
    width: 100%;
  }
`;

const StyledProfileEditBox = styled(Box)`
  position: relative;

  input[type="file"] {
    display: none;
  }

  label {
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 90px;
    color: #042045;
    background-color: #fff;
    padding: 7px;
    border-radius: 30px;
    display: flex;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const StyledModalInput = styled.input`
  border: none;

  :focus {
    outline: none;
  }
`;

const StyledModalTextarea = styled.textarea`
  border: none;
  resize: none;

  :focus {
    outline: none;
  }
`;

const StyledCreateTeam = styled.div`
  display: flex;
  flex-direction: column;
  width: 540px;
  gap: 7px;
  padding-bottom: 16px;

  @media only screen and (max-width: 480px) {
    width: auto;
  }
`;

const StyledModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 640px;
  background-color: #010c20;
  border: 1px solid #fff;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 32px;

  @media only screen and (max-width: 480px) {
    padding: 12px;
    width: 320px;
  }
`;

const StyledLoadingDiv = styled.div`
  width: 580px;

  @media only screen and (max-width: 480px) {
    width: auto;
  }
`;

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const validationSchema = yup.object({
  title: yup
    .string()
    .min(5, "Too small!")
    .max(60, "Too long string")
    .required("Title is required!"),
  gameId: yup.number().required("Game Type is required!"),
  date: yup.date().required("Date is required"),
  shortDescription: yup
    .string()
    .min(5, "Too small!")
    .max(200, "Too long string")
    .required("Short Description is required"),
  description: yup
    .string()
    .min(20, "Too small!")
    .max(4000, "Too long string")
    .required("Description is required"),
  file: yup
    .mixed()
    .nullable()
    .required()
    .test(
      "FILE_SIZE",
      "Upload file is too big.",
      (value) => !value || (value && value.size <= 2048 * 2048)
    )
    .test(
      "FILE_FORMAT",
      "Upload file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
    ),
});

const SettingsTeam = () => {
  const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const axiosPrivateForm = useAxiosPrivateForm();
  // Team Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // Profile picture upload
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  //
  const [teamData, setTeamData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [registerData, setRegisterData] = useState({
    imgUrl: null,
    about: "",
    country: "",
    name: "",
    gameId: 1,
  });
  const [refresh, setRefresh] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("imgUrl", selectedFile ? selectedFile : null);
    formData.append("about", registerData.about);
    formData.append("country", registerData.country);
    formData.append("name", registerData.name);
    formData.append("gameId", registerData.gameId);

    await axiosPrivateForm
      .post("Team/Create", formData)

      .then((res) => {
        EasyToastify.ToastSuccess("Team Created");
        setRegisterData({
          imgUrl: null,
          about: "",
          country: "",
          name: "",
          gameId: 1,
        });
        setRefresh(!refresh);
        handleClose();
      })
      .catch((error) => {
        EasyToastify.ToastError("Something wrong");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const onLeaveTeam = async (e) => {
    e.preventDefault();
    if (window.confirm("Do you want to leave team?")) {
      await axiosPrivate
        .put("Team/Leave")
        .then((res) => {
          setRefresh(!refresh);
          setTeamData(null);
          EasyToastify.ToastSuccess("You left the team");
        })
        .catch((error) => {
          EasyToastify.ToastError("Oop Something wrong!");
        });
    }
  };

  useEffect(() => {
    const getTeam = async () => {
      const response = await axiosPrivate.get("Team/Get").finally(() => {
        setIsLoaded(true);
      });
      setTeamData(response.data);
    };
    getTeam();
  }, [refresh]);

  return (
    <StyledBox>
      <Typography variant="h2" paddingBottom="12px">
        Team Profile
      </Typography>
      {isLoaded ? (
        teamData ? (
          <Box display="flex" flexDirection="column" gap="16px">
            <SettingsTeamUpdate teamData={teamData} />
            <SettingsTeamMembers />
            <SettingsOrgInvitation />
            <Tooltip
              title="Think carefully before you leave"
              placement="bottom-end"
              arrow
            >
              <StyledButton
                onClick={onLeaveTeam}
                variant="contained"
                color="error"
              >
                <span>Leave Team</span>
                <ExitToAppIcon />
              </StyledButton>
            </Tooltip>
          </Box>
        ) : (
          <StyledCreateTeam>
            <Tooltip
              title="You can only create one team"
              placement="bottom-end"
              arrow
            >
              <StyledButton
                onClick={handleOpen}
                variant="contained"
                color="secondary"
              >
                <span>Create Team</span>
                <GroupAddIcon />
              </StyledButton>
            </Tooltip>
            <SettingsTeamInvitation />
          </StyledCreateTeam>
        )
      ) : (
        <StyledLoadingDiv>
          <LinearProgress color="secondary" />
        </StyledLoadingDiv>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledModalBox>
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Box display="flex" flexDirection="column" gap="8px">
              <label>Team Profile Picture</label>
              <StyledProfileEditBox>
                {preview ? (
                  selectedFile && (
                    <Avatar
                      src={preview}
                      alt={auth.auth.name}
                      sx={{ width: 120, height: 120 }}
                    />
                  )
                ) : (
                  <Avatar
                    alt={auth.auth.name}
                    sx={{ width: 120, height: 120 }}
                  />
                )}
                <label htmlFor="file-upload">
                  <AddAPhotoIcon />
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={onSelectFile}
                />
              </StyledProfileEditBox>
              <p>Recommend image ratio is 1:1</p>
            </Box>
            <Box display="flex" flexDirection="column">
              <label>Game Type:</label>
              <select
                name="gameId"
                // value={selectgameId}
                onChange={handleChange}
                style={{
                  width: "100%",
                  borderRadius: "6px",
                  padding: "4px 6px",
                }}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Click to Select Game
                </option>
                <option value={1}>Dota 2</option>
                <option value={2}>CS:GO</option>
                <option value={3}>League of Legends</option>
                <option value={4}>PUBG Mobile</option>
                <option value={5}>Mobile Legends</option>
                <option value={6}>Valorant</option>
                <option value={7}>PUBG</option>
              </select>
            </Box>
            <Box display="flex" flexDirection="column">
              <label>Team Name</label>
              <StyledModalInput
                type="text"
                onChange={handleChange}
                name="name"
                style={{
                  width: "100%",
                  borderRadius: "6px",
                  padding: "4px 8px",
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <label>Nationality</label>
              <StyledModalInput
                type="text"
                onChange={handleChange}
                name="country"
                placeholder="example: GE"
                style={{
                  width: "100%",
                  borderRadius: "6px",
                  padding: "4px 8px",
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <label>Bio &#40;not required&#41;</label>
              <StyledModalTextarea
                type="text"
                onChange={handleChange}
                name="about"
                placeholder="aq unda iyos biogragia"
                rows="4"
                style={{
                  width: "100%",
                  borderRadius: "6px",
                  padding: "4px 8px",
                }}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ width: "100%" }}
            >
              Create
            </Button>
          </form>
        </StyledModalBox>
      </Modal>
      <ToastContainer />
    </StyledBox>
  );
};

export default SettingsTeam;
