import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import CircularProgress from "@mui/material/CircularProgress";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    !auth?.accesToken ? verifyRefreshToken() : setIsLoading(false);
  }, [isLoading]);

  return (
    <>
      {!auth.nickName ? (
        <Outlet />
      ) : isLoading ? (
        <CircularProgress
          sx={{
            color: "white",
            zIndex: "20",
            position: "fixed",
            top: "50%",
            right: "50%",
          }}
        />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
